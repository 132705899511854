import * as React from "react";
const ForwardIcon = (props) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g clipPath="url(#clip0_120_54)">
      <path
        d="M8.29695 11.9813C8.63445 11.9813 8.94382 11.7 8.94382 11.3344V7.11563C8.94382 5.59688 10.1813 4.35938 11.7001 4.35938H15.0469L13.4157 5.90625C13.1626 6.15938 13.1626 6.55313 13.3876 6.80625C13.5001 6.94688 13.6688 7.00313 13.8376 7.00313C14.0063 7.00313 14.1469 6.94688 14.2594 6.83438L16.8469 4.3875C17.0157 4.21875 17.1282 3.96563 17.1282 3.7125C17.1282 3.45938 17.0157 3.23438 16.8469 3.06563L14.2594 0.675003C14.0063 0.450003 13.6126 0.450003 13.3594 0.703128C13.1344 0.956253 13.1344 1.35 13.3876 1.60313L15.0188 3.12188H11.6438C9.42195 3.12188 7.62195 4.92188 7.62195 7.14375V11.3625C7.6782 11.7 7.95945 11.9813 8.29695 11.9813Z"
        fill="#365DA1"
      />
      <path
        d="M16.5938 10.7156C16.2563 10.7156 15.9469 10.9969 15.9469 11.3625V15.4406C15.9469 15.8906 15.5813 16.2562 15.1313 16.2562H2.86877C2.41877 16.2562 2.05315 15.8906 2.05315 15.4406V11.3344C2.05315 10.9969 1.7719 10.6875 1.40627 10.6875C1.04065 10.6875 0.759399 10.9687 0.759399 11.3344V15.4125C0.759399 16.5656 1.68752 17.4938 2.84065 17.4938H15.1313C16.2844 17.4938 17.2125 16.5656 17.2125 15.4125V11.3344C17.2407 10.9969 16.9594 10.7156 16.5938 10.7156Z"
        fill="#365DA1"
      />
    </g>
    <defs>
      <clipPath id="clip0_120_54">
        <rect width="18" height="18" fill="currentcolor" />
      </clipPath>
    </defs>
  </svg>
);
export default ForwardIcon;
