import React, { Component } from "react";
import { connect } from "react-redux";
import { serverResponse } from "../util/fakeServer";
// import { gaEventLabel } from '../settings/gaConfig';
import withParams from "../components/common/WithParams";
import withLocation from "../components/common/WithLocation";
import { Helmet } from "react-helmet";
import { getValue, scrollToSlide } from "../helper/utility";
import { setValueCookie } from "../redux/cookie/action";
import { setVerticalPages } from "../redux/carousel/action";
import CarouselWrapper from "../components/CarouselWrapper/CarouselWrapper";

class Leaflet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leaflet: {},
      setPageIndex: getValue(this.getInitialPageIndex()), // Calculate initial page index based on props
      item_id: this.getInitialItemId(), // Calculate initial item id based on props
      meta_tags: <Helmet></Helmet>,
    };
  }

  componentDidMount() {
    this.props.setValueCookie();
    this.handleScrollToSlide();
    this.updateMetaTags();
  }

  getInitialPageIndex() {
    const { params, location } = this.props;
    if (location.pathname === "/volantino") {
      return 0;
    } else if (params.item_id) {
      const pages = serverResponse.leaflet.pages.filter((page) =>
        page.interactivities.some((prod) => prod.item_id === params.item_id)
      );
      return pages.length > 0 ? pages[0].number : params.index;
    }
    return params.index;
  }

  getInitialItemId() {
    return this.props.params.item_id || 0;
  }

  handleScrollToSlide() {
    if (
      serverResponse.config.scrollVertical ||
      serverResponse.config.scrollHorizontal
    ) {
      scrollToSlide(
        this.props.params.index,
        this.props.setVerticalPages,
        this.props.carouselIndex.verticalPages
      );
    }
  }

  updateMetaTags() {
    const { item_id } = this.state;
    if (item_id) {
      const product = serverResponse.interactivities[item_id];
      if (product) {
        this.setState({
          meta_tags: (
            <Helmet>
              <meta property="og:title" content={product.description}></meta>
              <meta
                property="og:url"
                content={
                  serverResponse.config.shareFlyerURL +
                  "/products/" +
                  product.item_id
                }
              ></meta>
              <meta
                property="og:description"
                content={serverResponse.config.shareProductSentence}
              ></meta>
              <meta
                property="og:image"
                content={product.images[0].image_file}
              ></meta>
              <meta property="og:type" content="website"></meta>
            </Helmet>
          ),
        });
      }
    }
  }

  render() {
    let tmp = Object.assign({}, serverResponse);

    const { setPageIndex } = this.state;

    const { item_id } = this.state;

    return (
      <CarouselWrapper
        leaflet={tmp.leaflet}
        setPageIndex={setPageIndex}
        itemId={item_id}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // ...state.themeChanger,
    // LanguageSwitcher: state.LanguageSwitcher,
    // locale: state.LanguageSwitcher.language.locale,
    authData: {
      token: state.auth.accessToken,
      isLogin: state.auth.isLogin,
    },
    carouselIndex: state.carousel,
    // themeSetting: {
    //   toolbarAlignValue: state.themeSetting.toolbarAlignValue,
    //   footerAlignValue: state.themeSetting.footerAlignValue,
    //   sidebarDisplayValue: state.themeSetting.sidebarDisplayValue,
    //   toolbarDisplayValue: state.themeSetting.toolbarDisplayValue,
    //   footerDisplayValue: state.themeSetting.footerDisplayValue,
    //   sidebarTransParentValue: state.themeSetting.sidebarTransParentValue,
    //   transparentImage: state.themeSetting.transparentImage,
    //   activeLinkStyle: state.themeSetting.activeLinkStyle,
    //   sidebarMiniValue: state.themeSetting.sidebarMiniValue,
    //   sidebarTransParentActiveBack:
    //     state.themeSetting.sidebarTransParentActiveBack,
    //   sidebarTransParentActiveColor:
    //     state.themeSetting.sidebarTransParentActiveColor
    // }
  };
};

export default withLocation(connect(mapStateToProps, {
  setValueCookie,
  setVerticalPages,
})(withParams(Leaflet)));