import * as React from "react";
const CartIcon = (props) => (
  <svg
    width="22"
    height="24"
    viewBox="0 0 22 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.9125 17.925C11.4125 17.925 10.2125 19.125 10.2125 20.625C10.2125 22.125 11.4125 23.325 12.9125 23.325C14.4125 23.325 15.6125 22.125 15.6125 20.625C15.6125 19.1625 14.375 17.925 12.9125 17.925ZM12.9125 21.675C12.35 21.675 11.9 21.225 11.9 20.6625C11.9 20.1 12.35 19.65 12.9125 19.65C13.475 19.65 13.925 20.1 13.925 20.6625C13.925 21.1875 13.4375 21.675 12.9125 21.675Z"
      fill="currentcolor"
    />
    <path
      d="M5.78752 17.925C4.28752 17.925 3.08752 19.125 3.08752 20.625C3.08752 22.125 4.28752 23.325 5.78752 23.325C7.28752 23.325 8.48752 22.125 8.48752 20.625C8.48752 19.1625 7.25002 17.925 5.78752 17.925ZM5.78752 21.675C5.22502 21.675 4.77502 21.225 4.77502 20.6625C4.77502 20.1 5.22502 19.65 5.78752 19.65C6.35002 19.65 6.80002 20.1 6.80002 20.6625C6.80002 21.1875 6.35002 21.675 5.78752 21.675Z"
      fill="currentcolor"
    />
    <path
      d="M20.825 0.674988H18.8C17.9 0.674988 17.1125 1.34999 17 2.24999L16.4 6.56249H14.825V3.82499C14.7875 3.14999 14.2625 2.62499 13.625 2.62499H10.85C10.7375 2.06249 10.25 1.64999 9.68751 1.64999H6.42501C5.78751 1.64999 5.22501 2.21249 5.22501 2.92499V3.63749H2.48751C1.85001 3.63749 1.28751 4.16249 1.28751 4.76249V6.59999C0.987512 6.67499 0.762512 6.82499 0.575012 7.04999C0.350012 7.34999 0.237512 7.76249 0.350012 8.13749C0.350012 8.17499 0.350012 8.17499 0.350012 8.21249L2.67501 15.225C2.82501 15.75 3.31251 16.125 3.87501 16.125H14.225C15.6125 16.125 16.8125 15.075 17 13.6875L18.575 2.47499C18.575 2.39999 18.65 2.36249 18.725 2.36249H20.75C21.2 2.36249 21.6125 1.98749 21.6125 1.49999C21.6125 1.01249 21.275 0.674988 20.825 0.674988ZM13.1 6.56249H10.85V4.31249H13.1V6.56249ZM6.95001 3.33749H9.20001V3.82499V6.56249H6.95001V4.79999V3.33749ZM3.01251 5.28749H5.26251V6.56249H3.01251V5.28749ZM15.3875 13.425C15.3125 13.9875 14.825 14.4 14.2625 14.4H4.21251L2.18751 8.24999H16.1375L15.3875 13.425Z"
      fill="currentcolor"
    />
  </svg>
);
export default CartIcon;
