import React from "react";
import { useParams } from "react-router-dom";

function withParams(Component) {
  return function WrapperComponent(props) {
    const params = useParams();
    return <Component {...props} params={params} />;
  };
}

export default withParams;
