import * as React from "react";

const SchermoIntero = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1_281)">
      <path
        d="M21.375 0.637512H7.61249C6.52499 0.637512 5.62499 1.53751 5.62499 2.62501V10.4625H2.62499C1.53749 10.4625 0.674988 11.3625 0.674988 12.4125V21.375C0.674988 22.4625 1.57499 23.325 2.62499 23.325H11.55C12.6375 23.325 13.5 22.425 13.5 21.375V18.375H21.375C22.4625 18.375 23.325 17.475 23.325 16.425V2.62501C23.325 1.53751 22.4625 0.637512 21.375 0.637512ZM11.85 21.375C11.85 21.525 11.7375 21.675 11.55 21.675H2.62499C2.47499 21.675 2.32499 21.5625 2.32499 21.375V12.45C2.32499 12.3 2.43749 12.15 2.62499 12.15H5.62499V16.3875C5.62499 17.475 6.52499 18.3375 7.57499 18.3375H11.8125V21.375H11.85ZM11.85 16.6875H7.61249C7.46249 16.6875 7.31249 16.575 7.31249 16.3875V12.15H11.55C11.7 12.15 11.85 12.2625 11.85 12.45V16.6875ZM21.675 16.3875C21.675 16.5375 21.5625 16.6875 21.375 16.6875H13.5375V12.45C13.5375 11.3625 12.6375 10.5 11.5875 10.5H7.31249V2.62501C7.31249 2.47501 7.42499 2.32501 7.61249 2.32501H21.375C21.525 2.32501 21.675 2.43751 21.675 2.62501V16.3875Z"
        fill="black"
      />
      <path
        d="M18.9375 4.23749H14.625C14.175 4.23749 13.8 4.61249 13.8 5.06249C13.8 5.51249 14.175 5.88749 14.625 5.88749H16.875L14.2875 8.47499C13.95 8.81249 13.95 9.33749 14.2875 9.67499C14.4375 9.82499 14.6625 9.93749 14.8875 9.93749C15.1125 9.93749 15.3375 9.86249 15.4875 9.67499L18.1125 7.04999V9.37499C18.1125 9.82499 18.4875 10.2 18.9375 10.2C19.3875 10.2 19.7625 9.82499 19.7625 9.37499V5.06249C19.8 4.61249 19.425 4.23749 18.9375 4.23749Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_281">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SchermoIntero;
