import React from "react";
import { serverResponse } from "../../../../util/fakeServer";

const TagsModal = ({ tag }) => {
  let { tags } = serverResponse.leaflet || {};
  tags = tags ? tags : {};

  let filteredTags = Object.values(tags).filter((t) => tag?.includes(t.id));

  return (
    <div className="tag-block">
      {filteredTags.map((t) => (
        <div className="tag-block-item" key={t.id}>
          <img src={t.immagine} alt={t.nome} />
        </div>
      ))}
    </div>
  );
};

export default TagsModal;
