import React, { Component } from "react";
import { Carousel } from "nuka-carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ButtonGroup } from "reactstrap";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

import CustomSlide from "./CustomSlide";
import CustomIndex from "./CustomIndex";
import { isMobile, isIE } from "react-device-detect";
import "../../assets/css/custom.css";
// import { serverResponse } from '../../../util/fakeServer';
import { serverResponse } from "../../util/fakeServer";
import { gaEventLabel } from "../../settings/gaConfig";
import { sendAnalyticsEvent } from "../../helper/utility";

// let videoModalStyles = {
//     content: {
//         background: "transparent",
//         border: 0,
//     },
//     overlay: {
//         zIndex: 999999999
//     }
// };

// if (isMobile) {
//     videoModalStyles = {
//         content: {
//             background: "transparent",
//             border: 0,
//             padding: 0,
//             top: "10px",
//             left: "10px",
//             bottom: "10px",
//             right: "10px",
//         },
//         overlay: {
//             zIndex: 999999999
//         }
//     };
// }

class CustomIndice extends Component {
  // state = {
  //     slideIndex: 0,
  //     swipe: true,
  //     video_url: "",
  //     video_playing: false,
  //     video_modal_open: false
  // }

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      slideIndex: 0,
      swipe: true
    };
  }

  goToPage = (index) => {
    //TODO
    //axios per l'incremento del counter di visualizzazioni della pagina index
    /*axios({
            method: 'post',
            url: '',
            headers: {
            },
        })
            .then(res => {

            }).catch(error => {
                //console.log(error);
            });*/

    this.setState(
      {
        slideIndex: index
      },
      () => {
        // this.forceUpdate();
        this.setState(this.state);
      }
    );
  };

  disableSwipe = () => this.setState({ swipe: !this.state.swipe });
  setSwipe = (swipe) => {
    this.setState({ swipe: swipe });
    if (isIE) {
      // IE + iFrame: la modal si apre dietro l'elemento 'scrollbars'
      // forzo lo z-index
      document.getElementById("scrollbars").style["z-index"] = swipe
        ? ""
        : "-1";
    }
  };
  isSwipeDisabed = () => {
    return this.state.swipe ? false : true;
  };

  handleChangeOrientation = () => {
    this.setState({ state: this.state });
  };

  componentDidMount() {
    window.addEventListener("orientationchange", this.handleChangeOrientation);
    let pageIndex = this.props.setPageIndex;
    if (pageIndex !== undefined) {
      pageIndex = parseInt(pageIndex);
      if (isMobile || pageIndex % 2 !== 0) {
        pageIndex--;
      }
      this.goToPage(pageIndex);
    } else {
      if (this.state.leafleat_has_video) {
        if (this.state.pages_with_video.includes(1)) {
          this.showPageVideo(1, true);
        } else if (
          this.state.pages_with_video.includes(this.state.slideIndex)
        ) {
          this.showPageVideo(this.state.slideIndex, true);
        }
      }
    }
    setTimeout(() => window.dispatchEvent(new Event("resize")), 0);

    if (!isMobile) {
      sendAnalyticsEvent(gaEventLabel.open_page, String(0));
    }
    sendAnalyticsEvent(gaEventLabel.open_page, String(1));

    this.setState({
      loaded: true
    });
  }

  render() {
    const { slideIndex, swipe } = this.state;

    //Slides
    const listSlides = this.props.leaflet.pages.map((element, key) => (
      <CustomSlide
        pageNumber={element.number}
        key={key}
        imagePath={element.image_file}
        products={element.interactivities}
        leafletSwipe={this.disableSwipe}
        isSwipeDisabed={this.isSwipeDisabed}
        setSwipe={this.setSwipe}
      />
    ));

    //Pagination
    let listPages = [];
    listPages.push(
      <Button
        className="carousel-pagination-button"
        key={0}
        color="primary"
        onClick={() => this.setState({ slideIndex: 0 })}
        active={this.state.slideIndex === 0}
      >
        1
      </Button>
    );
    for (let i = 1; i < this.props.leaflet.pages.length - 2; i++) {
      let index = i;
      this.props.leaflet.pages.length === index + 1
        ? listPages.push(
            <Button
              className="carousel-pagination-button"
              key={index}
              color="primary"
              onClick={() => this.setState({ slideIndex: index + 1 })}
              active={this.state.slideIndex === index + 1}
            >
              {" "}
              {i + 1}{" "}
            </Button>
          )
        : listPages.push(
            <Button
              className="carousel-pagination-button"
              key={index}
              color="primary"
              onClick={() => this.setState({ slideIndex: index + 1 })}
              active={this.state.slideIndex === index + 1}
            >
              {" "}
              {i + 1}{" "}
            </Button>
          );
    }
    if (this.props.leaflet.pages.length % 2 !== 0) {
      listPages.push(
        <Button
          className="carousel-pagination-button"
          key={this.props.leaflet.pages.length - 1}
          color="primary"
          onClick={() =>
            this.setState({ slideIndex: this.props.leaflet.pages.length - 1 })
          }
          active={this.state.slideIndex === this.props.leaflet.pages.length - 1}
        >
          {" "}
          {this.props.leaflet.pages.length - 1}{" "}
        </Button>
      );
    }
    // listPages.push(<Button className="carousel-pagination-button" key={this.props.leaflet.pages.length - 1} color="primary" onClick={() => this.setState({ slideIndex: this.props.leaflet.pages.length - 1 })} active={this.state.slideIndex === this.props.leaflet.pages.length - 1} > {this.props.leaflet.pages.length - 1} </Button>);

    if (!serverResponse.leaflet.hasOwnProperty("index")) {
      listPages = [];
      // listPages.push(<Button className="carousel-pagination-button" key={0} color="primary" onClick={() => this.setState({ slideIndex: 1 })} active={this.state.slideIndex === 1} >1</Button>);
      for (let i = 1; i < this.props.leaflet.pages.length; i = i + 2) {
        let index = i;
        this.props.leaflet.pages.length === index
          ? listPages.push(
              <Button
                className="carousel-pagination-button"
                key={index}
                color="primary"
                onClick={() => this.setState({ slideIndex: index - 1 })}
                active={this.state.slideIndex === index - 1}
              >
                {" "}
                {i}{" "}
              </Button>
            )
          : listPages.push(
              <Button
                className="carousel-pagination-button"
                key={index}
                color="primary"
                onClick={() => this.setState({ slideIndex: index - 1 })}
                active={this.state.slideIndex === index - 1}
              >
                {" "}
                {i}-{i + 1}{" "}
              </Button>
            );
      }
      if (this.props.leaflet.pages.length % 2 !== 0) {
        listPages.push(
          <Button
            className="carousel-pagination-button"
            key={this.props.leaflet.pages.length - 1}
            color="primary"
            onClick={() =>
              this.setState({ slideIndex: this.props.leaflet.pages.length - 1 })
            }
            active={
              this.state.slideIndex === this.props.leaflet.pages.length - 1
            }
          >
            {" "}
            {this.props.leaflet.pages.length - 1}{" "}
          </Button>
        );
      }
    }

    return (
      <div>
        <Carousel
          heightMode="max"
          ref="carouselRef"
          slideIndex={slideIndex}
          slidesToShow={isMobile ? 1 : 2}
          slidesToScroll={isMobile ? 1 : 2}
          cellAlign={isMobile ? "center" : "left"}
          dragging={swipe}
          swiping={swipe}
          speed={500}
          renderBottomCenterControls={null}
          renderCenterLeftControls={({ previousSlide }) => {
            if (isMobile) return null;
            else
              return (
                <FontAwesomeIcon
                  className="carousel-arrow-left"
                  icon={faAngleLeft}
                  size="3x"
                  onClick={previousSlide}
                />
              );
          }}
          renderCenterRightControls={({ nextSlide }) => {
            if (isMobile) return null;
            else
              return (
                <FontAwesomeIcon
                  className="carousel-arrow-right"
                  icon={faAngleRight}
                  size="3x"
                  onClick={nextSlide}
                />
              );
          }}
          beforeSlide={(slideIndex) => {}}
          afterSlide={(slideIndex) => {
            this.goToPage(slideIndex);

            if (this.state.leafleat_has_video) {
              let target_page = slideIndex;
              let target_page_related = target_page;
              if (!isMobile) {
                if (target_page % 2 === 1) {
                  if (serverResponse.leaflet.pages.length < target_page) {
                    target_page_related = target_page - 1;
                  }
                } else {
                  target_page_related = target_page + 1;
                }
              } else {
                target_page = slideIndex + 1;
                target_page_related = target_page;
              }

              if (this.state.pages_with_video.includes(target_page)) {
                this.showPageVideo(target_page);
              } else if (
                this.state.pages_with_video.includes(target_page_related)
              ) {
                this.showPageVideo(target_page_related);
              }
            }

            sendAnalyticsEvent(
              gaEventLabel.open_page,
              String(isMobile ? slideIndex + 1 : slideIndex)
            );
            if (
              !isMobile &&
              serverResponse.leaflet.pages.length >= slideIndex + 1
            ) {
              sendAnalyticsEvent(
                gaEventLabel.open_page,
                String(slideIndex + 1)
              );
            }
          }}
        >
          {serverResponse.leaflet.hasOwnProperty("index") && (
            <CustomIndex
              index={this.props.leaflet.index}
              goToPage={this.goToPage}
            />
          )}
          {listSlides}
        </Carousel>

        <div
          id="indiceId"
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
            textAlign: "center"
          }}
        >
          <ButtonGroup>{listPages}</ButtonGroup>
        </div>
      </div>
    );
  }
}

export default CustomIndice;
