import React from "react";
import { useState } from "react";
import { Modal, ModalHeader } from "reactstrap";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalFooter from "reactstrap/lib/ModalFooter";
import { serverResponse } from "../../util/fakeServer";
import { styles } from "./RemoveAllBtn.style";
import { language } from "../../languageProvider/language/language";
import { defaultLanguage } from "../../helper/utility";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const RemoveAllBtn = (props) => {
  const [isOpen, toggleOpen] = useState(false);
  const [tooltip, toggleTooltip] = useState(false);

  const primary_color = serverResponse.config.primary_color;
  const secondary_color = serverResponse.config.secondary_color;

  let label = "lista";

  if (serverResponse.config.send_grocery_list_to_market) {
    label = "carrello";
  }

  return (
    <div
      style={{ position: "relative" }}
      onMouseEnter={() => toggleTooltip(true)}
      onMouseLeave={() => toggleTooltip(false)}
    >
      {tooltip && (
        <div style={styles.tooltip(primary_color)}>
          <p>{language[defaultLanguage].svuota}</p>
        </div>
      )}
      {/* <div
        id="removeAllBtn"
        style={{
          border: "none",
          width: "35px",
          cursor: "pointer"
        }}
        onClick={() => {
          if (props.active) {
            toggleOpen(!isOpen);
            toggleTooltip(false);
          }
        }}
      >
        {serverResponse.config.send_grocery_list_to_market ? (
          <EliminaCarrello custom_class="cls-2" />
        ) : (
          <EliminaLista custom_class="cls-2" />
        )}
      </div> */}
      <div
        id="removeAllBtn"
        className="delete-icon"
        onClick={() => {
          if (props.active) {
            toggleOpen(!isOpen);
            toggleTooltip(false);
          }
        }}
      >
        <span>
          {serverResponse.config.send_grocery_list_to_market ? (
            <FontAwesomeIcon icon={faTrashAlt} />
          ) : (
            <FontAwesomeIcon icon={faTrashAlt} />
          )}
        </span>
      </div>
      <Modal className="delete-modalBlock" isOpen={isOpen} centered>
        <ModalHeader>
          {language[defaultLanguage].svuota} {label}
        </ModalHeader>
        <ModalBody>
          {language[defaultLanguage].sei_sicuro_svuota_carrello}?
        </ModalBody>
        <ModalFooter>
          <button
            className="btn"
            style={styles.abort(secondary_color)}
            onClick={() => toggleOpen(!isOpen)}
          >
            {language[defaultLanguage].annulla}
          </button>
          <button
            className="btn"
            style={styles.confirm}
            onClick={() => {
              props.deleteAll();
              toggleOpen(!isOpen);
            }}
          >
            {language[defaultLanguage].conferma}
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default RemoveAllBtn;
