import * as React from "react";
const MenuIcon = (props) => (
  <svg
    width="67"
    height="67"
    viewBox="0 0 67 67"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M33.5008 1.88519C16.018 1.88519 1.88519 16.018 1.88519 33.5008C1.88519 50.9837 16.018 65.2212 33.5008 65.2212C50.9837 65.2212 65.2212 50.9837 65.2212 33.5008C65.2212 16.018 50.9837 1.88519 33.5008 1.88519ZM33.5008 60.5102C18.6352 60.5102 6.59613 48.3665 6.59613 33.5008C6.59613 18.6352 18.6352 6.59613 33.5008 6.59613C48.3665 6.59613 60.5102 18.7399 60.5102 33.6055C60.5102 48.3665 48.3665 60.5102 33.5008 60.5102Z"
      fill="currentcolor"
    />
    <path
      d="M44.0751 18.9501H22.9282C21.672 18.9501 20.5204 19.997 20.5204 21.3579C20.5204 22.7189 21.5673 23.7657 22.9282 23.7657H43.9704C45.2267 23.7657 46.3782 22.7189 46.3782 21.3579C46.3782 19.997 45.3314 18.9501 44.0751 18.9501Z"
      fill="currentcolor"
    />
    <path
      d="M44.0751 31.1977H22.9282C21.672 31.1977 20.5204 32.2446 20.5204 33.6055C20.5204 34.8618 21.5673 36.0133 22.9282 36.0133H43.9704C45.2267 36.0133 46.3782 34.9665 46.3782 33.6055C46.3782 32.2446 45.3314 31.1977 44.0751 31.1977Z"
      fill="currentcolor"
    />
    <path
      d="M44.0751 43.341H22.9282C21.672 43.341 20.5204 44.3879 20.5204 45.7488C20.5204 47.1098 21.5673 48.1567 22.9282 48.1567H43.9704C45.2267 48.1567 46.3782 47.1098 46.3782 45.7488C46.3782 44.3879 45.3314 43.341 44.0751 43.341Z"
      fill="currentcolor"
    />
  </svg>
);
export default MenuIcon;
