import * as React from "react";
const PagineIcon = (props) => (
  <svg
    width="24"
    height="22"
    viewBox="0 0 24 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21 0.65H12.825C11.55 0.65 10.4625 1.7 10.4625 3.0125V5.675H3.00001C1.72501 5.675 0.637512 6.725 0.637512 8.0375V15.2375C0.637512 15.6875 0.825012 16.1375 1.16251 16.475L5.62501 20.8625C5.96251 21.2 6.41251 21.3875 6.86251 21.3875H11.175C12.45 21.3875 13.5 20.3375 13.5 19.025V13.9625L15.4125 15.875C15.75 16.2125 16.2 16.4 16.65 16.4H21C22.275 16.4 23.3625 15.35 23.3625 14.0375V2.975C23.3625 1.7 22.275 0.65 21 0.65ZM3.52501 16.3625H5.58751C5.62501 16.3625 5.66251 16.4 5.66251 16.4375V18.5L3.52501 16.3625ZM11.85 19.025C11.85 19.4 11.55 19.7 11.2125 19.7H7.35001V16.475C7.35001 15.5 6.56251 14.7125 5.58751 14.7125H2.36251V7.9625C2.36251 7.5875 2.66251 7.2875 3.03751 7.2875H11.8125C11.85 7.2875 11.8875 7.325 11.8875 7.3625V19.025H11.85ZM13.5375 11.5625V11.375H15.4125C15.45 11.375 15.4875 11.4125 15.4875 11.45V13.5125L13.5375 11.5625ZM21.675 14.0375C21.675 14.4125 21.375 14.7125 21 14.7125H17.1375V11.4875C17.1375 10.5125 16.35 9.725 15.375 9.725H13.5V7.4C13.5 6.575 12.9 5.8625 12.15 5.7125V3.0125C12.15 2.6375 12.45 2.3375 12.825 2.3375H21C21.375 2.3375 21.675 2.6375 21.675 3.0125V14.0375Z"
      fill="currentcolor"
    />
  </svg>
);
export default PagineIcon;
