import { START_LOADING_PRICES, END_LOADING_PRICES, GET_PRICES } from "../type";
import * as services from "../../services/product.services";
import { getPagesFromLabel } from "../../helper/utility";
import { serverResponse } from "../../util/fakeServer";

export const getPricesAction = (str, isMobileFeed, array = -1) => async (dispatch) => {
  let arr = array !== -1 ? array : [];

  const strValue = str ? str.toString() : '';

  if (arr.length === 0) {
    const pages = !isMobileFeed
      ? getPagesFromLabel(strValue)
      : Array.from(Array(serverResponse.leaflet.pages.length).keys());
      
    pages.forEach((element) => {
      serverResponse.leaflet.pages
        .filter((el) => el.number === +element)
        .forEach((res) => { 
          res.interactivities
            .filter((r) => r.skul && r.skul !== "" && r.dynamic_price)
            .forEach((inter) => arr.push(inter.skul));
        });
    });
  }

  if (arr.length > 0 && serverResponse.config.dynamic_prices) {
    try {
      dispatch({ type: START_LOADING_PRICES });
      const {
        data: { data: res }
      } = await services.getPrices(arr, serverResponse.config.id_listino);

      dispatch({
        type: GET_PRICES,
        payload: res[0]
      });
    } catch (error) {
      console.error(error);
      dispatch({ type: END_LOADING_PRICES });
    }
  }
};
