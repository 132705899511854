import * as React from "react"

const PdfInpublish = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill={props.color}
      d="M16.531 6.313 10.906 1A1.605 1.605 0 0 0 9.75.531H4.625A1.68 1.68 0 0 0 2.937 2.22V17.75c0 .938.75 1.688 1.688 1.688h10.75a1.68 1.68 0 0 0 1.688-1.688V7.531c0-.468-.188-.906-.532-1.218ZM10.72 2.75l3.937 3.688H11c-.156 0-.281-.125-.281-.25V2.75Zm4.656 15.313H4.625a.28.28 0 0 1-.281-.282V2.25a.28.28 0 0 1 .281-.281h4.656v4.218c0 .907.75 1.657 1.688 1.657h4.687v9.906c0 .156-.125.313-.281.313Z"
    />
    <path
      fill={props.color}
      d="M6.084 15.833v-3.03h1.308c.225 0 .421.044.59.133.169.089.3.214.394.375.093.16.14.348.14.563 0 .217-.048.405-.145.564a.952.952 0 0 1-.404.367 1.344 1.344 0 0 1-.605.129h-.781v-.64h.615a.555.555 0 0 0 .247-.05.367.367 0 0 0 .159-.146.43.43 0 0 0 .056-.224.417.417 0 0 0-.056-.222.353.353 0 0 0-.159-.142.555.555 0 0 0-.247-.05h-.29v2.373h-.822ZM9.984 15.833H8.818v-3.03h1.154c.312 0 .581.06.808.182.228.12.403.294.527.52.124.227.186.497.186.813 0 .316-.062.587-.185.814-.123.226-.298.4-.524.52-.226.121-.492.181-.8.181Zm-.343-.698h.313c.15 0 .278-.024.384-.072a.489.489 0 0 0 .242-.25c.056-.119.085-.284.085-.495 0-.21-.029-.376-.086-.494a.492.492 0 0 0-.249-.25.981.981 0 0 0-.4-.073h-.29v1.634ZM11.874 15.833v-3.03h2.13v.663h-1.307v.52h1.177v.664h-1.178v1.183h-.822Z"
    />
  </svg>
)
export default PdfInpublish
