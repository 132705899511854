import { serverResponse } from "../util/fakeServer";
import DefaultManager from "./Default.manager";
import IperbimboManager from "./Iperbimbo.manager";
import InfoManager from "./Info.manager";
import ErgonManager from "./Ergon.manager";
import RudManager from "./Rud.manager";
import Sole365Manager from "./Sole365.manager";
import TroiloManager from "./Troilo.manager";
import PrequManager from "./Prequ.manager";
import CiesseItaliaManager from "./CiesseItalia.manager";

const clientId = serverResponse.config.client_id;
const signboardId = serverResponse.config.signboard_id;

class ManagerFactory {
  static getManager() {
    switch (clientId) {
      case 7:
        return new IperbimboManager(signboardId);

      case 29:
      case 2320:
        return new ErgonManager(signboardId);

      case 185:
        return new InfoManager(signboardId);

      case 2194:
        return new RudManager(signboardId);

      case 2601:
        return new TroiloManager(signboardId);

      case 3487:
        return new Sole365Manager(signboardId);

      case 3600:
        return new PrequManager(signboardId);

      case 3640:
        return new CiesseItaliaManager(signboardId);

      default:
        return new DefaultManager(signboardId);
    }
  }
}

export default ManagerFactory;
