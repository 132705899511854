import React, { useEffect, useRef, useState, useCallback } from "react";
import Select from "react-select";
import { serverResponse } from "../../util/fakeServer";
import { Input, InputGroup } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { styles } from "./categories.styles";
import "../../assets/css/custom.css";
import { language } from "../../languageProvider/language/language";
import { defaultLanguage, sendAnalyticsEvent } from "../../helper/utility";
import * as services from "../../services/product.services";
import { BREAKPOINT } from "../../helper/constant";
import useCurrentWidth from "../../hooks/widthHook";
import Product from "../productsList/Product";
import OffCanvas from "react-aria-offcanvas/dist/OffCanvas";
import Loader from "../common/Loader";
import ManagerFactory from "../../managers/ManagerFactory";
import { gaEventLabel } from "../../settings/gaConfig";
import { connect } from "react-redux";
import "../../assets/css/categoryDynamic.css";
import searchPageLogo from "../../assets/images/searchpagelogo.png";
import { Search } from "../../assets/images/icone";
import SearchFilter from "../../assets/icons/searchFilter.icon";
import { Link } from "react-router-dom";
import BackArrowIcon from "../../assets/icons/backarrow-icon";

const manager = ManagerFactory.getManager();

const CategoriesDynamic = (props) => {
  const [loading, setLoading] = useState(true);
  const [optionsCat, setOptionsCat] = useState([]);
  const [showSubCat, setShowSubCat] = useState(false);
  const [form, setForm] = useState({
    input_search: "",
    category: null,
    subcategory: null
  });
  const [sidebarFilterOpen, setSidebarFilterOpen] = useState(false);
  const [itemsToRender, setItemsToRender] = useState([]);
  const [activeChevronCategory, setActiveChevronCategory] = useState(null);
  const loadingProductsRef = useRef(false);

  const categoriesSelect = useRef(null);
  const subCategoriesSelect = useRef(null);

  const [showScrollUp, setShowScrollUp] = useState(false);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalProducts, setTotalProducts] = useState(0);
  const [categories, setCategories] = useState([]);
  const [featuredProducts, setFeaturedProducts] = useState({});
  const [isFilterActive, setIsFilterActive] = useState(true);

  const refScroll = useRef(null);
  const width = useCurrentWidth();

  const getCategories = useCallback(() => {
    services
      .getCategories(form)
      .then((res) => {
        if (res.status === 200) {
          setOptionsCat(
            formatCat({ arr: res.data.data, key: "category_name" })
          );
        }
      })
      .catch((err) => {
        console.log("this is error", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [form]);

  const fetchCategoriesAndProducts = async () => {
    setLoading(true);
    const fetchedCategories = await services.getCategories();
    const topCategories = fetchedCategories.data.data.slice(0, 3);
    setCategories(topCategories);

    const productsPromises = topCategories.map((category) =>
      services.getProducts({ categoryId: category.id })
    );
    const productsResults = await Promise.all(productsPromises);
    const featured = topCategories.reduce((acc, category, index) => {
      acc[category.id] = productsResults[index].data.data.items
        .sort(() => 0.5 - Math.random())
        .slice(0, 4);
      return acc;
    }, {});

    setFeaturedProducts(featured);
    setLoading(false);
  };

  useEffect(() => {
    fetchCategoriesAndProducts();
  }, []);

  const openBanner = () => {
    if (serverResponse.config.internal_banner_click) {
      var id_prod_special = serverResponse.config.product_banner;
      if (document.getElementById("plus-" + id_prod_special)) {
        sendAnalyticsEvent(gaEventLabel.banner_click, String(id_prod_special));
        document.getElementById("plus-" + id_prod_special).click();
      }
    } else if (serverResponse.config.external_banner_click) {
      window.open(serverResponse.config.href_banner, "_blank");
    }
  };

  const trackScrolling = useCallback(() => {
    const scrollTarget =
      width >= BREAKPOINT.xs
        ? document.querySelector(".wrapper_vm_container").parentNode
        : document.querySelector("#scrollable-div");

    if (isBottom(scrollTarget)) {
      const loadingProducts = loadingProductsRef.current;
      if (!loadingProducts) {
        setCurrentPage((prev) => prev + 1);
      }
    }
  }, [width]);

  useEffect(() => {
    const scrollTarget =
      width >= BREAKPOINT.xs
        ? document.querySelector(".wrapper_vm_container").parentNode
        : document.querySelector("#scrollable-div");

    scrollTarget.addEventListener("scroll", trackScrolling);
    getCategories();
  }, [getCategories, trackScrolling, width]);

  const handleScroll = useCallback(() => {
    if (loadingProducts || !isFilterActive) {
      return;
    }

    let newObj = {
      ...form,
      page: currentPage
    };
    loadingProductsRef.current = true;
    setLoadingProducts(true);
    services
      .getProducts(newObj)
      .then((data) => {
        if (data.status === 200) {
          setItemsToRender((prev) => [...prev, ...data.data.data.items]);
          loadingProductsRef.current = false;
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoadingProducts(false);
        // setCallApi(true);
      });
  }, [
    currentPage,
    form,
    loadingProducts,
    setLoadingProducts,
    setItemsToRender,
    isFilterActive
  ]);

  useEffect(() => {
    if (currentPage > 1 && itemsToRender.length < totalProducts) handleScroll();
  }, [currentPage, itemsToRender.length, handleScroll, totalProducts]);

  const formatCat = (obj) => {
    let opt = [];
    obj.arr.map((element) =>
      opt.push({
        value: obj.key ? element[obj.key] : element,
        label: obj.key ? element[obj.key] : element,
        id: element.id,
        sub: element.subcategories
      })
    );
    return opt;
  };

  const resetFilter = () => {
    if (categoriesSelect.current && categoriesSelect.current.clearValue) {
      categoriesSelect.current.clearValue();
    }
    if (subCategoriesSelect.current && subCategoriesSelect.current.clearValue) {
      subCategoriesSelect.current.clearValue();
    }
    setItemsToRender([]);
    setSidebarFilterOpen(false);
    setCurrentPage(1);
    setForm({
      input_search: "",
      category: null,
      subcategory: null
    });
    setActiveChevronCategory(null);
    setIsFilterActive(false);
    fetchCategoriesAndProducts();
  };

  const onChange = ({ name, value, arrow }) => {
    setForm({ ...form, [name]: value });
    if (!arrow) {
      handleSearch();
    }
  };

  const textualSearchonChange = ({ name, value }) => {
    setForm({ ...form, [name]: value });

    if (value.length === 0) {
      resetFilter();
    } else if (value.length > 2) {
      handleSearch();
    }
  };

  const onChangeMobile = ({ name, value, subCat, categoryName }) => {
    const newState = { ...form, [name]: value };
    if (categoryName) {
      newState.categoryName = categoryName;
    }
    setForm(newState);
    if (subCat) {
      handleSearch();
    }
  };

  const handleSearch = () => {
    setItemsToRender([]);
    setSidebarFilterOpen(false);
    setCurrentPage(1);
    setLoadingProducts(true);
    let newObj = {
      ...form,
      page: 1
    };
    services
      .getProducts(newObj)
      .then((data) => {
        if (data.status === 200) {
          setItemsToRender((prev) => [...prev, ...data.data.data.items]);
          setTotalProducts(data.data.data.total);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoadingProducts(false));
  };

  const backToCategories = () => {
    setShowSubCat(false);
  };

  const classChangeClick = (value) => {
    if (activeChevronCategory === value) {
      return;
    }
    setActiveChevronCategory((prevCategory) =>
      prevCategory === value ? null : value
    );
  };

  const closeFilterBar = () => {
    setSidebarFilterOpen(false);
  };

  const isBottom = (el) => {
    return el.scrollHeight - el.scrollTop - el.clientHeight < 1;
  };

  return (
    <>
      {/* {!!(width < BREAKPOINT.xs) && ( */}
      <OffCanvas
        position="bottom"
        width={"250"}
        className="shadow d-block d-lg-none sidebar-mobile"
        style={styles.sidebarContainer}
        isOpen={sidebarFilterOpen}
        onClose={() => {
          setSidebarFilterOpen(false);
        }}
        labelledby="menu-button"
      >
        {/* <h4 style={styles.marginBottomClass}>
            {language[defaultLanguage].filtra_prodotti}
          </h4>
          {manager.isSearchProductVisible() && (
            <Input
              placeholder={language[defaultLanguage].prodotto}
              id="product_"
              name="input_search"
              onKeyPress={(e) => {
                if (e.key === "Enter") handleSearch();
              }}
              onChange={(e) => {
                onChange({
                  name: "input_search",
                  value: e.target.value,
                });
              }}
              value={form.input_search}
            />
          )} */}

        {/* <div style={{ ...styles.categoryContainer, overflowY: "scroll" }}> */}
        <div className="categories-static mobile-view">
          {/* <div className="categories-static-header">
            <div className="tag-block" style={{ gap: "15px" }}>
              <div
                className={`tag-block-item colorbox`}
                style={{ backgroundColor: "#8FB63B" }}
              ></div>
              <div
                className={`tag-block-item colorbox`}
                style={{ backgroundColor: "#2B8AC4" }}
              ></div>
              <div
                className={`tag-block-item colorbox`}
                style={{ backgroundColor: "#BF353D" }}
              ></div>
              <div
                className={`tag-block-item colorbox`}
                style={{ backgroundColor: "#FF87C0" }}
              ></div>
            </div>
          </div> */}
          <div className="modelClose">
            {manager.closeButtonIcon() && (
              <FontAwesomeIcon
                onClick={() => closeFilterBar()}
                icon={faTimes}
                color="#0D0040"
                size="lg"
                className="cursor-pointer"
              />
            )}
          </div>
        </div>
        <div
          className="product-category-block"
          style={styles.categoryContainer}
        >
          {optionsCat.map((element, key) => {
            return (
              <div key={`${element.id}_${key}`}>
                <p
                  className={`pointer product-item-list ${
                    form.category === element.id && "active"
                  }`}
                  style={styles.categoryName(
                    form.category === element.id
                      ? serverResponse.config.secondary_color
                      : "black"
                  )}
                  onClick={() => {
                    onChangeMobile({
                      name: "category",
                      value: element.id,
                      categoryName: element.value
                    });
                    setShowSubCat(true);
                  }}
                >
                  <span>
                    <span className="font-icon-category">
                      <svg
                        width="15"
                        height="18"
                        viewBox="0 0 15 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.5625 0.00622559H2.43749C1.48124 0.00622559 0.721863 0.765601 0.721863 1.72185V10.75C0.721863 11.1719 0.890613 11.5656 1.19999 11.8469L6.26249 16.6281C6.54374 16.9094 6.90936 17.05 7.30311 17.05H12.5625C13.5187 17.05 14.2781 16.2906 14.2781 15.3344V1.72185C14.2781 0.765601 13.5187 0.00622559 12.5625 0.00622559ZM2.91561 11.7062H6.20624C6.34686 11.7062 6.45936 11.8187 6.45936 11.9312V15.025L2.91561 11.7062ZM13.0125 15.2781C13.0125 15.5312 12.8156 15.7281 12.5625 15.7281H7.72499V11.9312C7.72499 11.1156 7.04999 10.4406 6.20624 10.4406H1.98749V1.72185C1.98749 1.46873 2.18436 1.27185 2.43749 1.27185H12.5625C12.8156 1.27185 13.0125 1.46873 13.0125 1.72185V15.2781Z"
                          fill="currentcolor"
                        ></path>
                      </svg>
                    </span>
                    {element.value} ({element.sub.length})
                  </span>
                  <span className="rotate font-icon">
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="chevron-left"
                      className="svg-inline--fa fa-chevron-left "
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                    >
                      <path
                        fill="currentColor"
                        d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"
                      ></path>
                    </svg>
                  </span>
                </p>
              </div>
            );
          })}
        </div>
        <div className={`category-block-inner ${showSubCat ? "active" : ""}`}>
          <div className="category-inner-box">
            <Link className="back" onClick={backToCategories}>
              <BackArrowIcon />
              tutte le categorie
            </Link>
            <h2>
              <svg
                width="15"
                height="18"
                viewBox="0 0 15 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.5625 0.00622559H2.43749C1.48124 0.00622559 0.721863 0.765601 0.721863 1.72185V10.75C0.721863 11.1719 0.890613 11.5656 1.19999 11.8469L6.26249 16.6281C6.54374 16.9094 6.90936 17.05 7.30311 17.05H12.5625C13.5187 17.05 14.2781 16.2906 14.2781 15.3344V1.72185C14.2781 0.765601 13.5187 0.00622559 12.5625 0.00622559ZM2.91561 11.7062H6.20624C6.34686 11.7062 6.45936 11.8187 6.45936 11.9312V15.025L2.91561 11.7062ZM13.0125 15.2781C13.0125 15.5312 12.8156 15.7281 12.5625 15.7281H7.72499V11.9312C7.72499 11.1156 7.04999 10.4406 6.20624 10.4406H1.98749V1.72185C1.98749 1.46873 2.18436 1.27185 2.43749 1.27185H12.5625C12.8156 1.27185 13.0125 1.46873 13.0125 1.72185V15.2781Z"
                  fill="currentcolor"
                ></path>
              </svg>
              {form.categoryName}
            </h2>
            {optionsCat.map((element, key) => {
              return (
                <div className="category-name" key={key}>
                  {element.id === form.category &&
                    element.sub.map((subs, subKey) => {
                      return (
                        <p
                          className={`pointer ${
                            form.subcategory === subs.id ? "active" : ""
                          }`}
                          style={styles.subCategoryName(
                            form.subcategory === subs.id
                              ? serverResponse.config.secondary_color
                              : "black"
                          )}
                          key={subKey}
                          onClick={() => {
                            onChangeMobile({
                              name: "subcategory",
                              value: subs.id,
                              subCat: true
                            });
                          }}
                        >
                          {subs.category_name}
                        </p>
                      );
                    })}
                </div>
              );
            })}
          </div>
        </div>
        <div className="mt-4 d-flex justify-content-center flex-column gap-3">
          {/* <button
            style={{ borderRadius: "30px", border: "1px solid" }}
            disabled={
              !form.category &&
              !form.subcategory &&
              form.input_search.replace(/\s/g, "") === ""
            }
            className="btn btn-primary d-sm-block "
            onClick={() => handleSearch()}
          >
            {language[defaultLanguage].cerca}
          </button> */}
          <button
            style={{ borderRadius: "30px", border: "1px solid" }}
            disabled={loadingProducts || loading}
            className="btn btn-outline d-sm-block "
            onClick={() => resetFilter()}
          >
            {language[defaultLanguage].reset}
          </button>
        </div>
      </OffCanvas>
      {/* )} */}

      <div
        ref={refScroll}
        style={{ overflowX: "hidden" }}
        className="container-fluid productCategory-content categoryDynamic"
        id="scrollable-div"
        onScroll={(e) => {
          if (e.target.scrollTop > 300) {
            if (!showScrollUp) {
              setShowScrollUp(true);
            }
          } else {
            if (showScrollUp) {
              setShowScrollUp(false);
            }
          }
        }}
      >
        <div className="row ma-0 d-none d-lg-block">
          <div className="roe-card-body">
            <div
              className={`sidebarMenuBlock ${
                !("left_bar" in serverResponse.config) ||
                (!serverResponse.config.left_bar && "filterleft")
              }`}
            >
              <div className="search_bar" style={{ marginTop: "0" }}>
                {manager.isSearchProductVisible() && (
                  <>
                    <InputGroup className="custom-search-bar-wrapper">
                      <Input
                        placeholder={language[defaultLanguage].prodotto}
                        id="product_"
                        name="input_search"
                        onChange={(e) => {
                          textualSearchonChange({
                            name: "input_search",
                            value: e.target.value
                          });
                        }}
                        value={form.input_search}
                      />
                      <span>
                        <Search />
                      </span>
                    </InputGroup>
                  </>
                )}
              </div>
              <div className="sidebarMenuBlock__item">
                <button
                  style={{ border: "1px solid" }}
                  disabled={loadingProducts || loading}
                  className="btn btn-reset d-none d-sm-block"
                  onClick={() => resetFilter()}
                >
                  {language[defaultLanguage].reset}
                </button>
              </div>

              <div
                className="product-category-block"
                style={{ ...styles.categoryContainer, overflowY: "scroll" }}
              >
                {optionsCat.map((element, key) => {
                  return (
                    <div
                      className="product-block-inner"
                      key={`${element.id}_${key}`}
                    >
                      <p
                        className={`pointer product-item-list ${
                          form.category === element.id && "active"
                        }`}
                        style={styles.categoryName(
                          form.category === element.id
                            ? serverResponse.config.secondary_color
                            : "black"
                        )}
                      >
                        <span
                          onClick={() => {
                            onChange({
                              name: "category",
                              value: element.id,
                              arrow: false
                            });
                            classChangeClick(element.id);
                          }}
                        >
                          <span className="font-icon-category">
                            <svg
                              width="15"
                              height="18"
                              viewBox="0 0 15 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12.5625 0.00622559H2.43749C1.48124 0.00622559 0.721863 0.765601 0.721863 1.72185V10.75C0.721863 11.1719 0.890613 11.5656 1.19999 11.8469L6.26249 16.6281C6.54374 16.9094 6.90936 17.05 7.30311 17.05H12.5625C13.5187 17.05 14.2781 16.2906 14.2781 15.3344V1.72185C14.2781 0.765601 13.5187 0.00622559 12.5625 0.00622559ZM2.91561 11.7062H6.20624C6.34686 11.7062 6.45936 11.8187 6.45936 11.9312V15.025L2.91561 11.7062ZM13.0125 15.2781C13.0125 15.5312 12.8156 15.7281 12.5625 15.7281H7.72499V11.9312C7.72499 11.1156 7.04999 10.4406 6.20624 10.4406H1.98749V1.72185C1.98749 1.46873 2.18436 1.27185 2.43749 1.27185H12.5625C12.8156 1.27185 13.0125 1.46873 13.0125 1.72185V15.2781Z"
                                fill="currentcolor"
                              ></path>
                            </svg>
                          </span>
                          {element.value} ({element.sub.length})
                        </span>
                        <span
                          className={`font-icon ${
                            activeChevronCategory === element.id ? "rotate" : ""
                          }`}
                          onClick={() => {
                            onChange({
                              name: "category",
                              value: element.id,
                              arrow: true
                            });
                            classChangeClick(element.id);
                          }}
                        >
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="chevron-left"
                            className="svg-inline--fa fa-chevron-left "
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 320 512"
                          >
                            <path
                              fill="currentColor"
                              d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"
                            ></path>
                          </svg>
                        </span>
                      </p>

                      {element.id === form.category &&
                        element.sub.map((subs, key) => {
                          return (
                            <p
                              className={`pointer ${
                                form.subcategory === subs.id && "active"
                              }`}
                              style={styles.subCategoryName(
                                form.subcategory === subs.id
                                  ? serverResponse.config.secondary_color
                                  : "black"
                              )}
                              key={key}
                              onClick={() => {
                                onChange({
                                  name: "subcategory",
                                  value: subs.id
                                });
                              }}
                            >
                              {subs.category_name}
                            </p>
                          );
                        })}
                    </div>
                  );
                })}
              </div>
              {/* <div className="d-flex justify-content-center">
                <button
                  style={{ borderRadius: "30px", border: "1px solid" }}
                  disabled={
                    !form.category &&
                    !form.subcategory &&
                    form.input_search.replace(/\s/g, "") === ""
                  }
                  className="btn btn-primary d-sm-block "
                  onClick={() => handleSearch()}
                >
                  {language[defaultLanguage].cerca}
                </button>
              </div> */}
            </div>
          </div>
        </div>

        <div className="sidebarMenuBlockMobile">
          <div className="sidebarMenuBlockMobile-item">
            <div className="search-field">
              <input
                type="text"
                className="form-control"
                placeholder="cerca un prodotto"
                id="product_"
                onChange={(e) => {
                  textualSearchonChange({
                    name: "input_search",
                    value: e.target.value
                  });
                }}
                value={form.input_search}
              />
              <Search />
            </div>

            <button
              className="btn-filter"
              onClick={() => {
                setSidebarFilterOpen(true);
              }}
            >
              <SearchFilter />
            </button>
          </div>
        </div>

        {/* {serverResponse.config.category_banner && ( */}
        <div className="col-sm-12 d-none d-lg-flex text-center">
          <img
            className="product-banner-block"
            alt="banner"
            onClick={openBanner}
            src={serverResponse.config.category_banner}
          />
        </div>
        {/* )} */}

        {serverResponse.config.category_banner_mobile && (
          <div className="col-sm-12 d-flex d-lg-none">
            <img
              className="product-banner-block"
              alt="banner"
              onClick={openBanner}
              src={serverResponse.config.category_banner_mobile}
              style={{ cursor: "pointer", maxWidth: "100%" }}
            />
          </div>
        )}
        {/* <div className="categories-static categories-dynamic">
          <div className="categories-static-header">
            <div className="tag-block">
              <div
                className={`tag-block-item colorbox`}
                style={{ backgroundColor: "#8FB63B" }}
              ></div>
              <div
                className={`tag-block-item colorbox`}
                style={{ backgroundColor: "#2B8AC4" }}
              ></div>
              <div
                className={`tag-block-item colorbox`}
                style={{ backgroundColor: "#BF353D" }}
              ></div>
              <div
                className={`tag-block-item colorbox`}
                style={{ backgroundColor: "#FF87C0" }}
              ></div>
            </div>
            <button className="btn">cancella filtri</button>
          </div>
        </div> */}

        <div
          id="scroller"
          // className="row filtered-products justify-content-start"
          className="row categories-static-inner"
        >
          {!loadingProducts &&
            !loading &&
            itemsToRender.length === 0 &&
            // <img
            //   style={{
            //     width: "50%",
            //     textAlign: "center",
            //     margin: "auto",
            //     position: "absolute",
            //     top: "50%",
            //     left: "50%",
            //     transform: "translate(-50%, -50%)",
            //   }}
            //   className="img-fluid"
            //   src={serverResponse.config.logo_full}
            //   alt="logo"
            // ></img>
            (loading ? (
              <Loader background={"transparent"} />
            ) : (
              <div className="categories-static">
                {Object.entries(featuredProducts).map(
                  ([categoryId, products]) => {
                    const category = categories.find(
                      (cat) => cat.id == categoryId
                    );
                    return (
                      <div key={categoryId}>
                        <h2>
                          <span>
                            {category
                              ? category.category_name
                              : "Unknown Category"}
                          </span>
                          <button
                            onClick={() => {
                              onChange({
                                name: "category",
                                value: categoryId,
                                arrow: false
                              });
                            }}
                          >
                            mostra tutti
                          </button>
                        </h2>
                        <div className="row categories-static-inner">
                          {products.map((product, index) => (
                            <div
                              className="col-6 col-sm-6 col-lg-6 col-xl-3 col-xxxl-2 h-full product-item-block-wrap"
                              key={index}
                            >
                              <Product
                                info={product}
                                groceryList={props.groceryList}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            ))}
          {loading && itemsToRender.length <= totalProducts ? (
            <Loader background={"transparent"} />
          ) : (
            itemsToRender.map((el, key) => {
              return (
                <div
                  key={`${el.id}_${key}`}
                  className="col-6 col-sm-6 col-lg-6 col-xl-3 col-xxxl-2 pb-3 product-item-block-wrap"
                >
                  <Product
                    fromProducts={true}
                    elementIndex={key}
                    dynamic_price={el.dynamic_price}
                    dynamic_info={el.dynamic_info}
                    info={el}
                    groceryList={props.groceryList}
                  />
                </div>
              );
            })
          )}
          {loadingProducts && itemsToRender.length <= totalProducts && (
            <div
              className="spinner-grow text-center"
              style={{
                width: "100px",
                height: "100px",
                color: serverResponse.config.secondary_color
              }}
              role="status"
            ></div>
          )}
        </div>
        {/* {false && !loading && ( */}
        {/* <div
          onClick={() => {
            setSidebarFilterOpen(true);
          }}
          className="filter-scroll-up d-flex d-lg-none d-flex justify-content-center flex-column"
          style={styles.filterScrollUp(
            serverResponse.config.secondary_color,
            showScrollUp ? "10" : "10",
            56
          )}
        >
          <FontAwesomeIcon
            onClick={() => {
              setSidebarFilterOpen(true);
            }}
            size="1x"
            style={styles.displayType()}
            icon={faFilter}
          />
          <p style={{ fontSize: "0.8rem" }}>
            {language[defaultLanguage].filtra}
          </p>
        </div> */}
        {/* )} */}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    // ...state.themeChanger
    groceryList: state.groceryList
  };
};

export default connect(mapStateToProps)(CategoriesDynamic);
