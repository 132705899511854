import { START_LOADING_PRICES, END_LOADING_PRICES, GET_PRICES } from "../type";

const initState = {
  isLoading: false,
  productPrices: {}
};

function Price(state = initState, action) {
  const { type, payload } = action;

  switch (type) {
    case START_LOADING_PRICES:
      return {
        ...state,
        isLoading: true
      };
    case GET_PRICES:
      return {
        ...state,
        productPrices: { ...state.productPrices, ...payload },
        isLoading: false
      };
    case END_LOADING_PRICES:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
}

export default Price;