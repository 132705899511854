import * as React from "react";
const ProdottiIcon = (props) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="4.5"
      y="4"
      width="6.49609"
      height="6.49609"
      rx="1"
      stroke="currentcolor"
      strokeWidth="1.5"
    />
    <rect
      x="4.5"
      y="13.5044"
      width="6.49609"
      height="6.49609"
      rx="1"
      stroke="currentcolor"
      strokeWidth="1.5"
    />
    <rect
      x="14.0038"
      y="4"
      width="6.49609"
      height="6.49609"
      rx="1"
      stroke="currentcolor"
      strokeWidth="1.5"
    />
    <rect
      x="14.0038"
      y="13.5044"
      width="6.49609"
      height="6.49609"
      rx="1"
      stroke="currentcolor"
      strokeWidth="1.5"
    />
  </svg>
);
export default ProdottiIcon;
