import React, { useEffect, useRef, useState } from "react";
// import { Redirect } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./login.css";
import { connect } from "react-redux";
import { loginJWT, signupJWT } from "../redux/authJWT/action";
import { serverResponse } from "../util/fakeServer";
import { useLocation } from "react-router-dom";
import LoadingPages from "./Loading.pages";
import * as services from "../services/resetPassword.services";
import { language } from "../languageProvider/language/language";
import { defaultLanguage } from "../helper/utility";
import { interattivoLogo } from "../helper/constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { startLoadingJWT, endLoadingJWT } from "../redux/authJWT/action";

function Form(props) {
  const { passwordProtected } = serverResponse.config;
  const [option, setOption] = useState(1);
  const location = useLocation();

  // const history = useHistory();

  const navigate = useNavigate();

  const [errorReset, setErrorReset] = useState(null);
  const [feedMessage, setFeedMessage] = useState(false);
  const [form, setForm] = useState({
    email: passwordProtected ? "volantino@interattivo.it" : "",
    password: "",
    name: "",
    surname: "",
    codice: "",
    address: null,
    phone: null
  });
  const [hidden, setHidden] = useState(true);

  useEffect(() => {
    switch (location.pathname) {
      case "/register":
        setOption(2);
        break;
      case "/reset-psw":
        setOption(3);
        break;

      default:
        setOption(1);
    }
  }, [location.pathname]);

  const refEmail = useRef(null);
  const refName = useRef(null);
  const refSurname = useRef(null);
  const refPassword = useRef(null);
  const refCodiceCliente = useRef(null);
  // const refPhone = useRef(null);
  // const refAddress = useRef(null);

  const onChange = ({ name, value }) => {
    setForm({ ...form, [name]: value });
  };

  const resetAll = () => {
    setErrorReset(null);
    setFeedMessage(null);
  };
  const handleOnPress = () => {
    setErrorReset(null);
    if (!feedMessage) {
      if (option === 1) {
        props.loginJWT(form, navigate);
      }
      if (option === 2) {
        props.signupJWT(form);
      }
      if (option === 3) {
        const dataReset = {
          email: form.email,
          id_signboard: serverResponse.config.signboard_id,
          url: `${window.location.href.replace("reset-psw", "")}set-psw`,
          home: `${window.location.href.replace("reset-psw", "")}set-psw`
        };
        props.startLoadingJWT();
        services
          .resetPassword(dataReset)
          .then((res) => {
            switch (res.data.status) {
              case 200:
                setFeedMessage(language[defaultLanguage].email_successo);
                break;
              case 403:
                setErrorReset(language[defaultLanguage].email_non_presente);
                break;
              default:
                setErrorReset(language[defaultLanguage].errore_invio_mail);
                break;
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => props.endLoadingJWT());
      }
    }
  };

  const handleKeyPress = (e) => {};

  useEffect(() => {
    document.addEventListener("keyup", handleKeyPress);

    return () => {
      document.removeEventListener("keyup", handleKeyPress);
    };
  }, []);

  if (
    !serverResponse.config.allow_login ||
    (props.authJWT.isAuthenticated && !props.authJWT.loadingProfile)
  ) {
    if (location.state && location.state.referrer)
      return navigate(location.state.referrer);
    else return navigate("/leaflet");
  }

  if (props.authJWT.loadingProfile) {
    return <LoadingPages />;
  }

  return (
    <div className="global-wrapper">
      <div className="main-container">
        <img
          style={{ width: "150px" }}
          className="img-fluid"
          src={serverResponse.config.logo_full}
          alt="logo cliente"
        />
        <header>
          <div
            className={
              "header-headings " +
              (option === 1 ? "sign-in" : option === 2 ? "sign-up" : "forgot")
            }
          >
            <span>{language[defaultLanguage].effettua_login}</span>
            <span>{language[defaultLanguage].crea_account}</span>
            <span>{language[defaultLanguage].resetta_psw}</span>
          </div>
        </header>
        {!props.authJWT.success_signup && (
          <ul className="options" style={{ listStyle: "none", padding: 0 }}>
            <li
              className={option === 1 ? "active" : ""}
              onClick={() => {
                resetAll();
                setOption(1);
                navigate("/login");
              }}
            >
              {language[defaultLanguage].login}
            </li>
            {!passwordProtected && (
              <li
                className={option === 2 ? "active" : ""}
                onClick={() => {
                  resetAll();
                  setOption(2);
                  navigate("/register");
                }}
              >
                {language[defaultLanguage].registrati}
              </li>
            )}
            {!passwordProtected && (
              <li
                className={option === 3 ? "active" : ""}
                onClick={() => {
                  resetAll();
                  setOption(3);
                  navigate("/reset-psw");
                }}
              >
                {language[defaultLanguage].rec_psw}
              </li>
            )}
          </ul>
        )}
        {!props.success_signup && (
          <form
            className="account-form"
            onSubmit={(evt) => {
              evt.preventDefault();
              handleOnPress();
            }}
          >
            {!props.authJWT.success_signup && (
              <div
                style={{ position: "relative" }}
                className={
                  "account-form-fields " +
                  (option === 1
                    ? "sign-in"
                    : option === 2
                    ? "sign-up"
                    : "forgot")
                }
              >
                {!passwordProtected && (
                  <input
                    value={form.email}
                    ref={refEmail}
                    id="email"
                    name="email"
                    type="email"
                    placeholder={`${language[defaultLanguage].email}*`}
                    required
                    onChange={(e) => {
                      onChange({ name: "email", value: e.target.value });
                    }}
                  />
                )}

                <input
                  value={form.password}
                  ref={refPassword}
                  id="password"
                  name="password"
                  type={hidden ? "password" : "text"}
                  placeholder={`${language[defaultLanguage].password}*`}
                  required={option === 1 || option === 2 ? true : false}
                  disabled={option === 3 ? true : false}
                  onChange={(e) => {
                    onChange({ name: "password", value: e.target.value });
                  }}
                />
                <FontAwesomeIcon
                  className="eye_icon"
                  onClick={() => setHidden((prev) => !prev)}
                  icon={hidden ? faEyeSlash : faEye}
                  size="1x"
                  color="black"
                />
                {!passwordProtected && (
                  <input
                    value={form.codice}
                    ref={refCodiceCliente}
                    name="codice_cliente"
                    id="codice_cliente"
                    type="text"
                    required
                    placeholder={`${language[defaultLanguage].codice_cliente}*`}
                    disabled={option === 1 || option === 3 ? true : false}
                    onChange={(e) => {
                      onChange({ name: "codice", value: e.target.value });
                    }}
                  />
                )}

                {!passwordProtected && (
                  <input
                    minLength={2}
                    value={form.name}
                    ref={refName}
                    name="name"
                    id="name"
                    type="text"
                    placeholder={`${language[defaultLanguage].nome}*`}
                    required={option === 2 ? true : false}
                    disabled={option === 1 || option === 3 ? true : false}
                    onChange={(e) => {
                      onChange({ name: "name", value: e.target.value });
                    }}
                  />
                )}

                {!passwordProtected && (
                  <input
                    minLength={2}
                    ref={refSurname}
                    value={form.surname}
                    id="surname"
                    name="surname"
                    type="text"
                    placeholder={`${language[defaultLanguage].cognome}*`}
                    required={option === 2 ? true : false}
                    disabled={option === 1 || option === 3 ? true : false}
                    onChange={(e) => {
                      onChange({ name: "surname", value: e.target.value });
                    }}
                  />
                )}
              </div>
            )}
            {!feedMessage && !props.authJWT.feedbackMessage && (
              <button className="btn-submit-form mt-3" type="submit">
                {option === 1
                  ? language[defaultLanguage].login
                  : option === 2
                  ? language[defaultLanguage].registrati
                  : language[defaultLanguage].resetta_psw}
                {props.authJWT.loading && (
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                )}

                {!!errorReset && (
                  <div style={{ color: "red" }}>{errorReset}</div>
                )}
              </button>
            )}
            {!!props.authJWT.error && (
              <div style={{ color: "red" }}>
                <p>{props.authJWT.error}</p>
              </div>
            )}
            {feedMessage && (
              <p
                style={{ color: "#0008", width: "320px", textAlign: "center" }}
              >
                <>
                  {feedMessage}

                  <FontAwesomeIcon style={{ color: "green" }} icon={faCheck} />
                </>
              </p>
            )}
            {props.authJWT.feedbackMessage && (
              <p
                style={{ color: "#0008", width: "320px", textAlign: "center" }}
              >
                <>
                  {props.authJWT.feedbackMessage}
                  <FontAwesomeIcon style={{ color: "green" }} icon={faCheck} />
                </>
              </p>
            )}
          </form>
        )}
      </div>
      <div className="login_footer">
        <span style={{ fontSize: "0.7em", marginRight: "5px" }}>
          powered by
        </span>
        <img
          style={{
            width: "200px"
          }}
          className="img-fluid"
          src={interattivoLogo}
          alt="logo creator"
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    authJWT: state.authJWT
  };
};

export default connect(mapStateToProps, {
  loginJWT,
  signupJWT,
  startLoadingJWT,
  endLoadingJWT
})(Form);
