import * as React from "react";

const CustomTwitterIcon = ({ size, round, bgStyle, path }) => (
  <svg viewBox="0 0 64 64" width="32" height="32">
    <rect
      width="60"
      height="60"
      rx="0"
      ry="0"
      style={{ borderRadius: round ? "50%" : "0", ...bgStyle }}
    ></rect>
    <path
      d="M 41.116 18.375 h 4.962 l -10.8405 12.39 l 12.753 16.86 H 38.005 l -7.821 -10.2255 L 21.235 47.625 H 16.27 l 11.595 -13.2525 L 15.631 18.375 H 25.87 l 7.0695 9.3465 z m -1.7415 26.28 h 2.7495 L 24.376 21.189 H 21.4255 z"
      fill="white"
    />
  </svg>
);
export default CustomTwitterIcon;
