import React from "react";
import { language } from "../../languageProvider/language/language";
import { defaultLanguage } from "../../helper/utility";
import { LeftArrow } from "../../assets/images/icone";
import { serverResponse } from "../../util/fakeServer";
import { Link } from "react-router-dom";

function LeafletButton(props) {
  return (
    <Link
      className="btn btn-back-leaflet"
      style={{ textDecoration: "none", width: "fit-content" }}
      to={"/leaflet?cart=1"}
      onClick={props.onClick}
    >
      <LeftArrow fill={serverResponse.config.primary_color} />{" "}
      {props.text}
    </Link>
  );
}

export default LeafletButton;
