import * as React from "react";
const VideoIcon = (props) => (
  <svg
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.7499 0.596863H2.2499C1.29365 0.596863 0.478027 1.38436 0.478027 2.36874V9.84999C0.506152 10.8062 1.29365 11.5937 2.2499 11.5937H15.7499C16.7062 11.5937 17.5218 10.8062 17.5218 9.82186V2.36874C17.5218 1.38436 16.7062 0.596863 15.7499 0.596863ZM16.2562 9.84999C16.2562 10.1312 16.0312 10.3562 15.7499 10.3562H2.2499C1.96865 10.3562 1.74365 10.1312 1.74365 9.84999V2.36874C1.74365 2.08749 1.96865 1.86249 2.2499 1.86249H15.7499C16.0312 1.86249 16.2562 2.08749 16.2562 2.36874V9.84999Z"
      fill="currentcolor"
    />
    <path
      d="M11.8969 5.49059L7.56562 2.87496C7.36875 2.76246 7.11563 2.76246 6.91875 2.87496C6.72188 2.98746 6.60938 3.18434 6.60938 3.43746V8.66871C6.60938 8.89371 6.72188 9.11871 6.91875 9.23121C7.00313 9.28746 7.11563 9.31559 7.22813 9.31559C7.34063 9.31559 7.45312 9.28746 7.56562 9.23121L11.925 6.61559C12.1219 6.50309 12.2344 6.30621 12.2344 6.08121C12.2344 5.85621 12.0938 5.60309 11.8969 5.49059ZM7.84687 7.51559V4.53434L10.3219 6.02496L7.84687 7.51559Z"
      fill="currentcolor"
    />
    <path
      d="M16.2 13.675H9.44995C9.1687 12.8312 8.3812 12.2125 7.45308 12.2125C6.52495 12.2125 5.73745 12.8312 5.4562 13.675H1.79995C1.46245 13.675 1.15308 13.9562 1.15308 14.3218C1.15308 14.6875 1.43433 14.9687 1.79995 14.9687H5.48433C5.76558 15.8125 6.55308 16.4312 7.4812 16.4312C8.40933 16.4312 9.19683 15.8125 9.47808 14.9687H16.2C16.5375 14.9687 16.8468 14.6875 16.8468 14.3218C16.8468 13.9562 16.5375 13.675 16.2 13.675ZM7.4812 15.1375C7.0312 15.1375 6.66558 14.7718 6.66558 14.3218C6.66558 13.8718 7.0312 13.5062 7.4812 13.5062C7.9312 13.5062 8.29683 13.8718 8.29683 14.3218C8.29683 14.7718 7.9312 15.1375 7.4812 15.1375Z"
      fill="currentcolor"
    />
  </svg>
);
export default VideoIcon;
