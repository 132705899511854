// CAROUSEL
export const SET_CAROUSEL_INDEX = "SET_CAROUSEL_INDEX";
export const CAROUSEL_LOADING = "CAROUSEL_LOADING";
export const SET_INDEX_LABEL = "SET_INDEX_LABEL";
export const SET_SLIDE_MAX = "SET_SLIDE_MAX";
export const IS_FROM_INPUT = "IS_FROM_INPUT";
export const SET_FIRST_LOAD = "SET_FIRST_LOAD";
export const SET_ZOOM_LEVEL = "SET_ZOOM_LEVEL";
export const SET_REF_ZOOM = "SET_REF_ZOOM";
export const SET_BUTTON_DISABLED = "SET_BUTTON_DISABLED";
export const IS_MODAL_OPEN = "IS_MODAL_OPEN";
export const IS_RIGHT_SIDEBAR_OPEN = "IS_RIGHT_SIDEBAR_OPEN";
export const SET_VERTICAL_PAGES = "SET_VERTICAL_PAGES";
export const SET_MARKER_CLICKABLE = "SET_MARKER_CLICKABLE";
export const SET_SEARCHBAR_OPEN = "SET_SEARCHBAR_OPEN";
export const SET_SEARCHBAR_CLOSE = "SET_SEARCHBAR_CLOSE";
//COOKIE
export const SET_COOKIE_INFO = "SET_COOKIE_INFO";

//FLOATING MENU
export const IS_MENU_OPEN = "IS_MENU_OPEN";

//VIDEO MANAGER
export const SET_VISIBILITY_VIDEO = "SET_VISIBILITY_VIDEO";
//MODAL
export const OPEN_INTERACTIVITY_MODAL = "OPEN_INTERACTIVITY_MODAL";
export const CLOSE_INTERACTIVITY_MODAL = "CLOSE_INTERACTIVITY_MODAL";
export const OPEN_RELATED = "OPEN_RELATED";
export const SET_ERROR_MODAL = "SET_ERROR_MODAL";
export const OPEN_INTERACTIVITY_MODAL_STATIC =
  "OPEN_INTERACTIVITY_MODAL_STATIC";
export const START_LOADING_MODAL = "START_LOADING_MODAL";
export const END_LOADING_MODAL = "END_LOADING_MODAL";
export const RESET_MODAL_DATA = "RESET_MODAL_DATA";
export const SET_CURRENT_MARKER = "SET_CURRENT_MARKER";

// Person.name
export const LOGIN_JWT = "LOGIN_JWT";
export const LOGIN_JWT_SUCCESS = "LOGIN_JWT_SUCCESS";
export const LOGOUT_JWT = "LOGOUT_JWT";

export const TOGGLE_JWT_MODAL = "TOGGLE_JWT_MODAL";
export const LOGIN_JWT_FAIL = "LOGIN_JWT_FAIL";
export const REGISTER_JWT_FAIL = "REGISTER_JWT_FAIL";
export const START_JWT_LOADING = "START_JWT_LOADING";
export const END_JWT_LOADING = "END_JWT_LOADING";
export const GET_JWT_PROFILE = "GET_JWT_PROFILE";
export const LOADING_JWT_PROFILE = "LOADING_JWT_PROFILE";
export const GET_JWT_PROFILE_FAIL = "GET_JWT_PROFILE_FAIL";
export const TOGGLE_USER_MODAL = "TOGGLE_USER_MODAL";
export const SUCCESS_SIGNUP_JWT = "SUCCESS_SIGNUP_JWT";

// PRICES
export const START_LOADING_PRICES = "START_LOADING_PRICES";
export const END_LOADING_PRICES = "END_LOADING_PRICES";
export const GET_PRICES = "GET_PRICES";
