import React, { useState, useEffect } from "react";
import Switch from "react-ios-switch";
import {
  getAnalyticsDevice,
  sendVisit,
  getAnalyticsUniqueId,
  setCookiesBrowser,
  defaultLanguage,
} from "../../helper/utility";
import { visit } from "../../services/analytics.services";
import { serverResponse } from "../../util/fakeServer";
import {
  isIPad13,
  isMobileOnly,
  isTablet,
  isBrowser as isDesktop,
} from "react-device-detect";
import useCurrentWidth from "../../hooks/widthHook";
import { BREAKPOINT } from "../../helper/constant";
import ManagerFactory from "../../managers/ManagerFactory";
import { language } from "../../languageProvider/language/language";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShieldAlt } from "@fortawesome/free-solid-svg-icons";

const CookieConsentBanner = () => {
  const manager = ManagerFactory.getManager();
  const [checkedCookie, setCheckedCookie] = useState(true);
  const [buttonText, setButtonText] = useState(
    language[defaultLanguage].accetta_selezionati
  );
  const [showBanner, setShowBanner] = useState(null);
  const width = useCurrentWidth();
  const [isCentered, setIsCentered] = useState(
    width <= BREAKPOINT.xs ? true : false
  );

  const [cookies, setCookies] = useState([
    {
      name: "tecnici",
      value: "true",
      description: language[defaultLanguage].cookie_detail_tech,
      expires: "",
    },
    {
      name: "statistiche",
      value: "true",
      description: language[defaultLanguage].cookie_detail_stats,
      expires: "",
    },
  ]);

  const [currentTab, setCurrentTab] = useState(0);

  function toggleBannerPosition() {
    setIsCentered(!isCentered);
  }

  useEffect(() => {
    if (width <= BREAKPOINT.xs) {
      setIsCentered(true);
    }
  }, [width]);

  const initializeCookies = () => {
    const date = new Date();
    date.setDate(date.getDate() + 30);
    const expires = date.toUTCString();
    setCookies((prevState) => {
      const newCookies = prevState.map((cookie) => {
        return { ...cookie, expires: expires };
      });
      return newCookies;
    });
  };

  const visitAnaliticsVisit = () => {
    visit({
      id_cliente: serverResponse.config.client_id,
      id_insegna: serverResponse.config.signboard_id,
      id_utente: serverResponse.config.release_id,
      id_volantino: serverResponse.leaflet.id,
      device: getAnalyticsDevice(
        isMobileOnly,
        isTablet || isIPad13,
        isDesktop && !isIPad13
      ),
      ip_address: null,
      unique_id: getAnalyticsUniqueId(),
    });
  };

  useEffect(() => {
    const statisticheCookieValue = getCookie("statistiche");

    if (
      statisticheCookieValue === null ||
      statisticheCookieValue === "undefined"
    ) {
      initializeCookies();
      setShowBanner(true);
    } else {
      setShowBanner(false);
      if (statisticheCookieValue === "true") {
        setCheckedCookie(true);
        if (sendVisit()) visitAnaliticsVisit();
      }
    }
  }, []);

  useEffect(() => {
    checkedCookie
      ? setButtonText(language[defaultLanguage].accetta_selezionati)
      : setButtonText(language[defaultLanguage].rifiuta);
  }, [checkedCookie]);

  const getCookie = (name) => {
    const value = localStorage.getItem(name);
    return value;
  };

  const configurationPrivacy =
    useCurrentWidth() <= BREAKPOINT.xs
      ? {
          borderTopLeftRadius: 15,
          borderBottomLeftRadius: 15,
          bottom: 100,
          right: 0,
        }
      : {
          bottom: -1,
          left: 75,
          borderTopLeftRadius: 15,
          borderTopRightRadius: 15,
        };

  if (showBanner === null || !showBanner) {
    return (
      <div
        onClick={() => {
          setIsCentered(true);
          setShowBanner(true);
        }}
        id="miniMODE"
        style={{
          ...configurationPrivacy,
          position: "absolute",
          width: "40px",
          height: "40px",
          fontSize: "1.5rem",
          lineHeight: "30px",
          textAlign: "center",
          backgroundColor: "white",
          border: "1px solid rgba(0,0,0,0.3)",
          zIndex: 10,
          color: serverResponse.config.primary_color,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <FontAwesomeIcon icon={faShieldAlt} />
      </div>
    );
  }

  const handleTecCookie = () => {
    alert(
      "Obbligatorio - non può essere deselezionato. I cookie necessari contribuiscono a rendere fruibile il sito web abilitandone funzionalità di base quali la navigazione sulle pagine e l'accesso alle aree protette del sito. Il sito web non è in grado di funzionare correttamente senza questi cookie."
    );
  };

  const updateCookies = (name, value) => {
    if (name === "tecnici") return;

    setCookies((prevState) => {
      const newCookies = prevState.map((cookie) =>
        cookie.name === name ? { ...cookie, value: value.toString() } : cookie
      );

      //setCookiesBrowser(newCookies);
      return newCookies;
    });
  };

  const handleAcceptSelected = () => {
    setCookiesBrowser(cookies);
    if (
      cookies.filter(
        (el) => el.name === "statistiche" && el.value === JSON.stringify(true)
      ).length > 0
    ) {
      let tempVisit = sendVisit();
      if (tempVisit) {
        visitAnaliticsVisit();
      }
    }

    setShowBanner(false);
  };

  const handleClickAcceptAll = () => {
    setCookies((prevState) => {
      const newCookies = prevState.map((cookie) => ({
        ...cookie,
        value: "true",
      }));
      setCookiesBrowser(newCookies);
      setCheckedCookie(true);
      if (
        newCookies.filter(
          (el) => el.name === "statistiche" && el.value === JSON.stringify(true)
        ).length > 0
      ) {
        let tempVisit = sendVisit();
        if (tempVisit) {
          visitAnaliticsVisit();
        }
      }

      return newCookies;
    });
    setShowBanner(false);
  };

  const handleClickRejectAll = () => {
    setCookies((prevState) => {
      const newCookies = prevState.map((cookie) =>
        cookie.name === "tecnici" ? cookie : { ...cookie, value: "false" }
      );
      setCookiesBrowser(newCookies);
      setShowBanner(false);
      return newCookies;
    });
  };

  const ConsensoComponent = () => (
    <>
      {/* <div
        className={`logoCookieContainer d-flex justify-content-center align-items-center ${isCentered ? "col-12" : "col-2"
          }`}
      >
        {!isCentered && (
          <img
            className="img-fluid"
            src={logoInterattivoFree}
            style={{ width: "100px", alignSelf: "center" }}
            alt="logo interattivo"
          />
        )}
      </div> */}
      <div className={`contentCookie ${isCentered ? "col-12" : "col-7"}`}>
        <h5>{language[defaultLanguage].useCookie}</h5>
        <p style={{ fontSize: "13px" }}>
          {language[defaultLanguage].cookieText}
        </p>
        <div className="switchGlobalContainer align-items-center flex-row justify-content-center flex-md-row ">
          <div className="switchContainer" onClick={handleTecCookie}>
            <Switch
              checked={true}
              disabled={true}
              handleColor="white"
              name={"Necessari"}
              offColor="white"
              onChange={() => {}}
              onColor="rgb(76, 217, 100)"
              readOnly={true}
            />
            <p
              style={{
                fontSize: "15px",
                marginRight: "5px",
                textTransform: "capitalize",
              }}
            >
              {language[defaultLanguage].necessari}
            </p>
          </div>
          <div className="switchContainer switchStatistiche ml-3">
            <Switch
              onChange={(checked) => {
                setCheckedCookie((prev) => !prev);
                updateCookies("statistiche", checked);
              }}
              checked={checkedCookie}
              handleColor="white"
              name={"Statistiche"}
              offColor="white"
              onColor="rgb(76, 217, 100)"
            />
            <p
              style={{
                fontSize: "15px",
                marginRight: "5px",
                marginLeft: width > BREAKPOINT.md ? "20px" : "0px",
                textTransform: "capitalize",
              }}
            >
              {language[defaultLanguage].statistiche}
            </p>
          </div>
          {!!(width > BREAKPOINT.xs && !isCentered) && (
            <span
              onClick={toggleBannerPosition}
              style={{ alignSelf: "center", marginLeft: "10px", color: "blue" }}
            >
              {language[defaultLanguage].mostra_piu}
            </span>
          )}
        </div>
      </div>
    </>
  );

  const DettaglioComponent = () => (
    <>
      {cookies.map((el, index) => {
        return (
          <div key={`${el.name}_${index}`} className="col-12">
            <p
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "15px",
              }}
            >
              {language[defaultLanguage][el.name]}
            </p>
            <p style={{ fontSize: "13px" }}>{el.description}</p>
            <hr />
          </div>
        );
      })}
    </>
  );
  const InfoComponent = () => (
    <p style={{ fontSize: "13px" }}>{language[defaultLanguage].cookieText}</p>
  );

  const PrivacyComponent = () => manager.getPrivacyText();

  const tabs = [
    {
      key: 1,
      component: <ConsensoComponent />,
      title: language[defaultLanguage].consenso,
    },
    {
      key: 2,
      component: <DettaglioComponent />,
      title: language[defaultLanguage].dettaglio,
    },
    {
      key: 3,
      component: <InfoComponent />,
      title: "Cookie",
    },
    {
      key: 4,
      component: <PrivacyComponent />,
      title: "Privacy",
    },
  ];

  return (
    <div className={`mainContentCookie ${isCentered ? "centered" : ""}`}>
      <div className="globalContainerCookie px-1 row">
        {/* {!!isCentered && (
          <div className="col-12 d-flex p-0">
            <img
              className="img-fluid"
              src={logoInterattivoFree}
              style={{ width: "100px", alignSelf: "center" }}
              alt="logo interattivo"
            />
          </div>
        )} */}
        {!!isCentered && (
          <div className="col-12">
            <div className="row mb-4">
              {tabs.map((el, index) => {
                return (
                  <div
                    key={el.key}
                    className="col-3 bold p-1 text-center"
                    style={{
                      cursor: "pointer",
                      borderColor:
                        currentTab === index
                          ? serverResponse.config.primary_color
                          : "transparent",
                      borderWidth: "3px",
                      borderBottomStyle: "solid",
                    }}
                    onClick={() => setCurrentTab(index)}
                  >
                    <p
                      style={{
                        color:
                          currentTab === index
                            ? serverResponse.config.primary_color
                            : "unset",
                        fontWeight: "bold",
                      }}
                    >
                      {el.title}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {tabs[currentTab].component}
        <div
          className={`buttonCookieContainer ${
            isCentered ? "col-12 d-flex flex-md-row mt-3 p-2" : "col-3"
          }`}
        >
          <div
            onClick={handleClickAcceptAll}
            className="acceptCookieButton m-2 buttonCookie btn btn-sm btn-primary"
          >
            {language[defaultLanguage].accetta_tutti}
          </div>
          <div
            onClick={() =>
              !checkedCookie ? handleClickRejectAll() : handleAcceptSelected()
            }
            className={`rifiutaCookieButton buttonCookie btn btn-sm m-2  btn-outline-primary ${
              isCentered ? "mt-0" : ""
            }`}
          >
            {buttonText}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookieConsentBanner;
