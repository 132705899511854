import React, { Fragment, useEffect, useState } from "react";
// import SidebarWrapper from "./sidebar.style";
// import Radium from "radium";
import NavList from "../../components/sidebar/NavList";
import { sidebarData } from "../../util/data/sidebar";
import { interattivoIcon, iconAppName } from "../../helper/constant";
import { NavLink, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
// import { withRouter } from "react-router-dom";
import ShareFlyer from "../share/ShareFlyer";
import { isIE } from "react-device-detect";

import { serverResponse } from "../../util/fakeServer";
import { connect } from "react-redux";
import { setIndexCarousel } from "../../redux/carousel/action";
import NotificationBadge from "react-notification-badge";
// import RetrieveCart from "../RetrieveCart";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faSearch } from "@fortawesome/free-solid-svg-icons";
// import { Effect } from "react-notification-badge";
import { isMobile } from "react-device-detect";
import { language } from "../../languageProvider/language/language";
import { defaultLanguage } from "../../helper/utility";
// import { IndiceIcona } from "../../assets/images/icone";
import ManagerFactory from "../../managers/ManagerFactory";
import Logo from "../../assets/images/evviva-logo-dark.png";
import HomeIcon from "../../assets/icons/home.icon";
import CartIcon from "../../assets/icons/cart-icon";
import userImage from "../../assets/images/user-img.png";
import GroceryList from "../floatingGroceryList/GroceryList";
import UserAvatar from "../UserAvatar/UserAvatar";
const manager = ManagerFactory.getManager();
const IndexNav = (props) => {
  const [isActive, toggleActive] = useState(false);

  const routerLinkLeaflet =
    global.preview_id !== undefined
      ? `/leaflet?id=${global.preview_id}`
      : "/leaflet";

  let indexClass = isActive
    ? "nav-link main-list active"
    : "nav-link main-list";

  return (
    <Fragment>
      {manager.showList() && (
        <div
          // className={props.mini === "on" ? "li_sidebar" : ""}
          className="sidebar-nav-item sidebar-nav-fix"
          id="indexIconSidebar"
          style={{
            flex: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-end"
          }}
        >
          <Link
            to={routerLinkLeaflet}
            style={{ cursor: "pointer" }}
            onClick={() => {
              props.setIndex();
            }}
            onMouseDown={() => toggleActive(!isActive)}
            onMouseUp={() => toggleActive(!isActive)}
            className={indexClass}
          >
            <HomeIcon />
            <span className="nav-item-label">
              {language[defaultLanguage].indice}
            </span>
            {/* <span className="">{language[defaultLanguage].indice}</span> */}
            {/* <p
              style={{
                // opacity: props.mini === "on" ? null : "1",
                opacity: null,
                visibility: "visible",
              }}
              // className={props.mini ? "fade_p" : ""}
              className="fade_p"
            >
              {language[defaultLanguage].indice}
            </p> */}
          </Link>
        </div>
      )}
    </Fragment>
  );
};

const Sidebar = (props) => {
  let listNameStyle;
  let sidebar;
  let appNameStyle = {};
  const [popoverOpen, setPopoverOpen] = useState(false);

  const toggle = () => {
    setPopoverOpen(!popoverOpen);
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (parseInt(params.get("cart")) === 1 && !isMobile) {
      setPopoverOpen(true);
    }
  }, []);

  const {
    mini,
    drawerWidth,
    miniDrawerWidth,
    // sidebarTheme,
    closeDrawer,
    // themeSetting
    themeMapping
  } = props;

  let imgsrcDef = require("../../assets/images/logo_client.png");

  let redirect = process.env.REACT_APP_VOLANTINO_INTERATTIVO;
  if (serverResponse.config.custom_link)
    redirect = serverResponse.config.custom_link;

  // if (themeSetting.sidebarTransParentValue === "on") {
  // sidebar = {
  //   // backgroundImage: `linear-gradient(0deg,rgba(0, 0, 0, 0.8),rgba(0, 0, 0, 0.9)),url(${themeSetting.transparentImage})`,
  //   backgroundImage: `linear-gradient(0deg,rgba(0, 0, 0, 0.8),rgba(0, 0, 0, 0.9)),url(${themeSetting.transparentImage})`,
  //   backgroundRepeat: "no-repeat, repeat",
  //   backgroundPosition: "center",
  //   backgroundSize: "cover",
  //   width: mini ? miniDrawerWidth : drawerWidth,
  //   // "@media (maxWidth: 991px)": {
  //   //   width: mini ? 0 : drawerWidth
  //   // }
  // };
  // } else {
  sidebar = {
    width: mini ? miniDrawerWidth : drawerWidth,
    background: "#e48a00"
    // "@media (maxWidth: 991px)": {
    //   width: mini ? 0 : drawerWidth
    // }
  };
  // }
  const navigate = useNavigate();
  // const closeIcon = {
  //   "@media (maxWidth: 991px)": {
  //     display: "block"
  //   }
  // };

  if (mini) {
    listNameStyle = {
      opacity: miniDrawerWidth === drawerWidth ? 1 : 0,
      transform:
        miniDrawerWidth === drawerWidth
          ? "translateZ(0)"
          : "translate3d(-25px,0,0)"
    };
    if (isIE) {
      appNameStyle = {
        height: miniDrawerWidth === drawerWidth ? "" : "0px"
      };
    }
  } else {
    listNameStyle = {
      opacity: !mini ? 1 : 0,
      transform: !mini ? "translateZ(0)" : "translate3d(-25px,0,0)"
    };
    if (isIE) {
      appNameStyle = {
        height: !mini ? "" : "0px"
      };
    }
  }
  return (
    // <SidebarWrapper
    //   className="d-none d-lg-block"
    // themeSetting={themeSetting}
    //   sidebarTheme={sidebarTheme}
    //   mini={mini}
    //   miniDrawerWidth={miniDrawerWidth}
    //   drawerWidth={drawerWidth}
    // >
    <div
      className="d-none d-sm-block mainSidebar"
      // themeSetting={themeSetting}
      // sidebarTheme={sidebarTheme}
      // mini={mini}
      // miniDrawerWidth={miniDrawerWidth}
      // drawerWidth={drawerWidth}
    >
      <div id="sidebar" className="sidebar sideBack shadow" style={sidebar}>
        <div className="sidebar-header d-none px-0 mb-3 ">
          <NavLink
            to={"/"}
            className="simple-text logo-mini"
            onClick={() => {
              if (!serverResponse.config.no_link_to_site)
                window.open(redirect, "_blank");
            }}
          >
            <div className="logo-img">
              <img
                src={interattivoIcon}
                alt="rvolantino interattivo"
                style={{ width: "45px", height: "auto" }}
              />
            </div>
          </NavLink>

          {!serverResponse.config.no_link_to_site && (
            <a href={redirect} rel="noopener noreferrer" target="_blank">
              <div
                className="logo-text simple-text custom-sidebar-header"
                style={appNameStyle}
              >
                <img
                  src={iconAppName}
                  className={
                    serverResponse.config.small_logo
                      ? "logo_small"
                      : "logo_full"
                  }
                  alt="react-logo"
                />
              </div>
            </a>
          )}
        </div>
        <div
          className="close-drawer-icon"
          // style={closeIcon}
          onClick={closeDrawer()}
        >
          <i className="fas fa-times-circle" />
        </div>

        <div className="sidebar-wrapper">
          <div className="logo text-center">
            <img
              src={themeMapping?.clientIcon}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = imgsrcDef;
              }}
              alt=""
            />
          </div>
          <div className="sidebarMainMenu" style={{ height: "100%" }}>
            {serverResponse.leaflet.index !== null && (
              <IndexNav
                // mini={props.themeSetting}
                setIndex={() => props.setIndexCarousel(0)}
                listNameStyle={listNameStyle}
              />
            )}
            <ul className="nav">
              {sidebarData.map((list, i) => {
                return (
                  <Fragment key={i}>
                    {list && list.type && list.type === "heading" ? (
                      (!mini || miniDrawerWidth === drawerWidth) && (
                        <div className="sidelist-header-name">{list.name}</div>
                      )
                    ) : (
                      <NavList
                        listNameStyle={listNameStyle}
                        list={list}
                        mini={mini}
                        miniDrawerWidth={miniDrawerWidth}
                        drawerWidth={drawerWidth}
                        forceDrawerMini={props.forceDrawerMini}
                        {...props}
                      />
                    )}
                  </Fragment>
                );
              })}
              {serverResponse.hasOwnProperty("video_categories") && (
                <li>
                  <button className="nav-link main-list">
                    <i className="fas fa-play-circle"></i>
                    <p style={listNameStyle}>
                      {language[defaultLanguage].video_tutorial}
                    </p>
                  </button>
                </li>
              )}
              {/* {serverResponse.config.sidebar.includes("share") &&
                manager.showList() && (
                  <ShareFlyer
                    forceDrawerMini={props.forceDrawerMini}
                    pStyle={listNameStyle}
                  />
                )} */}
            </ul>
            {!serverResponse.config.hide_grocery_list && (
              <>
                <div
                  className="sidebar-nav-item position-relative sidebar-nav-fix"
                  style={{
                    flex: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-end"
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    toggle();
                  }}
                >
                  <div
                    className="nav-link main-list"
                    id="list-cart"
                    title={language[defaultLanguage].la_tua_lista_della_spesa}
                  >
                    <CartIcon />
                    <span className="nav-item-label">Lista</span>
                    {/* <span className="cart-item-badge">1</span> */}
                    <div className="cart-badge-main">
                      <NotificationBadge
                        count={props.groceryList?.length}
                        // effect={Effect.SCALE}
                        className="cart-item-badge"
                      />
                    </div>
                  </div>
                </div>
                <GroceryList
                  isOpen={popoverOpen}
                  toggle={toggle}
                  placement={"right"}
                  target="list-cart"
                  isMobile={false}
                />
              </>
            )}
          </div>
          <div className="sidebar-bottom-content">
            {props.authJWT.user ? (
              <div className="d-flex user-profile-wrap">
                <img src={userImage} alt="" />
                <span className="user-name">
                  <UserAvatar />
                </span>
              </div>
            ) : serverResponse.config.allow_login ? (
              <p
                style={{
                  color: "var(--primary-color)",
                  fontSize: "14px",
                  fontWeight: "bold",
                  textAlign: "center",
                  paddingBottom: "5px",
                  cursor: "pointer"
                }}
                onClick={() => navigate("/login")}
                className="btn-login"
              >
                Accedi
              </p>
            ) : null}
          </div>
          {/* <p
            style={{
              position: "absolute",
              bottom: "0px",
              fontSize: "9px",
              width: "100%",
              textAlign: "center",
              fontWeight: "bold",
              color: "#c7c7c7",
            }}
          >
            v{process.env.REACT_APP_VERSION}
          </p> */}
          {/* <SearchNav mini={props.themeSetting} /> */}
          {/* <div style={{position: "absolute", bottom: "0px"}}>Powered by Luigi D'Arco</div> */}
        </div>
      </div>
      {/* </SidebarWrapper> */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    // themeSetting: state.themeSetting.sidebarMiniValue,
    groceryList: state.groceryList.list,
    authJWT: state.authJWT,
    themeSetting: "on"
  };
};

export default connect(mapStateToProps, { setIndexCarousel })(Sidebar);
