import React, { useCallback, useEffect, useState } from "react";
import * as services from "../../../../services/product.services";
import { serverResponse } from "../../../../util/fakeServer";
import { connect } from "react-redux";
import ManagerFactory from "../../../../managers/ManagerFactory";

const manager = ManagerFactory.getManager();

const Price = ({
  strike_price,
  price,
  offer_price,
  discount_rate,
  price_label,
  no_price,
  fontSize = "1rem",
  styleContainer = {},
  styleParagraph = "unset",
  customClass = true,
  skul,
  dynamic_price,
  dynamic_info,
  price_for_kg,
  left = "unset",
  top = "unset",
  getPrice = true,
  priceReducer
}) => {
  const formattedPrice = (el, symbol = "") =>
    `${symbol}${parseFloat(el).toFixed(2).toString().replace(".", ",")}`;

  const [serverPrice, setServerPrice] = useState(null);
  const [loadingPrice, setLoadingPrice] = useState(
    dynamic_price && !dynamic_info && getPrice
  );

  const priceKg =
    price_for_kg && price_for_kg !== "0.0000" ? price_for_kg : null;

  const getPriceForItem = useCallback(async () => {
    try {
      const {
        data: { data: res }
      } = await services.getPrices([skul], serverResponse.config.id_listino);

      if (res) {
        setServerPrice(res[0][skul]);
        setLoadingPrice(false);
      }
    } catch (error) {
      console.log(error);
    }
  }, [skul]);

  useEffect(() => {
    if (dynamic_price && !dynamic_info && getPrice) {
      getPriceForItem();
    }
  }, [dynamic_price, dynamic_info, getPrice, getPriceForItem]);

  const classes = "priceinner";
  const symbol = "€ ";

  if (
    (loadingPrice || priceReducer.isLoading) &&
    !(skul in priceReducer.productPrices) &&
    dynamic_price
  ) {
    return (
      <div
        className="spinner-grow "
        style={{
          width: "5rem",
          height: "5rem",
          color: serverResponse.config.secondary_color
        }}
        role="status"
      />
    );
  }

  const symbolEuro = (str) => (
    <>
      {str && !str.includes(symbol) && (
        <span
          className="currency"
          style={{
            // fontSize: "28px",
            // color: serverResponse.config.primary_color,
            fontWeight: "700",
            color: "#0d0040"
          }}
        >
          €{" "}
        </span>
      )}
      {str && str.toString().replace(/(\d+)\.(\d+)/g, "$1,$2")}
    </>
  );

  const hasEuroChar = price_label.indexOf("€") !== -1;

  const getPriceLabel = (
    <>
      <div
        className="product-price-block"
        style={{
          display: "inline-flex",
          flexDirection: "column"
        }}
      >
        <span
          className="price_span"
          style={{
            fontFamily: "Rubik",
            color: "#0D0040"
          }}
        >
          {dynamic_price && !dynamic_info
            ? symbolEuro(serverPrice)
            : offer_price &&
              offer_price.toString().replace(/(\d+)\.(\d+)/g, "$1,$2")
            ? symbolEuro(formattedPrice(offer_price))
            : serverResponse.config.client_id === 3531
            ? price_label
                .toString()
                .replace(/(\d+)\.(\d+)/g, "$1,$2")
                .replace("€", " ") + (hasEuroChar ? " €" : "")
            : symbolEuro(price_label.replace(/€\s?/, ""))}
        </span>
      </div>
      {!!manager.getIvaLabel() && (
        <span
          style={{
            fontSize: `calc(${fontSize} - 1rem)`,
            lineHeight: "2rem",
            fontWeight: "900",
            color: serverResponse.config.primary_color,
            whiteSpace: "nowrap"
          }}
          className="price_span d-block d-lg-inline"
        >
          {" "}
          {manager.getIvaLabel()}
        </span>
      )}
    </>
  );

  return (
    <>
      {!!(!getPrice && dynamic_price) && (
        <>
          <p
            className="price_span mt-3"
            style={{
              fontSize: fontSize,
              lineHeight: "2rem",
              fontWeight: "900",
              color: serverResponse.config.primary_color,
              whiteSpace: "nowrap"
            }}
          >
            {symbolEuro(priceReducer.productPrices[skul])}
          </p>
        </>
      )}
      {!no_price && (
        // <div className={`product-price`} style={styleContainer}>
        <>
          {priceKg && (
            <p
              className="priceKg"
              // style={{
              //   right: "3px",
              //   fontSize: "0.7rem",
              //   left: left,
              //   top: top,
              //   lineHeight: "1rem",
              // }}
            >
              al kg € {formattedPrice(priceKg)}
            </p>
          )}
          {offer_price && (
            <p
              className={`${customClass ? classes : ""}`}
              style={{ fontWeight: "bold", paddingRight: styleParagraph }}
            >
              {discount_rate !== undefined &&
                parseFloat(discount_rate) !== 0 &&
                discount_rate !== null && (
                  <span className="discount-rate">{discount_rate}%</span>
                )}
              {getPriceLabel}
              <sup className="barrato offerPrice">
                {dynamic_price && !dynamic_info ? serverPrice : `€ ${price}`}
              </sup>
            </p>
          )}
          {!offer_price && (
            <div className="product-price-block">
              <div className="priceBlock">
                {getPriceLabel}
                {discount_rate !== undefined &&
                  parseFloat(discount_rate) !== 0 &&
                  discount_rate !== null && (
                    <span className="discount-rate">{discount_rate}%</span>
                  )}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    priceReducer: state.priceReducer
  };
};
export default connect(mapStateToProps, null)(Price);
