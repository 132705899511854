import React from "react";
import { connect } from "react-redux";

import GalleryModal from "./components/Gallery.modal";
import ListModal from "./components/List.modal";
import TitleModal from "./components/Title.modal";

const SpecsBody = ({ modalRedux }) => {
  const {
    otherProps: {
      markerInfo: { title, data }
    }
  } = modalRedux;

  return (
    <div className="row">
      <div
        className="col-12 col-sm-6 h-lg-100"
        style={{ position: "relative" }}
      >
        <GalleryModal minImg={false} gallery={data.img} />
      </div>

      <div className="col-12 col-sm-6">
        <div
          className="mobilelandscape bodyContentSection"
          style={{ height: "calc(80vh - 210px)", overflow: "auto" }}
        >
          <div className="mb-3 pt-3">
            <TitleModal title={title} />
          </div>
          <div className="py-3">
            <ListModal list={data.specifiche} />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    modalRedux: state.modal
  };
};
export default connect(mapStateToProps)(SpecsBody);
