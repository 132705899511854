import * as React from "react";
const ShareIcon = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.0625 15.975C11.5125 15.975 11.925 15.6 11.925 15.1125V9.48752C11.925 7.46252 13.575 5.81252 15.6 5.81252H20.0625L17.8875 7.87502C17.55 8.21252 17.55 8.73752 17.85 9.07502C18 9.26252 18.225 9.33752 18.45 9.33752C18.675 9.33752 18.8625 9.26252 19.0125 9.11252L22.4625 5.85002C22.6875 5.62502 22.8375 5.28752 22.8375 4.95002C22.8375 4.61252 22.6875 4.31252 22.4625 4.08752L19.0125 0.900024C18.675 0.600024 18.15 0.600024 17.8125 0.937524C17.5125 1.27502 17.5125 1.80002 17.85 2.13752L20.025 4.16252H15.525C12.5625 4.16252 10.1625 6.56252 10.1625 9.52502V15.15C10.2375 15.6 10.6125 15.975 11.0625 15.975Z"
      fill="black"
    />
    <path
      d="M22.125 14.2875C21.675 14.2875 21.2625 14.6625 21.2625 15.15V20.5875C21.2625 21.1875 20.775 21.675 20.175 21.675H3.82501C3.22501 21.675 2.73751 21.1875 2.73751 20.5875V15.1125C2.73751 14.6625 2.36251 14.25 1.87501 14.25C1.38751 14.25 1.01251 14.625 1.01251 15.1125V20.55C1.01251 22.0875 2.25001 23.325 3.78751 23.325H20.175C21.7125 23.325 22.95 22.0875 22.95 20.55V15.1125C22.9875 14.6625 22.6125 14.2875 22.125 14.2875Z"
      fill="black"
    />
  </svg>
);
export default ShareIcon;
