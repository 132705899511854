import React, { useState } from "react";
import { connect } from "react-redux";
import { setStatusOfMenu } from "../../redux/floatingMenu/action";
// import Plus from "../../assets/images/icone/Plus.icona";
import MenuIcon from "../../assets/icons/menu.icon";

export const AB = (p) => (
  <button type="button" {...p} className="rtf--ab">
    {p.children}
  </button>
);

export const MB = (p) => (
  <button type="button" className="rtf--mb" {...p}>
    {p.children}
  </button>
);

const defaultPosition = { bottom: 10, left: 10 };

const Fab = ({
  event = "click",
  position = defaultPosition,
  alwaysShowTitle = false,
  children,
  icon,
  mainButtonStyles,
  floatingMenu,
  subNav,
  isSubMenuOpen,
  setIsSubMenuOpen,
}) => {
  // const open = () => setStatusOfMenu(true);
  // const close = () => setStatusOfMenu(false);
  // const enter = () => event === "hover" && open();
  // const leave = () => event === "hover" && close();
  // const toggle = () =>
  //   event === "click" ? (floatingMenu ? close() : open()) : null;

  // const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const actionOnClick = (userFunc) => {
    setStatusOfMenu(false);

    setTimeout(() => {
      userFunc();
    }, 1);
  };

  const rc = () => {
    // if (React.Children.count(children) > 6)
    //   console.warn("react-tiny-fab only supports up to 6 action buttons");
    // const ariaHidden = alwaysShowTitle || !floatingMenu;

    return subNav.map(
      (ch, i) =>
        ch && (
          <li
            className="footer-subnav-links"
            key={ch.id}
            onClick={() => {
              actionOnClick(ch.onClick);
              setIsSubMenuOpen(() => !isSubMenuOpen);
            }}
          >
            {ch.svg}
            {ch.text}
          </li>
          // <li className={`rtf--ab__c ${"top" in position ? "top" : ""}`}>
          //   {React.cloneElement(ch, {
          //     "data-testid": `action-button-${i}`,
          //     "aria-label": ch.props.text || `Menu button ${i + 1}`,
          //     "aria-hidden": ariaHidden,
          //     ...ch.props,
          //     onClick: () => actionOnClick(ch.props.onClick),
          //   })}
          //   {ch.props.text && (
          //     <span
          //       onClick={() => actionOnClick(ch.props.onClick)}
          //       className={`${"right" in position ? "right" : ""} ${
          //         alwaysShowTitle ? "always-show" : ""
          //       }`}
          //       aria-hidden={ariaHidden}
          //     >
          //       {ch.props.text}
          //     </span>
          //   )}
          // </li>
        )
    );
  };

  return (
    <>
      {/* onClick={setIsSubMenuOpen(() => !isSubMenuOpen)} */}
      <button
        className="footer-nav-btn menu-icon"
        data-testid="main-button"
        aria-label="Floating menu"
        onClick={() => setIsSubMenuOpen(() => !isSubMenuOpen)}
      >
        <MenuIcon />
      </button>
      <div
        style={{
          height: "100vh",
          width: "100vw",
          zIndex: "9",
          position: "fixed",
          left: "0px",
          top: "0px",
          display: `${isSubMenuOpen ? "block" : "none"}`,
        }}
        onClick={() => setIsSubMenuOpen(() => !isSubMenuOpen)}
      ></div>
      <ul className={`footer-sub-nav ${isSubMenuOpen ? "open" : "closed"}`}>
        {rc()}
      </ul>
    </>
    // <ul
    //   onMouseEnter={enter}
    //   onMouseLeave={leave}
    //   className={`rtf ${floatingMenu ? "open" : "closed"}`}
    //   data-testid="fab"
    //   style={position}
    // >
    //   <li className="rtf--mb__c">
    //     <MB
    //       onClick={toggle}
    //       style={mainButtonStyles}
    //       data-testid="main-button"
    //       role="button"
    //       aria-label="Floating menu"
    //       tabIndex="0"
    //     >
    //       <Plus style={{ width: "20px" }} />
    //       <i className={icon}></i>
    //     </MB>
    //     <ul className="jsdhfghsdgjh">{rc()}</ul>
    //   </li>
    // </ul>
  );
};

const mapStateToProps = (state) => {
  return {
    floatingMenu: state.floatingMenu.isOpen,
  };
};

const mapDispatchToProps = { setStatusOfMenu };

export default connect(mapStateToProps, mapDispatchToProps)(Fab);
