import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import "../../assets/css/grocery-product.css";
import { CustomInput, Tooltip } from "reactstrap";

import { connect } from "react-redux";
import actions from "../../redux/groceryList/action";
import { serverResponse } from "../../util/fakeServer";

import { strings } from "../../util/data/strings";
// import { isMobile } from "react-device-detect";
// import { EliminaCarrello, EliminaLista } from "../../assets/images/icone";

const img_custom = require("../../assets/images/prodotto.png");

class GroceryProduct extends Component {
  state = {
    deleteTooltipOpen: false,
  };

  incrementQty = () => {
    let qty =
      this.props.quantity +
      (this.props.quantity_step ? this.props.quantity_step : 1);
    this.props.changeQuantity({
      quantity: qty,
      item: this.props,
    });
  };

  decrementQty = () => {
    let qty = this.props.quantity;
    if (this.props.quantity_step) {
      if (qty > this.props.quantity_step) {
        qty -= this.props.quantity_step;
      }
    } else if (qty > 1) {
      qty--;
    }

    this.props.changeQuantity({
      quantity: qty,
      item: this.props,
    });
  };

  checkProduct = () => {
    this.props.checkProduct({
      item: this.props,
    });
  };

  deleteProduct = () => {
    this.props.deleteProduct({
      item: this.props,
    });
    if (document.getElementById("selected-" + this.props.id) != null)
      document.getElementById("selected-" + this.props.id).style["display"] =
        "none";
  };

  toggleDeleteTooltip = () => {
    this.setState({ deleteTooltipOpen: !this.state.deleteTooltipOpen });
  };

  render() {
    const {
      title,
      price,
      variety,
      quantity,
      grocery_label,
      done,
      images,
      id,
      no_price,
    } = this.props;

    const varieryLabel = variety ? (
      <p
        style={{
          maxWidth: "100px",
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
          fontSize: "12px",
        }}>
        {variety}
      </p>
    ) : null;

    // const iconRemoveProduct = serverResponse.config.send_grocery_list_to_market
    //   ? CartRemove
    //   : RemoveProd;

    return (
      <ul className="cart-product-card">
        {/* style={{
          alignItems: "center",
          width: isMobile ? "100%" : "100%",
          overflowX: "hidden",
        }} */}
        {false && (
          <li className="notification-popover-profile">
            <CustomInput
              type="checkbox"
              id={"checkbox-" + this.props.id}
              onChange={() => this.checkProduct()}
              checked={done}
            />
          </li>
        )}
        <li className="cart-product-card-item" style={{ alignItems: "center" }}>
          <div className="product-card-item-wrap">
            <div className="product-image">
              <img
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = img_custom;
                }}
                src={
                  images &&
                  images !== undefined &&
                  images !== null &&
                  images.length > 0
                    ? process.env.PUBLIC_URL + images[0].image_file
                    : serverResponse.config.clientIcon
                }
                alt="img"
              />
            </div>
            <div className="product-details-wrap">
              <div className="product-details">
                <h6>
                  {title} {varieryLabel}
                </h6>
                <span className="price">{!no_price && `${price} €`}</span>
              </div>
              <div className="quantity-wrap">
                <div className="grocery-product-quantity productquantity">
                  <span className="quantity-icon">
                    <FontAwesomeIcon
                      icon={faMinus}
                      onClick={() => this.decrementQty()}
                    />
                  </span>
                  <span className="d-flex quantity-text">
                    <h6>{quantity}</h6>
                    <h6>{grocery_label ? grocery_label : "pz."}</h6>
                  </span>
                  <span className="quantity-icon">
                    <FontAwesomeIcon
                      icon={faPlus}
                      onClick={() => this.incrementQty()}
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="delete-icon-wrap">
              {/*<FontAwesomeIcon icon={faTrashAlt} style={{ color: "#ff0000", marginLeft: "20px" }} onClick={() => this.deleteProduct()} />*/}
              <div
                id={"btnDelete_" + id}
                onClick={() => this.deleteProduct()}
                className="delete-icon"
              >
                <span>
                  {serverResponse.config.send_grocery_list_to_market ? (
                    <FontAwesomeIcon icon={faTrashAlt} />
                  ) : (
                    <FontAwesomeIcon icon={faTrashAlt} />
                  )}
                </span>
              </div>

              <Tooltip
                placement="right"
                isOpen={this.state.deleteTooltipOpen}
                target={"btnDelete_" + id}
                toggle={this.toggleDeleteTooltip}
              >
                {strings.deleteBtnTooltip}
              </Tooltip>
            </div>
          </div>
          {/* <h6 style={{ fontSize: "0.8rem" }} className="reduceMobile"></h6> */}
        </li>
      </ul>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    changeQuantity: (newQuantity) => {
      dispatch(actions.changeQuantity(newQuantity));
    },
    checkProduct: (check) => {
      dispatch(actions.checkProduct(check));
    },
    deleteProduct: (deleted) => {
      dispatch(actions.deleteProduct(deleted));
    },
  };
}

export default connect(null, mapDispatchToProps)(GroceryProduct);
