import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { serverResponse } from "../util/fakeServer";
import { useLocation } from "react-router-dom";
import { allowPath } from "../helper/utility";

const PrivateRoute = ({
  element: Element,
  authJWT: { isAuthenticated, loadingProfile },
  ...rest
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (
  //     serverResponse.config.allow_login &&
  //     serverResponse.config.only_logged &&
  //     !isAuthenticated &&
  //     loadingProfile &&
  //     !allowPath.includes(location.pathname)
  //   ) {
  //     navigate('/login', { state: { referrer: location.pathname } });
  //   }
  // }, [isAuthenticated, loadingProfile, location.pathname, navigate]);

  return (
    <Routes>
      <Route
        {...rest}
        render={() =>
          serverResponse.config.allow_login &&
            serverResponse.config.only_logged &&
            !isAuthenticated &&
            loadingProfile &&
            !allowPath.includes(location.pathname) ? (
            navigate('/login', { state: { referrer: location.pathname } })
          ) : (
            <Element {...rest} />
          )
        }
      />
    </Routes>
  );
  // return <Route {...rest} element={<Element />} />;
};

PrivateRoute.propTypes = {
  authJWT: PropTypes.object.isRequired,
  element: PropTypes.elementType.isRequired // Specify element prop type
};

const mapStateToProps = (state) => ({
  authJWT: state.authJWT
});

export default connect(mapStateToProps)(PrivateRoute);
