import * as React from "react";
const CuriosityIcon = (props) => (
  <svg
    width="14"
    height="20"
    viewBox="0 0 14 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.375 0.5625H2.625C1.5625 0.5625 0.65625 1.4375 0.65625 2.53125V16.125C0.65625 16.9688 1.21875 17.6875 1.96875 17.9688V18.75C1.96875 19.125 2.28125 19.4688 2.6875 19.4688C3.09375 19.4688 3.40625 19.1562 3.40625 18.75V18.0312H11.375C12.4375 18.0312 13.3438 17.1562 13.3438 16.0625V2.5C13.3438 1.4375 12.4688 0.5625 11.375 0.5625ZM2.625 16.625C2.3125 16.625 2.0625 16.375 2.0625 16.0625V12.4062H8.375V16.625H2.625ZM2.0625 11V6.78125H8.375V11H2.0625ZM9.78125 6.78125H11.9375V11H9.78125V6.78125ZM11.9375 2.5V5.375H9.78125V1.96875H11.375C11.6875 1.96875 11.9375 2.1875 11.9375 2.5ZM2.625 1.96875H8.375V5.375H2.0625V2.5C2.0625 2.1875 2.3125 1.96875 2.625 1.96875ZM11.375 16.625H9.78125V12.4062H11.9375V16.0938C11.9375 16.4062 11.6875 16.625 11.375 16.625Z"
      fill="currentcolor"
    />
  </svg>
);
export default CuriosityIcon;
