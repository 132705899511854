import React, { Component } from "react";
import "../../assets/css/custom.css";
import { serverResponse } from "../../util/fakeServer";
import { connect } from "react-redux";
import { isModalOpen } from "../../redux/carousel/action";
import { setVisibilityVideo } from "../../redux/videoManager/action";
import Marker from "./markers/Marker";
import VideoInteractivity from "./videoInteractivity/VideoInteractivity";
import ShowPriceMarker from "./markers/ShowPriceMarker";
import CustomPrice from "./CustomPrice";
import ManagerFactory from "../../managers/ManagerFactory";

var marker = serverResponse.config.marker
  ? serverResponse.config.marker
  : "circle_black";
const selected = require("../../assets/images/" + marker + ".svg");

const manager = ManagerFactory.getManager();

class CustomProduct extends Component {
  state = {
    tooltipOpen: false
  };

  hasTooltip = () => {
    let result = this.props.info.type !== "product";

    return (
      result &&
      this.props.info.markers[0].data.tooltip !== "" &&
      this.props.info.markers[0].data.tooltip !== undefined
    );
  };

  render() {
    const styleProduct = {
      position: "absolute",
      display: this.props.info.blueprint !== null ? "flex" : "none",
      textAlign: "center",
      justifyContent: "center",
      alignItems: "center",
      top:
        this.props.blueprint !== null ? this.props.blueprint.top + "%" : "0%",
      left:
        this.props.info.blueprint !== null
          ? this.props.blueprint.left + "%"
          : "0%",
      width:
        this.props.blueprint !== null ? this.props.blueprint.width + "%" : "0%",
      height:
        this.props.blueprint !== null ? this.props.blueprint.height + "%" : "0%"
    };

    let markers = this.props.info.markers
      .filter((e) => manager.getVisibleInteractivities().includes(e.type))
      .map((element, index) => {
        if (
          this.props.info.type === "video" &&
          !this.props.info.markers[0].data.open_modal
        ) {
          return (
            <VideoInteractivity
              key={index}
              info={this.props.info}
              markerInfo={element}
              leafletSwipe={this.props.leafletSwipe}
              type={element.type}
            />
          );
        }
        return (
          <Marker
            from_leaflet={true}
            key={index}
            info={this.props.info}
            markerInfo={element}
            leafletSwipe={this.props.leafletSwipe}
            type={element.type}
            dynamic_price={this.props.dynamic_price}
            dynamic_info={this.props.dynamic_info}
            show_dynamic_price={this.props.show_dynamic_price}
          />
        );
      });
    const video = document.querySelector(`#video_${this.props.info.item_id}`);
    if (video)
      video.onended = () => {
        const videoDiv = document.querySelector(
          `#div-${this.props.info.item_id}`
        );
        if (
          videoDiv &&
          this.props.info.markers[0].data.link.match("interattivo_demo")
        )
          videoDiv.classList.add("d-none");
        this.props.setVisibilityVideo();
      };
    let markers_2 = [];
    if (this.props.info.hidden_price) {
      markers_2 = this.props.info.markers.map((element, key) => {
        switch (element.type) {
          case "play": {
            return (
              <ShowPriceMarker
                key={key + 10}
                info={this.props.info}
                markerInfo={element}
                leafletSwipe={this.props.leafletSwipe}
                navMarkers={this.navMarkers}
                setSwipe={this.props.setSwipe}
                isSwipeDisabed={this.props.isSwipeDisabed}
              />
            );
          }
          default:
            return null;
        }
      });
    }
    markers = markers.concat(markers_2);

    var id_select = "selected-" + this.props.info.item_id;
    var id_div = "div-" + this.props.info.item_id;

    const isVisible = () => {
      if (
        markers[0].props.id === "videoIper" &&
        !this.props.managerVideo.videoShow &&
        this.props.info.markers[0].data.link.match("interattivo_demo")
      )
        return "d-none";

      return "";
    };

    const setZIndex = () => {
      if (
        markers[0].props.id === "videoIper" &&
        this.props.managerVideo.videoShow &&
        this.props.info.markers[0].data.link.match("interattivo_demo")
      )
        return "show-first";

      return "";
    };

    if (markers[0] === undefined) {
      return <></>;
    }

    return (
      <div
        data-type="customProduct"
        onMouseEnter={() => {
          this.setState({ tooltipOpen: true });
        }}
        onMouseLeave={() => {
          this.setState({ tooltipOpen: false });
        }}
        title={""}
        id={id_div}
        type="fadeAnimationInt"
        className={`customProduct ${isVisible()} ${setZIndex()}`}
        style={styleProduct}
        onClick={(e) => {
          if (e.target.dataset.type === "customProduct") {
            const markerButton = document.querySelector(
              `#${this.props.info.markers[0].type}-${this.props.info.item_id}`
            );
            if (markerButton) markerButton.click();
          }
        }}
      >
        {this.props.info.type === "product" &&
          this.props.dynamic_price === true &&
          this.props.show_dynamic_price === true && (
            <CustomPrice
              dynamic_price_position={this.props.dynamic_price_position}
              onClick={(e) => {
                document
                  .querySelector(
                    `#${this.props.info.markers[0].type}-${this.props.info.item_id}`
                  )
                  .click();
              }}
              pageNumber={this.props.pageNumber}
              blueprint={this.props.info.blueprint}
              item={this.props.info}
              fontSizeServer={{
                desktop: serverResponse.config.price_text_size_d
                  ? serverResponse.config.price_text_size_d
                  : serverResponse.config.price_text_size,
                tablet: serverResponse.config.price_text_size_t
                  ? serverResponse.config.price_text_size_t
                  : serverResponse.config.price_text_size,
                mobile: serverResponse.config.price_text_size_m
                  ? serverResponse.config.price_text_size_m
                  : serverResponse.config.price_text_size
              }}
            />
          )}
        {serverResponse.config.marker_product_in_list &&
          this.props.groceryList.list.filter(
            (el) => el.item_id === this.props.info.item_id
          ).length > 0 && (
            <img
              src={selected}
              style={{
                position: "absolute",
                display: "block",
                width: "100%"
              }}
              alt="prodotto selezionato"
              id={id_select}
              onClick={() => document.getElementById(id_div).click()}
            />
          )}

        {markers}
        {this.state.tooltipOpen && this.hasTooltip() && (
          <div
            className="custom-tooltip"
            style={{
              fontSize: "12px",
              fontWeight: "bold",
              position: "absolute",
              top: "10%",
              backgroundColor: "var(--primary-color)",
              borderRadius: "0.25rem",
              padding: "0.25rem",
              color: "white"
            }}
          >
            <p
              style={{ cursor: "pointer" }}
              onClick={() => document.getElementById(id_div).click()}
            >
              {this.props.info.markers[0].data.tooltip}
            </p>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    carouselIndex: state.carousel,
    groceryList: state.groceryList,
    managerVideo: state.managerVideo
  };
};

export default connect(mapStateToProps, { isModalOpen, setVisibilityVideo })(
  CustomProduct
);
