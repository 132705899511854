import React from "react";
import { connect } from "react-redux";
import { setIndexCarousel } from "../../redux/carousel/action";
import { styles } from "./carouselArrow.style";
import useCurrentWidth from "../../hooks/widthHook";
import { serverResponse } from "../../util/fakeServer";
import { BREAKPOINT } from "../../helper/constant";
import { scrollToSlide } from "../../helper/utility";
import { ArrowLx, ArrowRx } from "../../assets/images/icone";

const CarouselArrow = (props) => {
  const styleArrow = {
    width: props.size || "10px",
    height: props.size || "10px",
    cursor: "pointer",
  };

  const icon =
    props.direction === "left" ? (
      <ArrowLx style={styleArrow} />
    ) : (
      <ArrowRx style={styleArrow} />
    );
  const iconFirstLast =
    props.direction === "left" ? (
      <>
        <ArrowLx style={styleArrow} />
        <ArrowLx style={styleArrow} />
      </>
    ) : (
      <>
        <ArrowRx style={styleArrow} />
        <ArrowRx style={styleArrow} />
      </>
    );

  const width = useCurrentWidth();

  const isDouble = props.double !== undefined ? props.double : true;

  const carousel = props.carousel
    ? ""
    : "carousel-pagination-button btn d-flex";

  const additionalIndex = props.direction === "left" ? -1 : +1;

  return (
    <div style={styles.container}>
      {/* CONTAINER FIRST PAGE */}
      {props.direction === "left" && isDouble && (
        <div
          className="carousel-pagination-button btn d-flex"
          style={Object.assign(
            {},
            styles.cursorStyle(props.carouselIndex.buttonCarouselDisabled),
            styles.borderLeft,
            {
              zIndex: "1",
              opacity: props.carouselIndex.buttonCarouselDisabled
                ? "0.7 "
                : "1",
            }
          )}
          onClick={() => {
            if (
              props.carouselIndex.buttonCarouselDisabled ||
              props.carouselIndex.carouselIndex === 0
            ) {
              return;
            } else {
              props.setIndexCarousel(0);
              if (
                (serverResponse.config.scrollVertical ||
                  serverResponse.config.scrollHorizontal) &&
                width < BREAKPOINT.xs
              ) {
                scrollToSlide(1);
              }
            }
          }}
        >
          {iconFirstLast}
        </div>
      )}
      {/* SIMPLE ARROW */}
      <div
        className={carousel}
        style={Object.assign({}, styles.noBorderRadius, {
          zIndex: "1",
          opacity: props.carouselIndex.buttonCarouselDisabled ? "0.7 " : "1",
          fontSize: "40px",
        })}
        onClick={() => {
          if (
            props.carouselIndex.buttonCarouselDisabled ||
            (props.direction === "left" &&
              props.carouselIndex.carouselIndex === 0) ||
            (props.direction === "right" &&
              props.carouselIndex.carouselIndex === global.max - 1)
          ) {
            return;
          } else {
            props.setIndexCarousel(
              props.carouselIndex.carouselIndex + additionalIndex
            );
            if (
              (serverResponse.config.scrollVertical ||
                serverResponse.config.scrollHorizontal) &&
              width < BREAKPOINT.xs
            ) {
              props.direction === "left"
                ? scrollToSlide(props.carouselIndex.carouselIndex)
                : scrollToSlide(props.carouselIndex.carouselIndex + 2);
            }
          }
        }}
      >
        {icon && icon}
      </div>

      {/* CONTAINER LAST PAGE */}
      {props.direction === "right" && isDouble && (
        <div
          className="carousel-pagination-button btn d-flex"
          style={Object.assign(
            {},
            styles.cursorStyle(props.carouselIndex.buttonCarouselDisabled),
            styles.borderRight,
            {
              zIndex: "1",
              opacity: props.carouselIndex.buttonCarouselDisabled
                ? "0.7 "
                : "1",
            }
          )}
          onClick={() => {
            if (
              props.carouselIndex.buttonCarouselDisabled ||
              props.carouselIndex.carouselIndex === global.max - 1
            ) {
              return;
            } else {
              props.setIndexCarousel(global.max - 1);
              if (
                (serverResponse.config.scrollVertical ||
                  serverResponse.config.scrollHorizontal) &&
                width < BREAKPOINT.xs
              ) {
                scrollToSlide(global.max);
              }
            }
          }}
        >
          {iconFirstLast}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    carouselIndex: state.carousel,
  };
};

export default connect(mapStateToProps, { setIndexCarousel })(CarouselArrow);
