import * as React from "react";

const Web = (props) => (
  <svg
    height="18"
    width="19"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1967_2079)">
      <path
        d="M29.2 8.55003C27.15 5.00003 23.7 2.35002 19.7 1.35002C18.4 1.05002 17.2 0.900024 16 0.900024C14.75 0.900024 13.45 1.05002 12.2 1.40002C8.25 2.40002 4.85 5.00003 2.85 8.60003C1.55 10.9 0.900002 13.4 0.900002 16V16.35C0.950002 19.1 1.8 21.8 3.25 24.15C5.65 27.95 9.6 30.45 14 30.95C14.6 31.05 15.25 31.1 16 31.1C16.6 31.1 17.25 31.05 17.85 31C22.35 30.45 26.3 27.95 28.75 24.15C30.25 21.85 31.05 19.15 31.15 16.35V16C31.15 13.5 30.45 10.9 29.2 8.55003ZM25.65 15.3C25.5 13.65 25.1 12.05 24.45 10.55H27.65C28.35 12.05 28.75 13.7 28.85 15.3H25.65ZM3.15 15.3C3.25 13.65 3.65 12.05 4.35 10.55H7.25C6.6 12.05 6.25 13.65 6.1 15.3H3.15ZM9.75 10.55H14.85V15.3H8.4C8.55 13.6 9 12 9.75 10.55ZM20.6 8.30003H17.1V4.15002C18.25 5.05002 19.4 6.45003 20.6 8.30003ZM14.9 4.10002V8.30003H11.15C12.4 6.40003 13.65 5.00002 14.9 4.10002ZM14.9 17.55V22.3H9.9C9.1 20.85 8.6 19.2 8.45 17.55H14.9ZM14.9 24.55V28C13.65 27.15 12.4 25.95 11.3 24.55H14.9ZM17.15 28V24.5H20.95C19.9 25.95 18.6 27.15 17.15 28ZM17.15 22.3V17.55H23.45C23.35 19.2 22.95 20.8 22.25 22.3H17.15ZM17.15 15.3V10.55H22C22.75 12 23.25 13.65 23.4 15.3H17.15ZM26.3 8.30003H23.25C22.15 6.45003 21.05 4.95003 19.95 3.80002C22.5 4.60002 24.75 6.20003 26.3 8.30003ZM11.75 3.85002C10.65 5.00002 9.55 6.50002 8.45 8.25003H5.75C7.25 6.25002 9.35 4.70002 11.75 3.85002ZM3.2 17.55H6.15C6.3 19.2 6.7 20.8 7.35 22.3H4.75C3.9 20.8 3.4 19.2 3.2 17.55ZM6.35 24.55H8.6C9.4 25.85 10.4 27.05 11.4 28.05C9.45 27.3 7.7 26.1 6.35 24.55ZM20.95 27.9C21.95 26.95 22.85 25.8 23.55 24.6C23.55 24.6 23.55 24.55 23.6 24.55H25.65C24.35 26 22.75 27.15 20.95 27.9ZM27.25 22.3H24.65C25.25 20.8 25.6 19.2 25.65 17.55H28.75C28.6 19.2 28.05 20.8 27.25 22.3Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1967_2079">
        <rect width={40} height={40} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default Web;
