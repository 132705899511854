import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Input, InputGroup } from "reactstrap";
import { language } from "../../languageProvider/language/language";
import { defaultLanguage } from "../../helper/utility";
import { openInteractivityModal } from "../../redux/modal/action";
import { Search } from "../../assets/images/icone";
import { serverResponse } from "../../util/fakeServer";
import { setSearchbarWidth } from "../../redux/carousel/action";
import { connect } from "react-redux";
const SearchBar = (props) => {
  const [term, setTerm] = useState("");
  const [products, setProducts] = useState([]);

  const ELEMENTS = useMemo(() => {
    return Object.values(serverResponse.interactivities).filter(
      (el) => el.type === "product"
    );
  }, []);

  const handleSearch = useCallback(() => {
    const filteredElement = ELEMENTS.filter((prod) => {
      const regex = new RegExp(term, "i");
      return regex.test(prod.description.toLowerCase());
    });

    setProducts(filteredElement);
  }, [term, ELEMENTS]);

  useEffect(() => {
    if (term.length > 2) {
      handleSearch();
    }
  }, [term, handleSearch]);

  return (
    <div style={props.style} className="container-fluid search_box_container">
      <div className="search_bar">
        <div className="theme-brand text-center">
          <img
            style={{
              width: "100px",
              marginBottom: "1rem"
            }}
            className="img-fluid"
            src={serverResponse.config.logo_full}
          />
        </div>
        <InputGroup className="custom-search-bar-wrapper">
          <Input
            type="text"
            name="serach"
            id="search"
            placeholder={language[defaultLanguage].cerca_un_prodotto}
            //   defaultValue={this.state.searchText}
            onChange={(e) => setTerm(e.target.value)}
          />
          {/* <InputGroupAddon addonType="append"> */}
          <Search />
          {/* </InputGroupAddon> */}
        </InputGroup>
        <div className="search-result-box">
          {products.map((el, index) => {
            return (
              <div
                style={{
                  cursor: "pointer"
                }}
                onClick={() => {
                  props.setSearchbarW();
                  props.openModal(
                    {
                      info: el,
                      type: "plus",
                      dynamic_price: el.dynamic_price
                    },
                    "plus"
                  );
                }}
                className="search-result"
                key={`${el.id}_${index}`}
              >
                <img
                  className="img-fluid"
                  style={{ width: "80%" }}
                  src={el.images[0].image_file}
                  alt={`${el.field1}`}
                />
                <h4 className="result-title">{el.field1}</h4>
                <p>
                  {el.field2} {el.field3} {el.field4}...
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    // themeSetting: state.themeSetting.sidebarMiniValue,
    themeSetting: "on",
    searchBarOpen: state.carousel.searchBarOpen
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setSearchbarW: () => {
      dispatch(setSearchbarWidth());
    },
    openModal: (obj, code) => {
      dispatch(openInteractivityModal(obj, code));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
