import * as React from "react";
const Allegato = (props) => (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.16418 2.52196C9.37122 0.409345 12.9398 0.409345 15.1468 2.52196C17.3677 4.64785 17.3677 8.10525 15.1468 10.2311L9.18575 15.9371C7.61823 17.4377 5.08649 17.4377 3.51894 15.9371C1.93752 14.4234 1.93752 11.9584 3.51894 10.4447L9.39425 4.82078C10.3223 3.93243 11.8172 3.93244 12.7452 4.82078C13.6872 5.72242 13.6872 7.19487 12.7452 8.09647L6.82709 13.7614C6.60267 13.9763 6.2466 13.9685 6.03178 13.744C5.81697 13.5196 5.82475 13.1636 6.04917 12.9487L11.9673 7.28381C12.4464 6.82516 12.4464 6.09214 11.9673 5.63347C11.4743 5.16154 10.6652 5.16154 10.1721 5.63347L4.29686 11.2573C3.17821 12.3282 3.17821 14.0536 4.29686 15.1244C5.42938 16.2085 7.27533 16.2085 8.40785 15.1244L14.3689 9.41842C16.127 7.7355 16.127 5.01757 14.3689 3.33465C12.5969 1.63845 9.71412 1.63845 7.9421 3.33465L3.13896 7.9323C2.91454 8.1471 2.55847 8.1393 2.34366 7.9149C2.12884 7.6905 2.13662 7.33443 2.36104 7.11961L7.16418 2.52196Z"
      fill="currentColor"
    />
  </svg>
);
export default Allegato;
