let Components = {};

Components["plus"] = require("./PlusBody").default;
Components["specs"] = require("./SpecsBody").default;
Components["play"] = require("./PlayBody").default;
Components["info"] = require("./InfoBody").default;
Components["hat-chef"] = require("./HatChefBody").default;
Components["carousel"] = require("./CarouselBody").default;
Components["promo"] = require("./PromoBody").default;
Components["world"] = require("./WorldBody").default;
Components["attachment"] = require("./AttachmentBody").default;

export default Components;
