import * as React from "react";
const LinkIcon = (props) => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.7095 5.02802H9.22517C7.53767 5.02802 6.15955 6.40614 6.15955 8.09364V8.23426C6.15955 9.92176 7.53767 11.2999 9.22517 11.2999H9.67517V10.0624H9.22517C8.2408 10.0624 7.42517 9.24676 7.42517 8.26239V8.09364C7.42517 7.10926 8.2408 6.29364 9.22517 6.29364H14.7095C15.6939 6.29364 16.5095 7.10926 16.5095 8.09364V8.23426C16.5095 9.21864 15.6939 10.0343 14.7095 10.0343H12.5158V11.2999H14.7095C16.397 11.2999 17.7752 9.92176 17.7752 8.23426V8.09364C17.8033 6.40614 16.4252 5.02802 14.7095 5.02802Z"
      fill="currentcolor"
    />
    <path
      d="M8.77522 7.67188H8.2971V8.9375H8.77522C9.7596 8.9375 10.5752 9.75313 10.5752 10.7375V10.9062C10.5752 11.8906 9.7596 12.7063 8.77522 12.7063H3.29085C2.30647 12.7063 1.49084 11.8906 1.49084 10.9062V10.7656C1.49084 9.78125 2.30647 8.96563 3.29085 8.96563H5.42835V7.7H3.29085C1.60334 7.7 0.22522 9.07812 0.22522 10.7656V10.9062C0.22522 12.5938 1.60334 13.9719 3.29085 13.9719H8.77522C10.4627 13.9719 11.8408 12.5938 11.8408 10.9062V10.7656C11.8408 9.05 10.4627 7.67188 8.77522 7.67188Z"
      fill="currentcolor"
    />
  </svg>
);
export default LinkIcon;
