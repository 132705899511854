import { IS_MENU_OPEN } from "../type";

const initState = {
  isOpen: false
};

function FloatingMenu(state = initState, action) {
  switch (action.type) {
    case IS_MENU_OPEN:
      return {
        ...state,
        isOpen: action.payload
      };
    default:
      return state;
  }
}

export default FloatingMenu;