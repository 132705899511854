import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import VideoModal from "./components/Video.modal";
import PlusBody from "./PlusBody";
import WorldBody from "./WorldBody";
import HatChefBodyMobile from "./HatChefBodyMobile";
import InfoBodyMobile from "./InfoBodyMobile";
import SpecsBodyMobile from "./SpecsBodyMobile";
import AttachmentBody from "./AttachmentBody";
import CarouselBodyMobile from "./CarouselBodyMobile";
import { serverResponse } from "../../../util/fakeServer";
import { connect } from "react-redux";

const ContentModalMobile = ({ setActiveTab, modalRedux }) => {
  const {
    contentModal: info,
  } = modalRedux;
  const markers = useSelector((state) => state.modal.otherProps.info.markers);
  const [tags, setTags] = useState(
    serverResponse?.leaflet && serverResponse?.leaflet?.tags
      ? Object.values(serverResponse.leaflet.tags)
      : []
  );

  const dataByType = React.useMemo(
    () =>
      markers.reduce((acc, marker) => {
        acc[marker.type] = marker;
        return acc;
      }, {}),
    [markers]
  );

  const plusRef = useRef(null);
  const worldRef = useRef(null);
  const playRef = useRef(null);
  const hatChefRef = useRef(null);
  const infoRef = useRef(null);
  const specsRef = useRef(null);
  const attachmentRef = useRef(null);
  const carouselRef = useRef(null);

  const sectionRefs = useMemo(
    () => ({
      plus: plusRef,
      world: worldRef,
      play: playRef,
      "hat-chef": hatChefRef,
      info: infoRef,
      specs: specsRef,
      attachment: attachmentRef,
      carousel: carouselRef,
    }),
    []
  );

  useEffect(() => {
    const handleScroll = () => {
      let activeSection = null;
      Object.entries(sectionRefs).forEach(([key, ref]) => {
        if (ref.current) {
          const { top, bottom } = ref.current.getBoundingClientRect();
          if (
            top < window.innerHeight * 0.5 &&
            bottom > window.innerHeight * 0.5
          ) {
            activeSection = key;
          }
        }
      });
      if (activeSection) {
        setActiveTab(activeSection);
      }
    };

    window.addEventListener("scroll", handleScroll, true);
    return () => window.removeEventListener("scroll", handleScroll, true);
  }, [setActiveTab, sectionRefs]);

  return (
    <div
      ref={sectionRefs.plus}
      className={`complete-modal-body relative ${
        !info?.tags && !info?.tags?.length > 0 && "no-space"
      }`}
    >
      {dataByType.plus && (
        <section id="plus">
          <PlusBody />
        </section>
      )}
      {dataByType.world && (
        <section id="world" ref={sectionRefs.world}>
          <WorldBody
            data={dataByType.world.data}
            title={dataByType.world.title}
          />
        </section>
      )}
      {dataByType.play && (
        <section id="play" ref={sectionRefs.play}>
          <VideoModal
            data={dataByType.play.data}
            title={dataByType.play.title}
          />
        </section>
      )}
      {dataByType["hat-chef"] && (
        <section id="hat-chef" ref={sectionRefs["hat-chef"]}>
          <HatChefBodyMobile
            data={dataByType["hat-chef"].data}
            title={dataByType["hat-chef"].title}
          />
        </section>
      )}
      {dataByType.info?.data && (
        <section id="info" ref={sectionRefs.info}>
          <InfoBodyMobile data={dataByType.info.data} />
        </section>
      )}
      {dataByType.specs && (
        <section id="specs" ref={sectionRefs.specs}>
          <SpecsBodyMobile
            data={dataByType.specs.data}
            title={dataByType.specs.title}
          />
        </section>
      )}
      {dataByType.attachment && (
        <section id="attachment" ref={sectionRefs.attachment}>
          <AttachmentBody
            data={dataByType.attachment.data}
            title={dataByType.attachment.title}
          />
        </section>
      )}
      {dataByType.carousel?.data && (
        <section id="carousel" ref={sectionRefs.carousel}>
          <CarouselBodyMobile data={dataByType.carousel.data} />
        </section>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    modalRedux: state.modal,
  };
};

export default connect(mapStateToProps)(ContentModalMobile);
