import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { styles } from "./customPrice.styles";
import { isMobileOnly, isTablet } from "react-device-detect";
import { serverResponse } from "../../util/fakeServer";

const CustomPrice = (props) => {

  const ref = useRef(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setHeight(ref.current ? ref.current.offsetHeight : 0);
  }, []);
  
  if (
    props.priceReducer.isLoading &&
    props.carouselIndex.labelIndex
      .toString()
      .includes(props.pageNumber.toString())
  ) {
    return (
      <div style={styles.container}>
        <div className="spinner-border text-success" role="status" />
      </div>
    );
  }

  const temp_font = isMobileOnly
    ? props.fontSizeServer.mobile
    : isTablet
    ? props.fontSizeServer.tablet
    : props.fontSizeServer.desktop;

  const price = props.priceReducer.productPrices[props.item.skul];

  if (price === undefined) {
    return <></>;
  }

  const containerStyles = {
    color: serverResponse.config.price_text_color,
    fontSize: `${temp_font > height && height > 0 ? height : temp_font}px`,
    height: "100%",
    position: "absolute",
    // background: "white",
    bottom: 0,
    right: 0,
    width: `100%`,
    // width: `50%`,
    // fontSize: "10px",
    fontWeight: "bold",
    textAlign: "right",
    paddingRight: "3px",
    // height: "25%",
    // border: "1px solid black"
    whiteSpace: "nowrap",
    display: "flex",
    justifyContent: props.dynamic_price_position,
    alignItems: "end"
  };

  return (
    <div
      className="custom_price"
      id={"custom_price_" + props.skul}
      onClick={props.onClick}
      style={containerStyles}
      ref={ref}
    >
      <p>
        €{" "}
        {parseFloat(price)
          .toFixed(2)
          .toString()
          .replace(".", ",")}
      </p>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    priceReducer: state.priceReducer,
    carouselIndex: state.carousel
  };
};

export default connect(mapStateToProps)(CustomPrice);
