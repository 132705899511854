import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { useNavigate, useLocation } from "react-router-dom";

import { serverResponse } from "../../util/fakeServer";
import { setIndexCarousel, isFromInput } from "../../redux/carousel/action";
import { toggleJWTModal } from "../../redux/authJWT/action";
import {
  // defaultLanguage,
  getAnalyticsDevice,
  getAnalyticsUniqueId,
  sendVisit,
} from "../../helper/utility";
import { visit } from "../../services/analytics.services";
import {
  isMobileOnly,
  isTablet,
  isIPad13,
  isBrowser as isDesktop,
} from "react-device-detect";
import ManagerFactory from "../../managers/ManagerFactory";
import "./header.css";
import { CartIcon } from "../../assets/icons";
import GroceryList from "../floatingGroceryList/GroceryList";
import { interattivoIcon } from "../../helper/constant";
import InPublishIcona from "../../assets/images/icone/InPublish.icona";
import userImage from "../../assets/images/user-img.png";
import UserAvatar from "../UserAvatar/UserAvatar";

const tabRoutes = ["/leaflet", "/categories"];

const Header = (props) => {
  const manager = ManagerFactory.getManager();

  const navigate = useNavigate();
  const location = useLocation();

  const isCategoriesPage = location.pathname === "/categories";

  const [activeTab, setActiveTab] = useState(
    tabRoutes.includes(location.pathname) ? location.pathname : null
  );

  const [popoverOpen, setPopoverOpen] = useState(false);
  const toggle = () => {
    setPopoverOpen(!popoverOpen);
    // if (popoverOpen) {
    //   ReactGA.pageview(
    //     `/${serverResponse.config.client_id}-${serverResponse.leaflet.id}/lista-spesa`
    //   );
    // }
  };

  useEffect(() => {
    setActiveTab(
      tabRoutes.includes(location.pathname) ? location.pathname : null
    );
  }, [location.pathname]);

  const handleTabClick = (path) => {
    setActiveTab(path);
    navigate(path);
  };

  useEffect(() => {
    if (
      serverResponse.config.ga_active &&
      sendVisit() &&
      manager.sendAnalitics()
    ) {
      visit({
        id_cliente: serverResponse.config.client_id,
        id_insegna: serverResponse.config.signboard_id,
        id_utente: serverResponse.config.release_id,
        id_volantino: serverResponse.leaflet.id,
        device: getAnalyticsDevice(
          isMobileOnly,
          isTablet || isIPad13,
          isDesktop && !isIPad13
        ),
        unique_id: getAnalyticsUniqueId(),
      });
    }
  }, [manager]);

  // const handleOnClick = useCallback(
  //   (value) => navigate({ pathname: value, from: true }),
  //   [navigate]
  // );

  // const [popoverOpen, setPopoverOpen] = useState(false);
  // const toggle = () => {
  //   setPopoverOpen(!popoverOpen);
  //   if (popoverOpen) {
  //     ReactGA.pageview(
  //       `/${serverResponse.config.client_id}-${serverResponse.leaflet.id}/lista-spesa`
  //     );
  //   }
  // };

  const appLogo = manager.getAppLogo();
  // const appLogoSize = manager.getAppLogoSize();
  // const headerLink = manager.getLogoHeaderLink();
  // const logoMaxSize = manager.getLogoClientMaxSize();
  return (
    <div
      className={`mobile-header d-block d-sm-none ${
        isCategoriesPage && "shadowNone"
      }`}
    >
      <div className="mobile-top-bar">
        <div className="left-content">
          {/* {serverResponse.config.allow_login && ( */}
          <div className="icon">
            {serverResponse.config.type === "inpublish" ? (
              <InPublishIcona />
            ) : (
              <img
                className="img-fluid"
                src={interattivoIcon}
                style={{ width: "150px", alignSelf: "center" }}
                alt="logo interattivo"
              />
            )}
          </div>
          {/* )} */}
        </div>

        <div className="middle-content">
          <div className="logo">
            <img
              // style={{ width: "90px" }}
              src={serverResponse.config.logo_full}
              alt=""
            />
          </div>
        </div>
        <div className="right-content">
          {props.authJWT.user ? (
            <div className="d-flex user-profile-mobile">
              {/* <img src={userImage} alt="" /> */}
              <span className="user-name">
                <UserAvatar />
              </span>
            </div>
          ) : serverResponse.config.allow_login ? (
            <p
              style={{
                color: "var(--primary-color)",
                fontSize: "14px",
                fontWeight: "bold",
                textAlign: "center",
                cursor: "pointer",
              }}
              onClick={() => navigate("/login")}
              className="btn-login"
            >
              Accedi
            </p>
          ) : (
            <div className="cart-icon text-right" onClick={toggle}>
              <CartIcon id="mobile-grocery" />
              {props.groceryList.length > 0 && (
                <span className="notificatopn-badge">
                  {props.groceryList.length}
                </span>
              )}
              {!serverResponse.config.hide_grocery_list && (
                <GroceryList
                  isOpen={popoverOpen}
                  toggle={toggle}
                  placement={"bottom"}
                  target="mobile-grocery"
                  isMobile={true}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <div className="mobile-header-bottom-content">
        <ul className="filter-wrap">
          <li
            className={activeTab === "/leaflet" ? "active" : ""}
            onClick={() => handleTabClick("/leaflet")}
          >
            sfoglia
          </li>
          <li
            className={activeTab === "/categories" ? "active" : ""}
            onClick={() => handleTabClick("/categories")}
          >
            prodotti
          </li>
        </ul>
      </div>
    </div>
    // <HeaderWrapper {...props}>
    // <div>
    //   <div className="container-fluid" style={{ height: H_COMPONENTS.header }}>
    //     <div className="row h-100">
    //       <div className="col-12 d-flex align-items-center justify-content-between p-md-1 p-2 d-lg-none h-100">
    //         <>
    //           {serverResponse.config.logo_full && (
    //             <img
    //               className="h-100"
    //               id="logo_client"
    //               src={serverResponse.config.logo_full}
    //               alt="react-logo"
    //               style={{ maxWidth: "150px", objectFit: "contain" }}
    //             />
    //           )}
    //           <a
    //             href={headerLink}
    //             target="_blank"
    //             rel="noopener noreferrer"
    //             style={styles.link_decoration}
    //           >
    //             {appLogo !== '' && <img
    //               className="h-100"
    //               id="logo_header"
    //               src={appLogo}
    //               alt="react-logo"
    //               style={styles.logo_header}
    //             />}
    //             <p style={styles.p_style}>v{process.env.REACT_APP_VERSION}</p>
    //           </a>
    //         </>

    //         {serverResponse.config.no_link_to_site && (
    //           <img src={iconAppNameNegative} alt="react-logo" />
    //         )}
    //       </div>
    //       <div className="col-6 d-lg-none d-flex justify-content-center align-items-center"></div>

    //       <div className="col-12 d-none d-lg-flex justify-content-center">
    //         <div className="container-fluid p-0 custom_header_container_fluid">
    //           <div className="row h-100">
    //             <div className="col-lg-4 d-flex align-items-center">
    //               <img src={appLogo} style={{ width: appLogoSize }} alt="" />
    //             </div>
    //             <div className="col-lg-8 d-flex justify-content-end align-items-center">
    //               {serverResponse.config.allow_login && (
    //                 <>
    //                   {props.authJWT.loadingProfile ? (
    //                     <div
    //                       className="spinner-grow"
    //                       style={styles.spinner_grow}
    //                       role="status"
    //                     />
    //                   ) : props.authJWT.isAuthenticated ? (
    //                     <UserAvatar />
    //                   ) : (
    //                     <p
    //                       onClick={() => {
    //                         //props.toggleJWTModal();
    //                         navigate("/login");
    //                       }}
    //                       className="login_button"
    //                     >
    //                       {language[defaultLanguage].login}
    //                     </p>
    //                   )}
    //                 </>
    //               )}
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   {!serverResponse.config.hide_grocery_list && (
    //     <GroceryList
    //       isOpen={popoverOpen}
    //       toggle={toggle}
    //       placement={"bottom"}
    //       target="mobile-grocery"
    //       isMobile={true}
    //     />
    //   )}
    // </HeaderWrapper>
    // </div>
  );
};
const mapStateToProps = (state) => {
  return {
    groceryList: state.groceryList.list,
    carouselIndex: state.carousel,
    authJWT: state.authJWT,
  };
};
export default compose(
  connect(mapStateToProps, {
    setIndexCarousel,
    isFromInput,
    toggleJWTModal,
  })
)(Header);
