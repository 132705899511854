import * as React from "react";
const FileIcon = (props) => (
  <svg
    width="14"
    height="18"
    viewBox="0 0 14 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.85632 5.71563H7.64695V4.50625C7.64695 4.16875 7.3657 3.85938 7.00007 3.85938C6.66257 3.85938 6.3532 4.14062 6.3532 4.50625V5.71563H5.14382C4.80632 5.71563 4.49695 5.99687 4.49695 6.3625C4.49695 6.72812 4.7782 7.00938 5.14382 7.00938H6.3532V8.21875C6.3532 8.55625 6.63445 8.86562 7.00007 8.86562C7.33757 8.86562 7.64695 8.58437 7.64695 8.21875V7.00938H8.85632C9.19382 7.00938 9.5032 6.72812 9.5032 6.3625C9.5032 5.99687 9.19382 5.71563 8.85632 5.71563Z"
      fill="currentcolor"
    />
    <path
      d="M12.0625 0.0062561H1.93755C0.981299 0.0062561 0.221924 0.765631 0.221924 1.72188V10.75C0.221924 11.1719 0.390674 11.5656 0.700049 11.8469L5.76255 16.6281C6.0438 16.9094 6.40942 17.05 6.80317 17.05H12.0625C13.0188 17.05 13.7782 16.2906 13.7782 15.3344V1.72188C13.7782 0.765631 13.0188 0.0062561 12.0625 0.0062561ZM2.41567 11.7063H5.7063C5.84692 11.7063 5.95942 11.8188 5.95942 11.9313V15.025L2.41567 11.7063ZM12.5125 15.2781C12.5125 15.5313 12.3157 15.7281 12.0625 15.7281H7.22505V11.9313C7.22505 11.1156 6.55005 10.4406 5.7063 10.4406H1.48755V1.72188C1.48755 1.46876 1.68442 1.27188 1.93755 1.27188H12.0625C12.3157 1.27188 12.5125 1.46876 12.5125 1.72188V15.2781Z"
      fill="currentcolor"
    />
  </svg>
);
export default FileIcon;
