import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const WorldBody = ({ data, title }) => {

  return (
    <div>
      <h6>{title}</h6>
      <Link to={data}>link esterno</Link>
    </div>
  );
};

const mapStateToProps = (state) => {
  
  return {
    modalRedux: state.modal,
  };
};

export default connect(mapStateToProps)(WorldBody);
