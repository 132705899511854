import React, { useState } from "react";
import InputPage from "../common/InputPage";
import useCurrentWidth from "../../hooks/widthHook";
import { serverResponse } from "../../util/fakeServer";

import UserAvatar from "../UserAvatar/UserAvatar";
import { styles } from "./footer.styles";
import { Carrello, ListaSpesa } from "../../assets/images/icone";
import { connect } from "react-redux";
import GroceryList from "../floatingGroceryList/GroceryList";
import { BREAKPOINT } from "../../helper/constant";

const FooterMobile = (props) => {
  const currentWidth = useCurrentWidth();

  const classFooter =
    currentWidth >= BREAKPOINT.xs ? "d-none" : "container-fluid shadow";
  const [popoverOpen, setPopoverOpen] = useState(false);
  const toggle = () => {
    setPopoverOpen(!popoverOpen);
    // if (popoverOpen) {
    //   ReactGA.pageview(
    //     `/${serverResponse.config.client_id}-${serverResponse.leaflet.id}/lista-spesa`
    //   );
    // }
  };

  return (
    <div
      className={classFooter}
      style={{
        position: "fixed",
        bottom: "0",
        width: "100%",
        background: "white",
        height: "55px",
      }}
    >
      <div className="row shadow h-100">
        <div className="col-4 offset-4 p-0 d-flex align-items-center">
          {currentWidth <= BREAKPOINT.xs && <InputPage />}
        </div>
        <div className="col-4 pr-4 d-flex align-items-center justify-content-end d-lg-none h-100 p-2">
          {!!serverResponse.config.allow_login && (
            <div className="col-lg-1 d-flex align-items-center justify-content-end">
              {props.authJWT.loadingProfile ? (
                <div
                  className="spinner-grow"
                  style={styles.spinner_grow}
                  role="status"
                />
              ) : props.authJWT.isAuthenticated ? (
                <UserAvatar />
              ) : (
                <></>
              )}
            </div>
          )}

          <button
            style={{
              position: "relative",
            }}
            className="top-header-icon btn p-0"
            id="mobile-grocery"
            onClick={toggle}
          >
            {serverResponse.config.send_grocery_list_to_market &&
              !serverResponse.config.hide_grocery_list && (
                <div
                  id="custom_grocery_button"
                  style={{
                    borderLeft: "1px solid #0008",
                    borderRadius: "0",
                  }}
                  className="btn p-0 m-0"
                >
                  <Carrello />
                </div>
              )}
            {!serverResponse.config.send_grocery_list_to_market &&
              !serverResponse.config.hide_grocery_list && (
                <div
                  id="custom_grocery_button"
                  style={{
                    borderLeft: "1px solid #0008",
                    borderRadius: "0",
                  }}
                  className="btn p-0 m-0"
                >
                  <ListaSpesa />
                </div>
              )}
            {props.groceryList.length > 0 && (
              <span
                style={styles.mobile_grocery_notification}
                className="custom-mobile-grocery-notification-badge"
              >
                {props.groceryList.length}
              </span>
            )}
          </button>
        </div>
      </div>
      {!serverResponse.config.hide_grocery_list && (
        <GroceryList
          isOpen={popoverOpen}
          toggle={toggle}
          placement={"bottom"}
          target="mobile-grocery"
          isMobile={true}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    groceryList: state.groceryList.list,
    carouselIndex: state.carousel,
    authJWT: state.authJWT,
  };
};

export default connect(mapStateToProps)(FooterMobile);
