import * as React from "react";
const Telefono = (props) => (
  <svg
    height="18"
    width="19"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M24.3 30.45C20.25 30.45 14.5 27.55 9.20001 22.55C2.00001 15.65 -1.04999 7.94997 2.15001 4.54997C2.30001 4.39997 2.50001 4.24997 2.70001 4.14997L6.75001 1.84997C7.80001 1.24997 9.10001 1.54997 9.80001 2.54997L12.75 6.74997C13.1 7.24997 13.25 7.84997 13.1 8.44997C13 8.99997 12.65 9.49997 12.15 9.84997L10.4 11C11.75 12.95 15.4 17.8 21.2 21.25C21.25 21.3 21.3 21.25 21.3 21.25L22.55 19.55C23.25 18.6 24.6 18.35 25.65 19L30.05 21.8C31.1 22.45 31.4 23.8 30.75 24.85L28.35 28.7C28.2 28.9 28.05 29.1 27.9 29.25C27 30.05 25.75 30.45 24.3 30.45ZM7.90001 3.79997L3.80001 6.09997C1.90001 8.14997 4.25001 14.7 10.8 20.9C17.45 27.2 24.3 29.45 26.5 27.55L28.9 23.7L24.5 20.9C24.45 20.9 24.4 20.9 24.4 20.9L23.15 22.6C22.45 23.55 21.1 23.8 20.1 23.2C13.85 19.45 9.95001 14.25 8.55001 12.2C8.20001 11.7 8.10001 11.1 8.20001 10.55C8.30001 9.94997 8.65001 9.44997 9.15001 9.14997L10.9 7.99997L8.00001 3.84997C7.95001 3.84997 7.90001 3.79997 7.90001 3.79997Z"
      fill="white"
    />
  </svg>
);
export default Telefono;
