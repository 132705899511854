import React, { Component } from "react";

import { connect } from "react-redux";
import { serverResponse } from "../../util/fakeServer";
import { styles } from "./ShareGroceryListBtn.style";

import { Volantino } from "../../assets/images/icone/index.js";

class CloseCart extends Component {
  render() {
    const shareButtonStyle =
      this.props.groceryList.list.length === 0
        ? {
            color: "white",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "space-between",
            background: "grey",
            width: "170px"
          }
        : {
            color: "white",
            // padding: "10px",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "space-between",
            cursor: "pointer",
            width: "170px"
          };

    return (
      <div
        style={shareButtonStyle}
        onClick={() => {
          this.props.toggle();
        }}
      >
        <div
          style={styles.shareBtn(serverResponse.config.secondary_color)}
          className="shareButton d-flex align-items-center justify-content-center w-100"
        >
          <div
            style={{
              height: "35px",
              width: "35px",
              filter: "invert(100%) brightness(100%) saturate(0%)"
            }}
          >
            <Volantino />
          </div>
          {/* <CondividiNew style={{ width: "35px" }} /> */}
          <span style={{ fontSize: "small" }}>Torna al catalogo</span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    groceryList: state.groceryList,
    auth: state.authJWT
  };
};

export default connect(mapStateToProps, null)(CloseCart);
