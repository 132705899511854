import * as React from "react";
const HomeIcon = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.18567 1.18787C9.65407 0.785644 10.346 0.785644 10.8144 1.18787L18.8079 8.05211C19.685 8.8053 19.1569 10.2419 18.0046 10.2504C17.6043 10.2534 17.25 10.5758 17.25 11.0104V18C17.25 18.6903 16.6903 19.25 16 19.25H12.5C12.3619 19.25 12.25 19.1381 12.25 19V16C12.25 15.0335 11.4665 14.25 10.5 14.25H9.49999C8.5335 14.25 7.74999 15.0335 7.74999 16V19C7.74999 19.1381 7.63806 19.25 7.49999 19.25H3.99999C3.30964 19.25 2.74999 18.6903 2.74999 18V11.0104C2.74999 10.5759 2.39582 10.2534 1.99545 10.2504C0.843161 10.2418 0.314999 8.80529 1.1921 8.05211L9.18567 1.18787Z"
      stroke="currentcolor"
      strokeWidth="1.5"
    />
  </svg>
);
export default HomeIcon;
