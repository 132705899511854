import auth from "./auth/reducer";
// import themeChanger from "./themeChanger/reducer";
// import LanguageSwitcher from "./languageSwitcher/reducer";
// import themeSetting from "./themeSettings/reducer";
// import customTheme from "./customTheme/reducer";
import groceryList from "./groceryList/reducer";
import carousel from "./carousel/reducer";
import floatingMenu from "./floatingMenu/reducer";
import cookie from "./cookie/reducer";
import managerVideo from "./videoManager/reducer";
import fakeServer from "./fakeserver/reducer";
import modal from "./modal/reducer";
import authJWT from "./authJWT/reducer";
import priceReducer from "./price/price.reducer";

const reducers = {
  auth,
  // themeChanger,
  // LanguageSwitcher,
  // themeSetting,
  // customTheme,
  groceryList,
  carousel,
  cookie,
  floatingMenu,
  managerVideo,
  fakeServer,
  priceReducer,
  modal,
  authJWT
};

export default reducers;