import React from "react";
import AdComponent from "../components/leaflet/Adsense";
import CookieConsentBanner from "../components/leaflet/CookieConsentBanner";
import { serverResponse } from "../util/fakeServer";
import PrivacyPolicy from "../components/common/PrivacyPolicy";
import FormComponent from "../views/Form.component";

import ShareGroceryListBtn from "../components/share/ShareGroceryListBtn";
// import CategoriesStatic from "../views/CategoriesStatic";
// import CategoriesStatic from "../components/Categories/CategoriesStatic";

const typeFlyer = {
  leaflet: "logo_interattivo.svg",
  menu: "logo_menu_interattivo.svg",
  catalog: "logo_catalog_interattivo.svg",
  inpublish: "logo_interattivo.svg"
};

// const iconDemoType = {
//   leaflet: "logo_new.png",
//   menu: "logo_new.png",
//   catalog: "logo_new.png"
// };

// const iconLogoNegativeType = {
//   leaflet: "logo_new.png",
//   menu: "logo_new.png",
//   catalog: "logo_new.png"
// };

const interattivoTypeLogo = typeFlyer[serverResponse.config.type];
// const iconDemoTypeLogo = iconDemoType[serverResponse.config.type];
// const typeLogoNegative = iconLogoNegativeType[serverResponse.config.type];

export const interattivoLogo = require(`../assets/images/${interattivoTypeLogo}`);

class DefaultManager {
  signboardId;

  constructor(signboardId) {
    this.signboardId = signboardId;
  }

  getPlusIcon() {
    return null;
  }

  getPlayIcon() {
    return null;
  }

  getInfoIcon() {
    return null;
  }

  noAddCart() {
    return false;
  }

  getTitleType() {
    if (global.footer_session) return global.footer_session;

    return {};
  }

  getMarkerIconClass() {
    return "";
  }

  getConsentBanner() {
    return <CookieConsentBanner />;
  }

  getVideoProps() {
    return {
      muted: true,
      controls: false
    };
  }

  getComponentCategory() {
    return "CategoriesStatic";
  }

  getHAdsense() {
    return 90;
  }

  getBannerComponent() {
    return <AdComponent />;
  }

  filterMarkers() {
    return null;
  }

  getStoreButton(link) {
    return null;
  }

  getRowClass(type) {
    return "";
  }

  getAnalyticsId() {
    return serverResponse.config.ga_tracking_id;
  }

  gaAnalyticsEvent(event, item) {
    return false;
  }

  getVisibleInteractivities() {
    return [
      "attachment",
      "plus",
      "carousel",
      "world",
      "play",
      "info",
      "hat-chef",
      "specs",
      "promo",
      "external_link",
      "internal_link"
    ];
  }

  applyFilterOnMarker() {
    return false;
  }

  isSearchProductVisible() {
    return true;
  }

  getCategoriesPlaceholder() {
    return {
      category: "Scegli una categoria",
      subcategory: "Scegli una sottocategoria"
    };
  }

  getDefaultFont() {
    // return "M PLUS Rounded 1c";
    return "Inter";
  }

  getIvaLabel() {
    return null;
  }

  linkToShop() {
    return false;
  }

  getShopUrl(markers) {
    return null;
  }

  getProdottiLabel(label) {
    return label;
  }

  getPrivacyText() {
    return <PrivacyPolicy />;
  }

  forceVisit() {
    return true;
  }

  getBaseWidth() {
    // return "3.5rem";
    return "0";
  }
  showList() {
    return true;
  }
  getLogoHeaderLink() {
    let signboards = [2687];

    return signboards.includes(this.signboardId)
      ? null
      : process.env.REACT_APP_VOLANTINO_INTERATTIVO;
  }

  getAppLogo() {
    const noLogo = [2295, 4364];
    if (noLogo.includes(this.signboardId)) return "";
    return interattivoLogo;
  }

  getAppLogoSize() {
    return "150px";
  }

  showButtonFloatingBar() {
    return false;
  }

  getSignupForm() {
    return <FormComponent />;
  }

  sendAnalitics() {
    return true;
  }

  buttonCloseGrocery() {
    return true;
  }

  leftButtonGroceryComponent() {
    return <ShareGroceryListBtn />;
  }

  actionCompleteOrder(fc) {
    fc();
  }

  closeModalAfterAddProd() {
    return false;
  }

  actionOnlyWA() {
    return false;
  }

  visibilityConditionShareGrocery(condition) {
    return condition;
  }

  labelCompleteOrder() {
    return "completa_ordine";
  }

  closeButtonIcon() {
    return true;
  }

  buttonLoginInHeader() {
    return false;
  }
  getLogoClientMaxSize() {
    return "150px";
  }
}

export default DefaultManager;
