import React from "react";

const ListModal = ({ list }) => {
  const specifiche = list?.split(", ");
  const listSpecifiche = specifiche?.map((element, key) => (
    <li className="mb-2" key={key}>
      {element}
    </li>
  ));
  return <>{listSpecifiche}</>;
};

export default ListModal;
