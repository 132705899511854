import * as React from "react";
const RecipeIcon = (props) => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1_924)">
      <path
        d="M15.4621 1.00603C15.1246 1.00603 14.8433 1.28728 14.8433 1.62478V6.09665C14.8433 6.85602 14.2808 7.47477 13.5214 7.6154V1.62478C13.5214 1.28728 13.2402 0.977905 12.8746 0.977905C12.5089 0.977905 12.2277 1.25916 12.2277 1.62478V7.64352C11.4683 7.5029 10.9058 6.85602 10.9058 6.09665V1.62478C10.9058 1.28728 10.6246 0.977905 10.2589 0.977905C9.89331 0.977905 9.61206 1.25916 9.61206 1.62478V6.09665C9.61206 7.55915 10.7371 8.7404 12.1996 8.90915V17.3748C12.1996 17.7123 12.4808 18.0217 12.8464 18.0217C13.2121 18.0217 13.4933 17.7404 13.4933 17.3748V8.90915C14.9558 8.76852 16.0808 7.55915 16.0808 6.09665V1.62478C16.1089 1.28728 15.7996 1.00603 15.4621 1.00603Z"
        fill="currentcolor"
      />
      <path
        d="M6.54688 1.0625H5.39375C4.325 1.0625 3.45312 1.93438 3.425 2.975L2.89062 11.2156C2.89062 11.3844 2.94688 11.5531 3.05938 11.6937C3.17188 11.8344 3.34062 11.8906 3.50937 11.8906H5.87188V17.375C5.87188 17.7125 6.15312 18.0219 6.51875 18.0219C6.88437 18.0219 7.16563 17.7406 7.16563 17.375V1.70938C7.16563 1.37188 6.88438 1.0625 6.54688 1.0625ZM4.2125 10.625L4.69062 3.05938V3.03125C4.69062 2.66562 5 2.35625 5.39375 2.35625H5.9V8.0375V10.625H4.2125Z"
        fill="currentcolor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_924">
        <rect
          width="18"
          height="18"
          fill="white"
          transform="translate(0.5 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default RecipeIcon;
