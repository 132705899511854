import { serverResponse } from "../../util/fakeServer";

const getProducts = () => {
  let products = {};
  let pages = serverResponse.leaflet.pages;
  for (let index = 0; index < pages.length; index++) {
    for (let j = 0; j < pages[index].interactivities.length; j++) {
      products[pages[index].interactivities[j].item_id] =
        pages[index].interactivities[j];
    }
  }
  return products;
};

const initState = {
  config: serverResponse.config,
  products: getProducts()
};

function FakeServer(state = initState, action) {
  switch (action.type) {
    default:
      return state;
  }
}

export default FakeServer;