import * as React from "react";
const SearchIcon = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23.025 20.8875L16.8375 15.8625C19.3875 12.375 19.125 7.3875 15.9375 4.2375C14.25 2.55 12 1.6125 9.60001 1.6125C7.20001 1.6125 4.95001 2.55 3.26251 4.2375C-0.224994 7.725 -0.224994 13.425 3.26251 16.9125C4.95001 18.6 7.20001 19.5375 9.60001 19.5375C11.8875 19.5375 14.025 18.675 15.7125 17.1375L21.975 22.2C22.125 22.3125 22.3125 22.3875 22.5 22.3875C22.7625 22.3875 22.9875 22.275 23.1375 22.0875C23.4375 21.7125 23.4 21.1875 23.025 20.8875ZM9.60001 17.85C7.65001 17.85 5.85001 17.1 4.46251 15.7125C1.61251 12.8625 1.61251 8.25 4.46251 5.4375C5.85001 4.05 7.65001 3.3 9.60001 3.3C11.55 3.3 13.35 4.05 14.7375 5.4375C17.5875 8.2875 17.5875 12.9 14.7375 15.7125C13.3875 17.1 11.55 17.85 9.60001 17.85Z"
      fill="currentcolor"
    />
  </svg>
);
export default SearchIcon;
