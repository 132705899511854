import * as React from "react";
const LeftArrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    {...props}
  >
    <path
      fill={props.fill}
      d="m2 10.5 6.469 6.563a.698.698 0 0 0 1 0 .698.698 0 0 0 0-1l-5.25-5.344H17.5a.694.694 0 0 0 .688-.688c0-.375-.313-.719-.688-.719H4.156L9.47 3.907a.698.698 0 0 0 0-1 .71.71 0 0 0-.5-.187.622.622 0 0 0-.5.219L2 9.5a.698.698 0 0 0 0 1Z"
    />
  </svg>
);
export default LeftArrow;
