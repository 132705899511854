import * as React from "react"

const CsvInpublish = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill={props.color}
      d="M17.657 5.188 13.344.938a1.323 1.323 0 0 0-.938-.375h-7.5A1.98 1.98 0 0 0 2.939 2.53v15a1.98 1.98 0 0 0 1.969 1.97h11.156a1.98 1.98 0 0 0 1.969-1.97V6.126c0-.343-.125-.687-.375-.937Zm-4.282-2.25L15.72 5.25h-2.344V2.938Zm2.719 15.125H4.906a.56.56 0 0 1-.562-.563v-15a.56.56 0 0 1 .563-.562h7.062v4c0 .375.313.719.719.719h3.969V17.5c-.032.313-.282.563-.563.563Z"
    />
    <path
      fill={props.color}
      d="M11.5 8.25a.698.698 0 0 0-1 0 .698.698 0 0 0 0 1l1.875 1.907H6.97a.727.727 0 0 0-.719.719c0 .406.313.718.719.718h5.406L10.5 14.501a.698.698 0 0 0 0 1 .71.71 0 0 0 .5.187.622.622 0 0 0 .5-.219l3.063-3.093a.698.698 0 0 0 0-1L11.5 8.25Z"
    />
  </svg>
)
export default CsvInpublish
