import React, { useRef, useEffect, useCallback, useState } from "react";
import { connect } from "react-redux";
import {
  leafLetIcon,
  columnIcon,
  searchIcon,
  pdfIcon,
  shareIcon,
  indexIcon,
  BREAKPOINT,
} from "../../helper/constant";
import { setStatusOfMenu } from "../../redux/floatingMenu/action";
import { serverResponse } from "../../util/fakeServer";
import { useLocation, useNavigate } from "react-router-dom";
import { setIndexCarousel } from "../../redux/carousel/action";
import ShareModal from "../common/ShareModal";
import { defaultLanguage, scrollToSlide } from "../../helper/utility";
import Fab from "./patch"; // , { AB }
import "./fab.css";
import useCurrentWidth from "../../hooks/widthHook";
import * as Icone from "../../assets/images/icone/index.js";
import { language } from "../../languageProvider/language/language";
import ManagerFactory from "../../managers/ManagerFactory";
import { logoutJWT } from "../../redux/authJWT/action.js";
import HomeIcon from "../../assets/icons/home.icon.js";
import PagineIcon from "../../assets/icons/pagine.icon.js";
// import MenuIcon from "../../assets/icons/menu.icon.js";
// import StoreIcon from "../../assets/icons/store.icon.js";
import ShareIcon from "../../assets/icons/share.icon.js";
import ProdottiIcon from "../../assets/icons/prodotti.icon.js";
import VolantinoIcon from "../../assets/icons/volantino.icon.js";
import PdfIcon from "../../assets/icons/pdf.icon.js";
import CartIcon from "../../assets/icons/cart-icon.js";
import GroceryList from "../floatingGroceryList/GroceryList.jsx";
const WAIT_BEFORE_SHOW = 100;

const FloatingMenu = (props) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const ref = useRef(null);
  // const history = useHistory();
  const navigate = useNavigate();
  const [isShown, setIsShown] = useState(false);
  const width = useCurrentWidth();

  const arrayToFilter = serverResponse.config.sidebar;

  const [openShareModal, setOpenShareModal] = useState(false);
  const {
    authJWT: { user },
  } = props;

  const { pathname } = useLocation();

  const {
    config: { primary_color, secondary_color },
  } = serverResponse;

  const manager = ManagerFactory.getManager();

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShown(true);
    }, WAIT_BEFORE_SHOW);
    return () => {
      clearTimeout(timer);
      setIsShown(false);
    };
  }, [pathname]);

  const handleOnClick = useCallback(
    (value) => navigate({ pathname: value, from: true }),
    [navigate]
  );
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      props.setStatusOfMenu(false);
    }
  };

  const handleOnPressLogout = () => {
    const confirmLogout = window.confirm("Vuoi effettuare il logout?");
    if (confirmLogout) {
      props.logoutJWT(navigate);
    }
  };

  const toggle = () => {
    setPopoverOpen(!popoverOpen);
  };

  const readerType = language[defaultLanguage].sfoglia;
  // serverResponse.config.type === "leaflet"
  //   ? manager.getProdottiLabel(language[defaultLanguage].sfoglia)
  //   : serverResponse.config.type === "menu"
  //   ? language[defaultLanguage].sfoglia
  //   : language[defaultLanguage].sfoglia;

  const arrayEl = [
    {
      id: "3",
      key: "pages",
      text: language[defaultLanguage].pagine,
      backgroundColor: pathname.includes("pages")
        ? secondary_color
        : primary_color,
      imgSrc: columnIcon,
      svg: <PagineIcon />,
      onClick: () => handleOnClick("/pages"),
    },
    {
      id: "4",
      key: "pdf",
      text: language[defaultLanguage].pdf,
      backgroundColor: primary_color,
      imgSrc: pdfIcon,
      svg: <PdfIcon />,
      onClick: () => window.open(serverResponse.config.pdfPath),
    },
    {
      id: "5",
      key: "share",
      text: language[defaultLanguage].condividi,
      backgroundColor: primary_color,
      imgSrc: shareIcon,
      svg: <ShareIcon />,
      onClick: () => setOpenShareModal(true),
    },
    {
      id: "6",
      text: language[defaultLanguage].indice,
      backgroundColor: primary_color,
      key: "index",
      imgSrc: indexIcon,
      svg: <HomeIcon />,
      onClick: () => {
        handleOnClick("/leaflet");
        props.setIndexCarousel(0);
        if (
          (serverResponse.config.scrollVertical ||
            serverResponse.config.scrollHorizontal) &&
          width < BREAKPOINT.xs
        )
          scrollToSlide(serverResponse.leaflet.index ? 0 : 1);
      },
    },
    {
      id: "2",
      key: "products",
      text: language[defaultLanguage].prodotti,
      backgroundColor: pathname.includes("categories")
        ? secondary_color
        : primary_color,
      imgSrc: searchIcon,
      svg: <ProdottiIcon />,
      onClick: () => handleOnClick("/categories"),
    },
    {
      id: "1",
      key: "leaflet",
      text: readerType,
      backgroundColor: pathname.includes("leaflet")
        ? secondary_color
        : primary_color,
      imgSrc: leafLetIcon,
      svg: <VolantinoIcon />,
      onClick: () => handleOnClick("/leaflet"),
    },
    {
      id: "7",
      key: "login",
      text: language[defaultLanguage].login,
      backgroundColor: pathname.includes("login")
        ? secondary_color
        : primary_color,
      imgSrc: leafLetIcon,
      svg: <Icone.LoginIcona />,
      onClick: () => handleOnClick("/login"),
    },
    {
      id: "8",
      key: "logout",
      text: "Logout",
      backgroundColor: pathname.includes("logout")
        ? secondary_color
        : primary_color,
      imgSrc: leafLetIcon,
      svg: <Icone.LogoutIcona />,
      onClick: () => handleOnPressLogout(),
    },
  ];

  const filterFromServer = arrayEl.filter((el) => {
    return arrayToFilter.some((f) => {
      return f === el.key || el.key === "index";
    });
  });

  const elementToMerge = [arrayEl[0], arrayEl[5]];
  if (serverResponse.config.allow_login) {
    if (user) {
      elementToMerge.unshift(arrayEl[7]);
    } else {
      elementToMerge.unshift(arrayEl[6]);
    }
  }

  const elementToMap = manager.showList()
    ? [...new Set([...elementToMerge, ...filterFromServer])]
    : [arrayEl[1], arrayEl[2]];

  const mainButtonStyles = {};
  const actionButtonStyles = {};
  const style = {};
  const event = "click";
  return (
    <>
      <div
        style={{
          transitionDuration: "500s",
        }}
        className={`${isShown ? "d-flex" : "d-none"} d-lg-none`}
        ref={ref}
      >
        {/* onClick={() => props.setStatusOfMenu(!props.floatingMenu)} */}
        {/* {elementToMap.length > 0 ? (
          <Fab
            mainButtonStyles={mainButtonStyles}
            actionButtonStyles={actionButtonStyles}
            style={style}
            event={event}
            icon="fa fa-plus"
            alwaysShowTitle={true}
          >
            {elementToMap.map((el) => (
              <AB
                icon={el.imgSrc}
                text={el.text}
                key={el.id}
                onClick={el.onClick}
              >
                {el.svg}
                <img src={el.imgSrc} alt={el.text} />
              </AB>
            ))}
          </Fab>
        ) : null} */}
        <ShareModal open={openShareModal} setOpen={setOpenShareModal} />
      </div>

      <div className="mobile-footer d-block d-sm-none">
        <ul className="footer-nav">
          <li className="footer-nav-items">
            {/* <button className="footer-nav-btn menu-icon">
              <MenuIcon
                onClick={() => props.setStatusOfMenu(!props.floatingMenu)}
              />
            </button> */}
            {elementToMap.length > 0 ? (
              <Fab
                mainButtonStyles={mainButtonStyles}
                actionButtonStyles={actionButtonStyles}
                style={style}
                event={event}
                icon="fa fa-plus"
                alwaysShowTitle={true}
                subNav={elementToMap}
                isSubMenuOpen={isSubMenuOpen}
                setIsSubMenuOpen={setIsSubMenuOpen}
              >
                {/* {elementToMap.map((el) => (
                  <AB
                    icon={el.imgSrc}
                    text={el.text}
                    key={el.id}
                    onClick={el.onClick}
                  >
                    {el.svg}
                    <img src={el.imgSrc} alt={el.text} />
                  </AB>
                ))} */}
              </Fab>
            ) : null}
          </li>
          <li className="footer-nav-items">
            <button
              className="footer-nav-btn"
              onClick={() => {
                setIsSubMenuOpen(false);
                handleOnClick("/leaflet");
                props.setIndexCarousel(0);
                if (
                  (serverResponse.config.scrollVertical ||
                    serverResponse.config.scrollHorizontal) &&
                  width < BREAKPOINT.xs
                )
                  scrollToSlide(serverResponse.leaflet.index ? 0 : 1);
              }}
            >
              <HomeIcon />
            </button>
          </li>
          <li className="footer-nav-items">
            <button
              className="footer-nav-btn"
              onClick={() => {
                setIsSubMenuOpen(false);
                handleOnClick("/pages");
              }}
            >
              <PagineIcon />
            </button>
          </li>

          {/* <li className="footer-nav-items">
            <button className="footer-nav-btn">
              <StoreIcon />
            </button>
          </li> */}
          <li className="footer-nav-items">
            <button
              className="footer-nav-btn"
              onClick={() => {
                setIsSubMenuOpen(false);
                setOpenShareModal(true);
              }}
            >
              <ShareIcon />
            </button>
          </li>
          {(serverResponse.config.allow_login || props.authJWT?.user) && (
            <li className="footer-nav-items">
              <button
                className="footer-nav-btn"
                onClick={() => {
                  setIsSubMenuOpen(false);
                  toggle();
                }}
                id="mobile-grocery"
              >
                <CartIcon />
                {props.groceryList.length > 0 && (
                  <span className="notificatopn-badge">
                    {props.groceryList.length}
                  </span>
                )}
              </button>
              {!serverResponse.config.hide_grocery_list && (
                <GroceryList
                  isOpen={popoverOpen}
                  toggle={toggle}
                  placement={"bottom"}
                  target="mobile-grocery"
                  isMobile={true}
                />
              )}
            </li>
          )}
        </ul>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    groceryList: state.groceryList.list,
    floatingMenu: state.floatingMenu.isOpen,
    authJWT: state.authJWT,
  };
};

const mapDispatchToProps = { setStatusOfMenu, setIndexCarousel, logoutJWT };

export default connect(mapStateToProps, mapDispatchToProps)(FloatingMenu);
