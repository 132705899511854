import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { language } from "../../../languageProvider/language/language";
import { defaultLanguage } from "../../../helper/utility";

const AttachmentBody = ({ data, title }) => {
  return (
    <div>
      <h6>{title}</h6>
      <Link to={data.link} target="_blank">
        {language[defaultLanguage].allegato}
      </Link>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    modalRedux: state.modal,
  };
};

export default connect(mapStateToProps)(AttachmentBody);
