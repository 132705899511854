import React from "react";
// import { Player } from "video-react";
import TitleModal from "./Title.modal";
import { isMobile } from "react-device-detect";

const VideoModal = ({ data, title }) => {
  const player =
    data.video_type === "video_file" ? (
      <div className="col-12 m-auto video_file video-responsive h-100">
        {isMobile && <TitleModal title={title} />}
        <video
          autoPlay={!isMobile}
          muted
          playsInline
          height="100%"
          controls
          style={{ height: "100%", width: "100%" }}
        >
          <source src={process.env.PUBLIC_URL + data.link} type="video/mp4" />
        </video>
      </div>
    ) : (
      <div
        className="video-responsive video_iframe col-12 m-auto mt-0 p-0"
        // style={{ height: "calc(100vh - 150px)", width: "100%" }}
        // style={{ height: "calc(80vh - 220px)", width: "100%" }}
        style={{ width: "100%", height: "100%" }}
      >
        {isMobile && <TitleModal title={title} />}
        <iframe
          title={data.link}
          width="100%"
          height="100%"
          src={`https://www.youtube.com/embed/${data.link}?autoplay=0&mute=1`}
        />
      </div>
    );

  return <>{player}</>;
};

export default VideoModal;
