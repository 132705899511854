import React, { Component } from "react";
import { serverResponse } from "../util/fakeServer";
// import { gaEventLabel } from '../settings/gaConfig';
// import CustomProduct from '../components/leaflet/CustomProduct';
import VisibilitySensor from "react-visibility-sensor";
import Adsense from "../components/leaflet/Adsense";
import { setIndexCarousel } from "../redux/carousel/action";
import { getValue, isSupported } from "../helper/utility";
import { connect } from "react-redux";
import withNavigate from "../components/common/withNavigate";

import "../assets/css/custom.css";
import "../assets/css/responsive.css";

class Pages extends Component {
  navMarkers = (newMarker) => {
    var element = document.getElementById(
      newMarker + "-" + this.props.info.item_id
    );
    element.click();
  };

  imageUri = (el, key1, key2) => (isSupported ? el[key1] : el[key2]);

  constructor(props) {
    super(props);
    var t_items = 30;
    var t_pages = [];
    serverResponse.leaflet.pages.map((element, key) => {
      if (key < t_items)
        t_pages.push(
          this.imageUri(element, "webp_thumb_image_file", "thumb_image_file")
        );
      return 0;
    });
    this.state = {
      pages: t_pages,
      items: t_items,
    };
  }

  // componentDidMount() {
  //   if (serverResponse.config.ga_active) {
  //     ReactGA.pageview(
  //       `/${serverResponse.config.client_id}-${serverResponse.leaflet.id}/pagine`
  //     );
  //   }
  // }

  goToPage = (page) => {
    this.props.setIndexCarousel(getValue(0));
    this.props.navigate("/leaflet/" + page);
  };

  loadMore = () => {
    var t_pages = [];
    this.setState({ items: this.state.items + 10 });
    serverResponse.leaflet.pages.map((element, key) => {
      if (key < this.state.items)
        t_pages.push(
          this.imageUri(element, "webp_thumb_image_file", "thumb_image_file")
        );
      return 0;
    });
    this.setState({ pages: t_pages });
  };

  render() {
    const { pages } = this.state;

    const gridImages = pages.map((image, key) => {
      return (
        <div key={key} className="listingBox">
          <img
            src={
              process.env.PUBLIC_URL +
              image +
              "?v=" +
              serverResponse.config.version
            }
            title={"pag " + (key + 1)}
            alt={"page " + (key + 1)}
            className="shadow img-fluid"
            style={{ cursor: "pointer" }}
            onClick={() => this.goToPage(key + 1)}
          />
        </div>
      );
    });

    return (
      <>
        <div
          className="productpageslisting"
          // style={{
          //   paddingBottom: serverResponse.config.adsense ? "90px" : "50px",
          // }}
        >
          {gridImages}

          <VisibilitySensor
            style={{ display: "inline" }}
            onChange={(isVisible) => {
              if (isVisible) {
                this.loadMore();
              }
            }}
          >
            {/* <div>
              <span style={{ color: "#ffffff" }}>.</span>
            </div> */}
          </VisibilitySensor>
          <div style={{ display: "none" }}>{this.state.products}</div>
        </div>
        {serverResponse.config.adsense && <Adsense position="fixed" />}
      </>
    );
  }
}

export default withNavigate(connect(null, { setIndexCarousel })(Pages));
