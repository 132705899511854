import * as React from "react";
const ShareIcon = (props) => (
  <svg
    width="22"
    height="24"
    viewBox="0 0 22 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.0625 15.975C10.5125 15.975 10.925 15.6 10.925 15.1125V9.48749C10.925 7.46249 12.575 5.81249 14.6 5.81249H19.0625L16.8875 7.87499C16.55 8.21249 16.55 8.73749 16.85 9.07499C17 9.26249 17.225 9.33749 17.45 9.33749C17.675 9.33749 17.8625 9.26249 18.0125 9.11249L21.4625 5.84999C21.6875 5.62499 21.8375 5.28749 21.8375 4.94999C21.8375 4.61249 21.6875 4.31249 21.4625 4.08749L18.0125 0.899993C17.675 0.599993 17.15 0.599993 16.8125 0.937493C16.5125 1.27499 16.5125 1.79999 16.85 2.13749L19.025 4.16249H14.525C11.5625 4.16249 9.1625 6.56249 9.1625 9.52499V15.15C9.2375 15.6 9.6125 15.975 10.0625 15.975Z"
      fill="currentcolor"
    />
    <path
      d="M21.125 14.2875C20.675 14.2875 20.2625 14.6625 20.2625 15.15V20.5875C20.2625 21.1875 19.775 21.675 19.175 21.675H2.825C2.225 21.675 1.7375 21.1875 1.7375 20.5875V15.1125C1.7375 14.6625 1.3625 14.25 0.874997 14.25C0.387497 14.25 0.0124969 14.625 0.0124969 15.1125V20.55C0.0124969 22.0875 1.25 23.325 2.7875 23.325H19.175C20.7125 23.325 21.95 22.0875 21.95 20.55V15.1125C21.9875 14.6625 21.6125 14.2875 21.125 14.2875Z"
      fill="currentcolor"
    />
  </svg>
);
export default ShareIcon;
