import React, { memo, useRef } from "react";
import { serverResponse } from "../../../../util/fakeServer";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useCurrentWidth from "../../../../hooks/widthHook";
import { BREAKPOINT } from "../../../../helper/constant";

const GalleryModal = ({
  gallery,
  maxWidth = "350px",
  customClassContainer = "",
  customClassCol = "col-lg-12",
  dots = true,
  autoplay = false,
  arrows = true,
  minImg = true,
}) => {
  const currentWidth = useCurrentWidth();

  const isLg = currentWidth > BREAKPOINT.xs;

  const settings = {
    // dots: isLg || !dots ? false : true,
    dots: gallery?.length > 2 ? true : false,
    infinite: false,
    dotsClass: "slick-dots slick-thumb m-auto",
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: gallery?.length > 1 && isLg && arrows ? true : false,
    autoplay: false,
    autoplaySpeed: 9000000000,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const imgs =
    gallery?.length === 1 ? gallery : gallery?.filter((el) => !el.cropped);

  const slickRef = useRef();
  const listImages = imgs?.map((element, key) => (
    <div
      className="h-100 container-slide d-flex align-items-center justify-content-center"
      key={key}
    >
      <img
        // style={{
        //   height: maxWidth,
        //   width: `${maxWidth} !important`,
        //   objectFit: "contain"
        // }}
        style={{ aspectRatio: "1 / 1" }}
        className={`${
          isLg && !minImg ? "carousel_img_modal  w-lg-100" : "m-auto img-fluid"
        } no-visual-search`}
        loading="lazy"
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = `${serverResponse.config.clientIcon}`;
        }}
        src={
          element.image_file
            ? process.env.PUBLIC_URL +
              element.image_file +
              "?v=" +
              serverResponse.config.version
            : element
        }
        alt="img"
      />
    </div>
  ));
  return (
    <div className={`${customClassContainer}`}>
      {/* <div
        className={`${customClassCol} col-12  m-auto  justify-content-center align-items-center`}
      >  */}
      <div className={`${customClassCol}`}>
        <Slider style={{ width: "100%" }} ref={slickRef} {...settings}>
          {listImages}
        </Slider>
      </div>
    </div>
  );
};

export default memo(GalleryModal);
