import * as React from "react";
const CloseCartIcon = (props) => (
  <svg
    width="12"
    height="11"
    viewBox="0 0 12 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.4082 0.0909088L5.99911 3.47159L7.67525 0.0909088H11.5673L8.64116 5.54545L11.7093 11H7.8457L5.99911 7.53409L4.20934 11H0.288885L3.38548 5.54545L0.487749 0.0909088H4.4082Z"
      fill="#0D0040"
    />
  </svg>
);
export default CloseCartIcon;
