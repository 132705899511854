import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classNames from "classnames";
// import IntlMessages from "util/intlMessages";
// import { Breadcrumb, BreadcrumbItem } from "reactstrap";

import { iconDemo, interattivoLogo } from "../../helper/constant";
import { serverResponse } from "../../util/fakeServer";
import { isMobile } from "react-device-detect";
import { language } from "../../languageProvider/language/language";
import { defaultLanguage } from "../../helper/utility";
import { VolantinoIcon } from "../../assets/icons";

const PageTitle = ({ title, sidebarTheme, className, breadCrumb }) => {
  let clientLogo = serverResponse.config.clientIcon;

  return (
    <Fragment>
      {/** classNames("Page-title", "mr-15", className) */}
      <div
        style={{ justifyContent: "space-between" }}
        className={
          isMobile
            ? classNames("Page-title", "mtb-15")
            : classNames("mtb-15", "mr-15", "Page-title", className)
        }
      >
        <div className="">
          {/*title.substr(title.indexOf(".") + 1).substring(0, 2)*/}
          <VolantinoIcon color="#9747FF"/>
        </div>
        <div className="title page-title-color custom-page-title">
          {/* <IntlMessages id={title} /> */}
          {language[defaultLanguage][title]}
        </div>
        <div className="">
          {clientLogo !== "" && (
            <img
              className="img-fluid"
              style={{ maxWidth: "50px" }}
              src={serverResponse.config.logo_full || clientLogo}
              alt="img"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = clientLogo;
              }}
            />
          )}
        </div>
      </div>
      {/* {breadCrumb && (
        <div>
          <Breadcrumb className="custom-breadcumb">
            {breadCrumb &&
              breadCrumb.map((e, i) => {
                if (i === breadCrumb.length - 1) {
                  return (
                    <BreadcrumbItem key={i} active>
                      <IntlMessages id={e.name} />
                    </BreadcrumbItem>
                  );
                } else {
                  return (
                    <BreadcrumbItem className="breadcumb-color" key={i}>
                      <IntlMessages id={e.name} />
                    </BreadcrumbItem>
                  );
                }
              })}
          </Breadcrumb>
        </div>
      )} */}
    </Fragment>
  );
};

PageTitle.propTypes = {
  title: PropTypes.string.isRequired
};

const mapStateToProps = (state) => {
  return {
    // ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(PageTitle);
