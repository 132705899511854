import * as React from "react";
const DetailIcon = (props) => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.97218 5.98163H8.9449C8.61764 5.98163 8.31763 6.25436 8.31763 6.6089C8.31763 6.96344 8.59036 7.23617 8.9449 7.23617H8.97218C9.29944 7.23617 9.59945 6.96344 9.59945 6.6089C9.59945 6.25436 9.32672 5.98163 8.97218 5.98163Z"
      fill="currentcolor"
    />
    <path
      d="M8.97249 8.13605C8.6452 8.13605 8.34521 8.40878 8.34521 8.76332V12.9633C8.34521 13.2906 8.61794 13.5906 8.97249 13.5906C9.29975 13.5906 9.59976 13.3179 9.59976 12.9633V8.73604C9.59976 8.40878 9.32703 8.13605 8.97249 8.13605Z"
      fill="currentcolor"
    />
    <path
      d="M8.97215 1.26355C4.41758 1.26355 0.708496 4.94538 0.708496 9.49992C0.708496 14.0545 4.41758 17.7635 8.97215 17.7635C13.5267 17.7635 17.2085 14.0545 17.2085 9.49992C17.2085 4.94538 13.5267 1.26355 8.97215 1.26355ZM8.97215 16.5363C5.09942 16.5363 1.93577 13.3726 1.93577 9.49992C1.93577 5.62719 5.09942 2.46355 8.97215 2.46355C12.8448 2.46355 15.9812 5.62719 15.9812 9.49992C15.9812 13.3726 12.8448 16.5363 8.97215 16.5363Z"
      fill="currentcolor"
    />
  </svg>
);
export default DetailIcon;
