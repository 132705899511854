import * as React from "react";
const PdfIcon = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.8375 7.575L13.0875 1.2C12.7125 0.824997 12.225 0.637497 11.7 0.637497H5.54999C4.42499 0.637497 3.52499 1.5375 3.52499 2.6625V21.3C3.52499 22.425 4.42499 23.325 5.54999 23.325H18.45C19.575 23.325 20.475 22.425 20.475 21.3V9.0375C20.475 8.475 20.25 7.95 19.8375 7.575ZM12.8625 3.3L17.5875 7.725H13.2C13.0125 7.725 12.8625 7.575 12.8625 7.425V3.3ZM18.45 21.675H5.54999C5.36249 21.675 5.21249 21.525 5.21249 21.3375V2.7C5.21249 2.5125 5.36249 2.3625 5.54999 2.3625H11.1375V7.425C11.1375 8.5125 12.0375 9.4125 13.1625 9.4125H18.7875V21.3C18.7875 21.4875 18.6375 21.675 18.45 21.675Z"
      fill="currentcolor"
    />
    <path
      d="M7.30029 19V15.3636H8.8699C9.13978 15.3636 9.37593 15.4169 9.57835 15.5234C9.78076 15.63 9.9382 15.7797 10.0506 15.9727C10.1631 16.1656 10.2193 16.3911 10.2193 16.6491C10.2193 16.9096 10.1613 17.1351 10.0453 17.3256C9.9305 17.5162 9.76892 17.663 9.56059 17.766C9.35344 17.869 9.11137 17.9205 8.83438 17.9205H7.89688V17.1534H8.63552C8.75152 17.1534 8.85036 17.1333 8.93204 17.093C9.0149 17.0516 9.07823 16.993 9.12203 16.9173C9.16701 16.8415 9.1895 16.7521 9.1895 16.6491C9.1895 16.545 9.16701 16.4562 9.12203 16.3828C9.07823 16.3082 9.0149 16.2514 8.93204 16.2124C8.85036 16.1721 8.75152 16.152 8.63552 16.152H8.28751V19H7.30029ZM11.9807 19H10.5815V15.3636H11.9665C12.3405 15.3636 12.6637 15.4364 12.9359 15.582C13.2094 15.7264 13.4201 15.9348 13.568 16.207C13.7172 16.4781 13.7918 16.803 13.7918 17.1818C13.7918 17.5606 13.7178 17.8861 13.5698 18.1584C13.4219 18.4295 13.2123 18.6378 12.9413 18.7834C12.6702 18.9278 12.35 19 11.9807 19ZM11.5688 18.1619H11.9452C12.1251 18.1619 12.2784 18.1329 12.4051 18.0749C12.5329 18.0169 12.63 17.9169 12.6962 17.7749C12.7637 17.6328 12.7975 17.4351 12.7975 17.1818C12.7975 16.9285 12.7631 16.7308 12.6945 16.5888C12.627 16.4467 12.5276 16.3467 12.3962 16.2887C12.266 16.2307 12.1062 16.2017 11.9168 16.2017H11.5688V18.1619ZM14.2485 19V15.3636H16.8054V16.1591H15.2358V16.7841H16.6491V17.5795H15.2358V19H14.2485Z"
      fill="currentcolor"
    />
  </svg>
);
export default PdfIcon;
