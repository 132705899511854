import React, { Component, createRef } from "react";
import { Button, Col } from "reactstrap";
import CustomSlide from "./CustomSlide";
import CustomIndex from "./CustomIndex";
import { isIE, isIOS } from "react-device-detect";
import "../../assets/css/custom.css";
import { serverResponse } from "../../util/fakeServer";
import { gaEventLabel } from "../../settings/gaConfig";
import Modal from "react-modal";
import ReactPlayer from "react-player";
import PrismaZoom from "react-prismazoom";
import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";
import "react-awesome-slider/dist/custom-animations/cube-animation.css";
import { connect } from "react-redux";
import {
  setIndexCarousel,
  setIndexLabel,
  setSlideMax,
  isFromInput,
  setFirstLoad,
  setZoomLevel,
  setRefZoom,
  setButtonDisabled
} from "../../redux/carousel/action";
import CarouselLoader from "./CarouselLoader";
import CarouselArrow from "./CarouselArrow";
import { BREAKPOINT, interattivoLogo } from "../../helper/constant";
import {
  checkMobileOs,
  defaultLanguage,
  getPager,
  isSupported,
  sendAnalyticsEvent
} from "../../helper/utility";
import Slider from "react-input-slider";
import { styles } from "./customCarousel.styles";
// import { withRouter } from "react-router-dom";
import withNavigate from "../common/withNavigate.js";
import AdComponent from "./Adsense";
import ZoomButton from "./ZoomButton";
import InputPage from "../common/InputPage";
import { language } from "../../languageProvider/language/language";
import ManagerFactory from "../../managers/ManagerFactory";
import * as Icone from "../../assets/images/icone/index.js";
import ShareModal from "../common/ShareModal";
import InPublishIcona from "../../assets/images/icone/InPublish.icona.js";
import ShareIcon from "../../assets/icons/share.icon.js";
import GoToProductsIcon from "../../assets/icons/goToProducts.icon.js";
import ProdottiIcon from "../../assets/icons/prodotti.icon.js";

const {
  config: { adsense, type, show_one_page, primary_color },
  leaflet: { pages, index: serverIndex },
  video
} = serverResponse;

let videoModalStyles = {
  content: {
    background: "transparent",
    border: 0
  },
  overlay: {
    zIndex: 999999999
  }
};

if (window.innerWidth < BREAKPOINT.xs) {
  videoModalStyles = {
    content: {
      background: "transparent",
      border: 0,
      padding: 0,
      top: "10px",
      left: "10px",
      bottom: "10px",
      right: "10px"
    },
    overlay: {
      zIndex: 999999999
    }
  };
}

const showOnePage = show_one_page || window.innerWidth < BREAKPOINT.xs;
const hasIndex_ = serverIndex != null;

var thumbs = [];
if (hasIndex_) {
  thumbs.push(serverIndex.image_file);
}
pages.map((e) => thumbs.push(e.thumb_image_file));

const manager = ManagerFactory.getManager();

class CustomCarousel extends Component {
  constructor(props) {
    super(props);

    let leafleat_has_video = false;
    let pages_with_video = null;
    let video_only_once = false;
    let video_mandatory_seconds = 0;
    let level_resize = adsense ? 200 : 105;
    let global_heigth = window.innerHeight - level_resize;
    let global_width = window.innerWidth;

    this.prismaZoom = createRef();

    if (serverResponse.hasOwnProperty("video")) {
      leafleat_has_video = true;
      pages_with_video = video.videos.map((i) => i.page);
      video_only_once = video.show_only_once;
      video_mandatory_seconds = video.mandatory_seconds;
    }

    this.state = {
      loaded: false,
      slideIndex:
        type === "menu" ? 0 : window.innerWidth < BREAKPOINT.xs ? 1 : 0,
      swipe: true,
      video_url: "",
      video_muted: true,
      video_playing: false,
      video_modal_open: false,
      leafleat_has_video: leafleat_has_video,
      pages_with_video: pages_with_video,
      show_close_video: false,
      preroll_video_in_progress: false,
      video_only_once: video_only_once,
      video_page_number: null,
      video_page_item_id: null,
      countdown: video_mandatory_seconds,
      video_mandatory_seconds: video_mandatory_seconds,
      isZoom: false,
      sliderValue:
        type === "menu" ? 0 : window.innerWidth < BREAKPOINT.xs ? 1 : 0,
      fromSlider: false,
      tmpIndex: props.carouselIndex.carouselIndex,
      buttonDisable: false,
      globalHeigth: global_heigth,
      globalWidth: global_width,
      closeClick: false,
      isCurrentOnePage: show_one_page,
      openShareModal: false
    };

    this.closeVideoModal = this.closeVideoModal.bind(this);
    this.onVideoProgress = this.onVideoProgress.bind(this);
    this.onVideoStart = this.onVideoStart.bind(this);
    this.toggleVideoMuted = this.toggleVideoMuted.bind(this);
    this.showPageVideo = this.showPageVideo.bind(this);
    this.onVideoEnded = this.onVideoEnded.bind(this);
    this.userCloseVideo = this.userCloseVideo.bind(this);
    this.resize = this.resize.bind(this);
  }

  thumbs = pages.map((e) => e.thumb_image_file);

  ref = (player) => {
    this.player = player;
  };

  toggleVideoMuted() {
    this.setState({ video_muted: !this.state.video_muted });
  }

  onVideoEnded() {
    this.closeVideoModal();
    sendAnalyticsEvent(
      gaEventLabel.page_video_completion,
      String(this.state.video_page_item_id)
    );
  }

  userCloseVideo() {
    this.closeVideoModal();
    sendAnalyticsEvent(
      gaEventLabel.page_video_closed_by_user,
      String(this.state.video_page_item_id)
    );
  }

  closeVideoModal() {
    this.setState({
      video_modal_open: false,
      video_url: "",
      preroll_video_in_progress: false,
      show_close_video: false,
      closeClick: true
    });
  }

  showPageVideo(page_number, onload = false) {
    if (this.state.video_only_once) {
      let session_video_pages = sessionStorage.getItem("video_pages");
      if (session_video_pages == null) {
        sessionStorage.setItem("video_pages", JSON.stringify([page_number]));
      } else {
        let session_video_pages_parsed = JSON.parse(session_video_pages);
        if (session_video_pages_parsed.includes(page_number)) {
          return;
        } else {
          session_video_pages_parsed.push(page_number);
          sessionStorage.setItem(
            "video_pages",
            JSON.stringify(session_video_pages_parsed)
          );
        }
      }
    }

    this.setState(
      {
        video_modal_open: true,
        video_url:
          process.env.PUBLIC_URL +
          video.videos.filter((i) => i.page === page_number)[0].url,
        video_playing: true,
        video_muted: onload ? true : isIOS,
        video_page_number: page_number,
        video_page_item_id: video.videos.filter(
          (i) => i.page === page_number
        )[0].item_id,
        countdown: this.state.video_mandatory_seconds
      },
      () => {
        sendAnalyticsEvent(
          gaEventLabel.page_video_play,
          String(this.state.video_page_item_id)
        );
      }
    );
  }

  onVideoProgress(args) {
    if (this.state.preroll_video_in_progress) {
      this.setState({
        countdown: parseInt(this.state.countdown - 1)
      });
      if (args.playedSeconds > this.state.video_mandatory_seconds) {
        this.setState({
          preroll_video_in_progress: false,
          show_close_video: true
        });
        sendAnalyticsEvent(
          gaEventLabel.page_video_mandatory_completed,
          String(this.state.video_page_item_id)
        );
      }
    }
  }

  onVideoStart() {
    this.setState({
      preroll_video_in_progress: true
    });
  }

  goToPage = (index) => {
    this.setState(
      {
        slideIndex: index,
        tmpIndex: index
      },
      () => {
        this.setState(this.state);
      }
    );
  };

  leafletSwipe = () => this.setState({ swipe: !this.state.swipe });

  setSwipe = (swipe) => {
    this.setState({ swipe: swipe });
    if (isIE) {
      document.getElementById("scrollbars").style["z-index"] = swipe
        ? ""
        : "-1";
    }
  };

  isSwipeDisabed = () => (this.state.swipe ? false : true);

  handleChangeOrientation = () => {
    this.setState({ state: this.state });
  };

  keyControl = (e) => {
    switch (e.code) {
      case "ArrowRight":
        if (
          !this.props.carouselIndex.buttonCarouselDisabled &&
          !this.props.modalRedux.isModalOpen &&
          this.props.carouselIndex.carouselIndex !== global.max - 1
        )
          this.props.setIndexCarousel(
            this.props.carouselIndex.carouselIndex + 1
          );
        break;
      case "ArrowLeft":
        if (
          !this.props.modalRedux.isModalOpen &&
          !this.props.carouselIndex.buttonCarouselDisabled &&
          this.props.carouselIndex.carouselIndex !== 0
        )
          this.props.setIndexCarousel(
            this.props.carouselIndex.carouselIndex - 1
          );
        break;
      default:
        break;
    }
  };

  componentDidMount() {
    window.addEventListener("resize", this.resize);
    window.addEventListener("keydown", this.keyControl);
    this.resize();
    if (!this.props.carouselIndex.firstLoad) {
      setTimeout(() => {
        this.props.setFirstLoad();
      }, 1000);
    }
    this.props.setRefZoom(this.prismaZoom);

    window.addEventListener("orientationchange", this.handleChangeOrientation);
    let pageIndex = this.props.setPageIndex;

    if (pageIndex !== undefined && !isNaN(pageIndex)) {
      pageIndex = parseInt(pageIndex);

      setTimeout(() => {
        this.props.setIndexCarousel(pageIndex);
      }, 100);
    } else {
      if (this.state.globalWidth < BREAKPOINT.xs) {
        pageIndex = 2;
      }
      if (this.state.leafleat_has_video) {
        if (this.state.pages_with_video.includes(1)) {
          this.showPageVideo(1, true);
        } else if (
          this.state.pages_with_video.includes(this.state.slideIndex)
        ) {
          this.showPageVideo(this.state.slideIndex, true);
        }
      }
    }
    setTimeout(() => window.dispatchEvent(new Event("resize")), 0);

    this.setState({
      loaded: true
    });

    this.timer();
  }

  timer = () => {
    if (this.timerHandle) {
      return;
    }
    this.timerHandle = setTimeout(() => {
      if (document.getElementById("plus-" + this.props.itemId)) {
        document.getElementById("plus-" + this.props.itemId).click();
      }
      this.timerHandle = 0;
    }, 100);
  };

  clearTimer = () => {
    if (this.timerHandle) {
      clearTimeout(this.timerHandle);
      this.timerHandle = 0;
    }
  };

  setSwitchIndex = (n) => {
    const { carouselIndex } = this.props.carouselIndex;

    const has_index = serverIndex !== null;
    const location = this.props.location;
    if (carouselIndex === 0) {
      this.props.setIndexCarousel(0);
    } else if (n === 1) {
      if (has_index) {
        !location.from
          ? this.props.setIndexCarousel(carouselIndex * 2)
          : this.props.setIndexCarousel(carouselIndex);
        if (location.from) {
          let newLocation = {
            ...location,
            from: undefined
          };

          this.props.history.replace(newLocation);
        }
      } else {
        this.props.setIndexCarousel(carouselIndex * 2 - 1);
      }
    } else {
      if (has_index) {
        this.props.setIndexCarousel(Math.floor(carouselIndex / 2));
      } else {
        let index =
          carouselIndex % 2 === 0
            ? Math.floor(carouselIndex / 2)
            : (carouselIndex + 1) / 2;
        this.props.setIndexCarousel(index);
      }
    }
  };

  resize() {
    this.setState({
      globalHeigth: window.innerHeight
    });
    this.setState({ globalWidth: window.innerWidth });

    if (!show_one_page) {
      if (
        this.state.globalWidth < BREAKPOINT.xs &&
        !this.state.isCurrentOnePage
      ) {
        this.setState({ isCurrentOnePage: true });
        this.setSwitchIndex(1);
      } else if (
        this.state.globalWidth >= BREAKPOINT.xs &&
        this.state.isCurrentOnePage
      ) {
        this.setState({ isCurrentOnePage: false });
        this.setSwitchIndex(2);
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
    window.removeEventListener("keydown", this.keyControl);
    window.removeEventListener(
      "orientationchange",
      this.handleChangeOrientation
    );
    this.clearTimer();
  }

  disableSwipe = () => {
    setTimeout(() =>
      this.setState({
        isZoom: true
      })
    );
  };

  enableSwipe = () => {
    this.setState({
      isZoom: false
    });
  };

  getThumb(x) {
    const hasIndex = serverIndex != null;
    const leafLeatLength = thumbs.length;
    const onePage = show_one_page || this.state.globalWidth < BREAKPOINT.xs;

    if (hasIndex) {
      if (onePage) {
        return thumbs[this.state.tmpIndex];
      } else {
        return x === 1
          ? thumbs[this.state.tmpIndex * 2]
          : this.state.tmpIndex * 2 + 1 > leafLeatLength
          ? null
          : thumbs[this.state.tmpIndex * 2 + 1];
      }
    } else {
      if (onePage) {
        return thumbs[this.state.tmpIndex];
      } else {
        if (this.state.tmpIndex === 0) {
          return x === 1 ? null : thumbs[this.state.tmpIndex * 2];
        } else {
          return x === 1
            ? thumbs[this.state.tmpIndex * 2 - 1]
            : this.state.tmpIndex * 2 + 1 > leafLeatLength
            ? null
            : thumbs[this.state.tmpIndex * 2];
        }
      }
    }
  }

  getAnalyticsIndex = (index) => {
    if (!showOnePage) {
      if (hasIndex_) {
        return [index * 2, index * 2 + 1];
      } else {
        return [index * 2 + 1, index * 2 + 2];
      }
    } else {
      if (index === 0 && hasIndex_) {
        return [0];
      } else {
        if (hasIndex_) {
          return [index];
        } else {
          return [index + 1];
        }
      }
    }
  };

  isLastPage = (pageNumber) => {
    return pageNumber === pages.length;
  };

  render() {
    const { slideIndex } = this.state;
    const pages = this.props.leaflet.pages.filter(
      (element, key) => element.number > 0
    );

    // Slides
    var listButtonsPager = [];
    var max = 0;
    let listSlides = [];

    const imageUriIndex = (i, key1, key2) =>
      isSupported ? pages[i][key1] : pages[i][key2];

    const imageUri = (el, key1, key2) => (isSupported ? el[key1] : el[key2]);

    //key1 = webp_image_file
    //key2 = image_file

    // key1 = thumb_image_file
    //key2 = webp_thumb_image_file

    if (!show_one_page && this.state.globalWidth >= BREAKPOINT.xs) {
      global.showOnePage = false;
      listSlides.push(
        <div
          key={0}
          className="row w-100 background_color "
          style={{ margin: "0 auto" }}
        >
          <div className="d-flex justify-content-end col-6 p-0">
            {serverIndex && (
              <CustomIndex index={this.props.leaflet.index} shadow={"right"} />
            )}
          </div>
          <div
            className={`d-flex justify-content-${
              serverIndex ? "start" : "center"
            } col-${serverIndex ? "6" : "12"} p-0`}
          >
            <CustomSlide
              animationType={pages[0].animationType}
              zoom={this.prismaZoom.current}
              disableSwipe={this.disableSwipe}
              enableSwipe={this.enableSwipe}
              pageNumber={pages[0].number}
              imagePath={imageUriIndex(0, "webp_image_file", "image_file")}
              imageThumbPath={imageUriIndex(
                0,
                "webp_thumb_image_file",
                "thumb_image_file"
              )}
              products={pages[0].interactivities}
              leafletSwipe={this.leafletSwipe}
              isSwipeDisabed={this.isSwipeDisabed}
              setSwipe={this.setSwipe}
              shadow={serverIndex ? "left" : "none"}
            />
          </div>
        </div>
      );

      // BOTTONI PAGER
      listButtonsPager.push(
        <Button
          disabled={this.props.carouselIndex.buttonCarouselDisabled}
          style={styles.pagerButtons}
          className="carousel-pagination-button"
          key={0}
          color="primary"
          onClick={() => {
            this.props.isFromInput(true);
            this.props.setIndexCarousel(0);
          }}
          active={this.props.carouselIndex.carouselIndex === 0}
        >
          1
        </Button>
      );

      max++;
      for (
        let start = 1, indexActive = 1;
        start < pages.length;
        start += 2, indexActive++
      ) {
        let isLastPage = this.isLastPage(pages[start].number);
        let buttonLabel = start + 1;
        listSlides.push(
          <div key={start} className="row w-100 background_color">
            <div
              className={`d-flex justify-content-${
                isLastPage ? "center" : "end"
              } col-${isLastPage ? "12" : "6"} p-0`}
            >
              <CustomSlide
                animationType={pages[start].animationType}
                zoom={this.prismaZoom.current}
                disableSwipe={this.disableSwipe}
                enableSwipe={this.enableSwipe}
                pageNumber={pages[start].number}
                imagePath={imageUriIndex(
                  start,
                  "webp_image_file",
                  "image_file"
                )}
                imageThumbPath={imageUriIndex(
                  start,
                  "webp_thumb_image_file",
                  "thumb_image_file"
                )}
                // imagePath={pages[start].image_file}
                // imageThumbPath={pages[start].thumb_image_file}
                products={pages[start].interactivities}
                leafletSwipe={this.leafletSwipe}
                isSwipeDisabed={this.isSwipeDisabed}
                setSwipe={this.setSwipe}
                shadow={pages[start + 1] ? "right" : "none"}
              />
            </div>
            {pages[start + 1] && (
              <div className="d-flex justify-content-start col-6 p-0">
                <CustomSlide
                  animationType={pages[start + 1].animationType}
                  zoom={this.prismaZoom.current}
                  disableSwipe={this.disableSwipe}
                  enableSwipe={this.enableSwipe}
                  pageNumber={pages[start + 1].number}
                  imagePath={imageUriIndex(
                    start + 1,
                    "webp_image_file",
                    "image_file"
                  )}
                  imageThumbPath={imageUriIndex(
                    start + 1,
                    "webp_thumb_image_file",
                    "thumb_image_file"
                  )}
                  // imagePath={pages[start + 1].image_file}
                  // imageThumbPath={pages[start + 1].thumb_image_file}
                  products={pages[start + 1].interactivities}
                  leafletSwipe={this.leafletSwipe}
                  isSwipeDisabed={this.isSwipeDisabed}
                  setSwipe={this.setSwipe}
                  shadow={"left"}
                />
              </div>
            )}
          </div>
        );
        // COSTRUZIONE PAGER BOTTONI
        if (start + 1 < pages.length) {
          buttonLabel += "-" + (start + 2);
        }

        listButtonsPager.push(
          <Button
            disabled={this.props.carouselIndex.buttonCarouselDisabled}
            style={styles.pagerButtons}
            className="carousel-pagination-button"
            key={start}
            color="primary"
            onClick={() => {
              this.props.isFromInput(true);
              this.props.setIndexCarousel(indexActive);
            }}
            active={this.props.carouselIndex.carouselIndex === indexActive}
          >
            {buttonLabel}
          </Button>
        );

        max++;
      }
    } else {
      global.showOnePage = true;
      if (serverIndex !== null) {
        listSlides.push(
          <div
            key={0}
            className="row w-100 background_color justify-content-center"
            style={{ margin: "0 auto" }}
          >
            <div className="d-flex justify-content-center col-lg-12  col-12 p-0">
              <CustomIndex index={this.props.leaflet.index} />
            </div>
          </div>
        );

        listButtonsPager.push(
          <Button
            disabled={this.props.carouselIndex.buttonCarouselDisabled}
            className="carousel-pagination-button"
            style={styles.pagerButtons}
            key={"index"}
            color="primary"
            onClick={() => {
              this.props.isFromInput(true);
              this.props.setIndexCarousel(0);
            }}
            active={this.props.carouselIndex.carouselIndex === 0}
          >
            Indice
          </Button>
        );

        max++;
      }

      pages.map((page, index) => {
        listSlides.push(
          <div
            key={index}
            className="row w-100 background_color justify-content-center"
            style={{ margin: "0 auto" }}
          >
            <div className="d-flex justify-content-center  col-12 p-0">
              <CustomSlide
                animationType={page.animationType}
                zoom={this.prismaZoom.current}
                disableSwipe={this.disableSwipe}
                enableSwipe={this.enableSwipe}
                pageNumber={page.number}
                imagePath={imageUri(page, "webp_image_file", "image_file")}
                imageThumbPath={imageUri(
                  page,
                  "webp_thumb_image_file",
                  "thumb_image_file"
                )}
                products={page.interactivities}
                leafletSwipe={this.leafletSwipe}
                isSwipeDisabed={this.isSwipeDisabed}
                setSwipe={this.setSwipe}
                shadow={"none"}
              />
            </div>
          </div>
        );

        listButtonsPager.push(
          <Button
            disabled={this.props.carouselIndex.buttonCarouselDisabled}
            className="carousel-pagination-button"
            style={styles.pagerButtons}
            key={index}
            color="primary"
            onClick={() => {
              this.props.isFromInput(true);
              this.props.setIndexCarousel(
                serverIndex === null ? index : index + 1
              );
            }}
            active={
              serverIndex === null
                ? this.props.carouselIndex.carouselIndex === index
                : this.props.carouselIndex.carouselIndex === index + 1
            }
          >
            {index + 1}
          </Button>
        );

        max++;
        return true;
      });
    }

    global.max = max;

    //Pagination

    // let listPages = [];
    const setT = (x) => {
      var value = parseInt(x, 10);
      this.setState({ tmpIndex: value });
    };

    // function getCol() {
    //   let col = 4;
    //   let n = listPages.length;
    //   if (show_one_page) {
    //     if (n > 50) {
    //       col = 10;
    //     } else if (n > 40) {
    //       col = 8;
    //     } else if (n > 30) {
    //       col = 6;
    //     }
    //   } else {
    //     if (n > 50) {
    //       col = 8;
    //     } else if (n > 40) {
    //       col = 6;
    //     } else if (n > 30) {
    //       col = 4;
    //     }
    //   }
    //   return col;
    // }

    const toggleSwiping = (e) => {
      this.setState({
        isZoom: e === 1 ? false : true
      });

      this.props.setZoomLevel(e);
    };

    const retrievePageAndCheckVideo = (str) => {
      let res = false;
      let page = null;
      if (!str || str === undefined || str === null) {
        return res;
      }
      if (str === "Indice") {
        str = "0";
      }
      if (this.state.globalWidth < BREAKPOINT.xs || show_one_page) {
        const pageToNumber = parseInt(str);
        if (this.state.pages_with_video.includes(pageToNumber)) {
          page = parseInt(pageToNumber);
          res = true;
        }
      } else {
        const labelPages = str.split("-");
        for (let index = 0; index < labelPages.length; index++) {
          if (
            this.state.pages_with_video.includes(parseInt(labelPages[index]))
          ) {
            page = parseInt(labelPages[index]);
            res = true;
          }
        }
      }
      if (res && page !== null) {
        const [{ url }] = video.videos.filter((pg) => pg.page === page);
        return { res, url };
      }

      return res;
    };

    // const sliderCol = "col-" + getCol();

    const goToProduct = () => {
      this.props.navigate("/categories");
    };

    let classPrismaZoom = adsense ? "H_adsense" : "H_not_adsense";
    if (checkMobileOs()) {
      classPrismaZoom = adsense
        ? "H_adsense_mobile_detected"
        : "H_not_adsense_mobile_detected";
    }
    // const hasTempVideo = () => {
    //   const marker = this.props.leaflet.pages[0].interactivities;
    //   if (marker.length > 0) {
    //     let temp_marker = marker[0].markers[0];
    //     return (
    //       temp_marker.type === "play" &&
    //       temp_marker.data.link.match("interattivo_demo") !== 0
    //     );
    //   }
    // };

    const setOpenShareModal = () => {
      this.setState({
        openShareModal: !this.state.openShareModal
      });
    };

    return (
      <>
        <div
          className="container-fluid relative leaflet"
          id="leaflet"
          style={styles.prismaZoomContainer}
        >
          <PrismaZoom
            allowZoom={false}
            doubleTouchMaxDelay={200}
            onZoomChange={(e) => {
              toggleSwiping(e);
            }}
            allowTouchEvents={true}
            allowWheel={serverResponse.config?.wheel_zoom ?? false}
            maxZoom={this.props.carouselIndex.modalOpen ? 1 : 5}
            ref={this.prismaZoom}
            className={`row w-100 m-0 h-100 prismaZoom ${classPrismaZoom}`}
          >
            <div className="col-12 p-0 h-100">
              <div className="row w-100 m-0 h-100 align-items-center">
                {/* FRECCIA 1 */}

                <div className="custom-col-1 p-0 h-100 d-none d-sm-flex justify-content-center align-items-center">
                  {listSlides.length > 1 && (
                    <CarouselArrow
                      carousel={true}
                      arrowColor={primary_color}
                      direction="left"
                      double={false}
                      size="0.625em"
                    />
                  )}
                </div>

                {/* FINE FRECCIA 1 */}

                {/* CAROSELLO */}
                <div className="col-12 p-0 custom-col-12 h-100 Carousel d-flex align-items-center">
                  <AwesomeSlider
                    className="awesome_slider_style h-100"
                    organicArrows={false}
                    startup={this.props.carouselIndex.firstLoad}
                    startupScreen={
                      !this.props.carouselIndex.firstLoad ? (
                        <CarouselLoader />
                      ) : null
                    }
                    mobileTouch={
                      this.props.carouselIndex.zoomValue === 1 &&
                      !this.props.carouselIndex.modalOpen
                    }
                    infinite={false}
                    bullets={false}
                    selected={this.props.carouselIndex.carouselIndex}
                    onTransitionStart={(e) => {
                      return this.props.setButtonDisabled();
                    }}
                    onTransitionEnd={(e) => {
                      if (this.props.carouselIndex.fromInput) {
                        this.setState({ tmpIndex: e.currentIndex });

                        this.props.isFromInput(false);
                      } else if (this.state.fromSlider) {
                        this.props.setIndexCarousel(e.currentIndex);

                        this.setState({ fromSlider: false });
                      } else {
                        this.props.setIndexCarousel(e.currentIndex);

                        this.setState({ tmpIndex: e.currentIndex });
                      }
                      if (this.state.leafleat_has_video) {
                        let target_page = slideIndex;
                        let target_page_related = target_page;
                        if (!this.state.globalWidth >= BREAKPOINT.xs) {
                          if (target_page % 2 === 1) {
                            if (pages.length < target_page) {
                              target_page_related = target_page - 1;
                            }
                          } else {
                            target_page_related = target_page + 1;
                          }
                        } else {
                          target_page = slideIndex + 1;
                          target_page_related = target_page;
                        }

                        if (this.state.pages_with_video.includes(target_page)) {
                          this.showPageVideo(target_page);
                        } else if (
                          this.state.pages_with_video.includes(
                            target_page_related
                          )
                        ) {
                          this.showPageVideo(target_page_related);
                        }
                      }

                      if (this.props.carouselIndex.labelIndex) {
                        const temp_pages = String(
                          this.props.carouselIndex.labelIndex
                        ).split(" - ");
                        temp_pages.forEach((el) =>
                          sendAnalyticsEvent(
                            gaEventLabel.open_page,
                            String(el === "Indice" ? 0 : el)
                          )
                        );
                      }

                      if (this.props.carouselIndex.zoomValue > 1) {
                        this.prismaZoom.current.zoomOut();
                        this.props.setZoomLevel(1);
                      }
                      this.props.setButtonDisabled(false);
                      this.setState({ closeClick: false });
                    }}
                  >
                    {listSlides}
                  </AwesomeSlider>
                </div>
                {/* FINE CAROSELLO */}

                {/* FRECCIA 2 */}
                {listSlides.length > 1 && (
                  <div className="custom-col-1 p-0 h-100 d-none d-sm-flex justify-content-center align-items-center">
                    <CarouselArrow
                      carousel={true}
                      arrowColor={primary_color}
                      direction="right"
                      double={false}
                      size="0.625em"
                    />
                  </div>
                )}
                {/* FINE FRECCIA 2 */}
              </div>
            </div>
          </PrismaZoom>
          {(serverResponse.config?.bottom_bar ?? true) && (
            <>
              <div
                id="pagers"
                className="row d-flex justify-content-center align-items-center p-0 m-0"
                style={styles.pagersHeight}
              >
                <div
                  // className="col-lg-8 col-10 m-auto shadow carousel-footer"
                  className={`shadow carousel-footer ${
                    serverResponse.config?.show_one_page && "progressbarfooter"
                  }`}
                  style={{ borderRadius: "8px", backgroundColor: "white" }}
                >
                  <div className="carousel-footer-inner">
                    {getPager() !== "slider" ? (
                      <div
                        className="d-flex align-items-center pageinnerBox justify-content-center"
                        id="notslider"
                      >
                        <CarouselArrow direction="left" />
                        <InputPage />
                        <CarouselArrow direction="right" />
                        {/* <Slider
                          className="ZoomSlider"
                          axis="x"
                          x={this.state.tmpIndex}
                          xstep={0.000001}
                          xmax={max - 1}
                          onChange={({ x }) => {
                            setT(x);

                            this.props.setIndexLabel(this.state.tmpIndex);
                            if (document.getElementById("input-page")) {
                              document.getElementById("input-page").value = "";
                            }
                          }}
                          onDragStart={() => {
                            document.getElementById("notslider");
                          }}
                          onDragEnd={(e) => {
                            document.getElementById("notslider");
                            this.props.setIndexCarousel(this.state.tmpIndex);
                          }}
                        /> */}
                      </div>
                    ) : (
                      <div className="d-flex justify-content-center container-arrow align-items-center">
                        <div style={styles.pagerSlider}>
                          <div
                            id="thumbs"
                            className="d-none"
                            style={styles.thumbsContainer}
                          >
                            <div className="row w-100 justify-content-center">
                              {this.getThumb(1) && (
                                <div className="col-6 p-0">
                                  <img
                                    src={this.getThumb(1)}
                                    className="img-fluid shadow"
                                    style={styles.thumbsImg}
                                    alt="page"
                                  />
                                </div>
                              )}

                              {!show_one_page &&
                                this.state.globalWidth >= BREAKPOINT.xs &&
                                this.getThumb(2) && (
                                  <div className="col-6 p-0">
                                    <img
                                      src={this.getThumb(2)}
                                      className="img-fluid shadow"
                                      style={styles.thumbsImg}
                                      alt="page"
                                    ></img>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>

                        {/* <CarouselArrow direction={"left"} /> */}
                        {/* <Col className="col-12"> */}
                        <div
                          className="signlePageBox"
                          // style={{ marginTop: "7px" }}
                        >
                          <div
                            className="d-none"
                            style={styles.thumbsContainer}
                          >
                            <div className="row w-100 justify-content-center">
                              <div className="col-6 p-0">
                                <img
                                  src={this.getThumb(1)}
                                  className="img-fluid shadow"
                                  style={styles.thumbsImg}
                                  alt="page"
                                ></img>
                              </div>
                              {!show_one_page && this.getThumb(2) !== null && (
                                <div className="col-6 p-0">
                                  <img
                                    src={this.getThumb(2)}
                                    className="img-fluid shadow"
                                    style={styles.thumbsImg}
                                    alt="page"
                                  ></img>
                                </div>
                              )}
                            </div>
                          </div>
                          <Slider
                            className="ZoomSlider"
                            styles={styles.pagerSliderStyle}
                            axis="x"
                            x={this.state.tmpIndex}
                            xstep={0.000001}
                            xmax={max - 1}
                            onChange={({ x }) => {
                              setT(x);

                              this.props.setIndexLabel(this.state.tmpIndex);
                              if (document.getElementById("input-page")) {
                                document.getElementById("input-page").value =
                                  "";
                              }
                            }}
                            onDragStart={() => {
                              document
                                .getElementById("thumbs")
                                .classList.remove("d-none");
                            }}
                            onDragEnd={(e) => {
                              document
                                .getElementById("thumbs")
                                .classList.add("d-none");
                              this.props.setIndexCarousel(this.state.tmpIndex);
                            }}
                          />
                        </div>
                        {/* </Col> */}
                        {/* <CarouselArrow direction={"right"} /> */}
                      </div>
                    )}
                    <div className="d-flex align-items-center gap-2">
                      <ZoomButton />
                      <div className="d-flex align-items-center gap-2">
                        {/* SHARE IN FLOATING BAR */}
                        <div
                          className="d-flex shareBtn"
                          style={{ cursor: "pointer" }}
                        >
                          <ShareIcon
                            style={{ width: "45px" }}
                            onClick={() =>
                              this.setState({
                                openShareModal: true
                              })
                            }
                          />
                          {manager.showButtonFloatingBar() && (
                            <>
                              <Icone.Pdf
                                style={{ width: "35px" }}
                                onClick={(e) => {
                                  sendAnalyticsEvent(
                                    gaEventLabel.open_pdf,
                                    "PDF"
                                  );
                                  if (window.ReactNativeWebView !== undefined) {
                                    var obj = {
                                      action: "download",
                                      value:
                                        "https://" +
                                        window.location.host +
                                        "/" +
                                        serverResponse.config.pdfPath
                                    };
                                    window.ReactNativeWebView.postMessage(
                                      JSON.stringify(obj)
                                    );
                                  } else {
                                    window.open(
                                      process.env.PUBLIC_URL +
                                        serverResponse.config.pdfPath,
                                      "_blank"
                                    );
                                  }
                                }}
                              />
                              <Icone.SchermoIntero
                                style={{ width: "35px" }}
                                onClick={(e) => {
                                  e.preventDefault();

                                  // Ottieni l'elemento che vuoi mettere a schermo intero, qui assumo sia l'elemento radice
                                  var routeHeight =
                                    window.document.documentElement;

                                  // Funzione per verificare se si è in modalità schermo intero
                                  function isFullScreen() {
                                    return (
                                      document.fullscreenElement ||
                                      document.mozFullScreenElement ||
                                      document.webkitFullscreenElement ||
                                      document.msFullscreenElement
                                    );
                                  }

                                  // Funzione per uscire dalla modalità schermo intero
                                  function exitFullScreen() {
                                    if (document.exitFullscreen) {
                                      document.exitFullscreen();
                                    } else if (document.mozCancelFullScreen) {
                                      document.mozCancelFullScreen();
                                    } else if (document.webkitExitFullscreen) {
                                      document.webkitExitFullscreen();
                                    } else if (document.msExitFullscreen) {
                                      document.msExitFullscreen();
                                    }
                                  }

                                  // Funzione per entrare in modalità schermo intero
                                  function enterFullScreen() {
                                    if (routeHeight.requestFullscreen) {
                                      routeHeight.requestFullscreen();
                                    } else if (
                                      routeHeight.mozRequestFullScreen
                                    ) {
                                      routeHeight.mozRequestFullScreen();
                                    } else if (
                                      routeHeight.webkitRequestFullscreen
                                    ) {
                                      routeHeight.webkitRequestFullscreen(
                                        Element.ALLOW_KEYBOARD_INPUT
                                      );
                                    } else if (
                                      routeHeight.msRequestFullscreen
                                    ) {
                                      routeHeight.msRequestFullscreen();
                                    }
                                  }

                                  // Alterna tra modalità schermo intero e normale
                                  if (isFullScreen()) {
                                    exitFullScreen();
                                  } else {
                                    enterFullScreen();
                                  }
                                }}
                              />
                            </>
                          )}
                          <ShareModal
                            open={this.state.openShareModal}
                            setOpen={setOpenShareModal}
                          />
                        </div>

                        {/* VAI A PRODOTTI */}
                        {!!(
                          serverResponse.config.sidebar.includes("products") &&
                          Object.keys(serverResponse.interactivities).filter(
                            (el) =>
                              serverResponse.interactivities[el].type ===
                              "product"
                          ).length > 0
                        ) && (
                          <div
                            className="shareContent"
                            // className="col-md-2 col-3 d-flex align-items-center justify-content-center"
                            style={{
                              cursor: "pointer",
                              textDecoration: "underline"
                            }}
                          >
                            <div
                              onClick={goToProduct}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              <ProdottiIcon />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {/* *****ADSENSE******** */}

          {adsense && (
            <div className="row w-100 p-0 m-0" style={styles.adsenseContainer}>
              <AdComponent />
            </div>
          )}
          {/* *****FINE ADSENSE******** */}

          {video && (
            <Modal
              isOpen={
                retrievePageAndCheckVideo(this.props.carouselIndex.labelIndex)
                  .res && !this.state.closeClick
              }
              style={videoModalStyles}
              ariaHideApp={false}
            >
              <div className="modal-page-video-container">
                {this.state.video_muted && (
                  <button
                    onClick={this.toggleVideoMuted}
                    className="audio-page-video-button"
                  >
                    <i className="fas fa-volume-mute" />
                    <span style={styles.videoTextAudio}>Attiva audio</span>
                  </button>
                )}
                {this.state.show_close_video && (
                  <button
                    onClick={this.userCloseVideo}
                    className="close-page-video-button"
                  >
                    <i className="fas fa-times" />
                  </button>
                )}
                {!this.state.show_close_video && this.state.countdown > 0 && (
                  <button className="wait-page-video-button">
                    Sfoglia il{" "}
                    {type === "leaflet"
                      ? "volantino"
                      : type === "menu"
                      ? "menù"
                      : "catalogo"}{" "}
                    tra {this.state.countdown} secondi
                  </button>
                )}

                <ReactPlayer
                  className="react-page-player"
                  url={
                    retrievePageAndCheckVideo(
                      this.props.carouselIndex.labelIndex
                    ).url
                  }
                  playing={this.state.video_playing}
                  width="100%"
                  height="100%"
                  onProgress={this.onVideoProgress}
                  onStart={this.onVideoStart}
                  onEnded={this.onVideoEnded}
                  muted={true}
                  playsinline={this.state.globalWidth < BREAKPOINT.xs}
                  ref={this.ref}
                  volume={1}
                  controls={true}
                />
              </div>
            </Modal>
          )}
        </div>
        <div className="inpublish-icon-container">
          {serverResponse.config.type === "inpublish" ? (
            <InPublishIcona />
          ) : (
            <img
              className="img-fluid"
              src={interattivoLogo}
              style={{ width: "150px", alignSelf: "center" }}
              alt="logo interattivo"
            />
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    carouselIndex: state.carousel,
    modalRedux: state.modal
  };
};

export default withNavigate(
  connect(mapStateToProps, {
    setIndexCarousel,
    setIndexLabel,
    setSlideMax,
    isFromInput,
    setFirstLoad,
    setZoomLevel,
    setRefZoom,
    setButtonDisabled
  })(CustomCarousel)
);
