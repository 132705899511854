export const styles = {
  buttons_wrapper: {
    zIndex: 2,
    display: "flex",
    gap: "15px",
    alignItems: "center",
  },
  button: {
    // height: "38px",
    // marginRight: "5px"
  },
};
