import React from "react";
import { connect } from "react-redux";
import { getCustomActions } from "../../../../helper/utility";
import { openRelated } from "../../../../redux/modal/action";
import RelatedProduct from "../../../leaflet/RelatedProduct";

const Related = (props) => {
  const handleRelatedClick = (item_id) => {
    props.openRelated({
      content: getCustomActions().getInteractivity(item_id),
    });
  };

  return (
    <div className="modal-product-sidebar">
      <h5 className="related-product-title">Prodotti Correlati</h5>
      {props.related.map((el, index) => (
        <div className="related-product-card" key={index}>
          <RelatedProduct
            key={el}
            prod={getCustomActions().getInteractivity(el)}
            handleClick={handleRelatedClick}
          />
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    modalRedux: state.modal,
  };
};

export default connect(mapStateToProps, { openRelated })(Related);
