import * as React from "react";
const FormIcon = (props) => (
  <svg
    width="14"
    height="18"
    viewBox="0 0 14 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.93745 17.9938L12.0625 17.9938C13.0187 17.9938 13.7781 17.2344 13.7781 16.2781L13.7781 7.25002C13.7781 6.82815 13.6093 6.4344 13.3 6.15315L8.23745 1.3719C7.9562 1.09065 7.59058 0.950023 7.19683 0.950023L1.93745 0.950023C0.981203 0.950023 0.221828 1.7094 0.221828 2.66565L0.221827 16.2781C0.221827 17.2344 0.981201 17.9938 1.93745 17.9938ZM11.5843 6.29377L8.2937 6.29377C8.15308 6.29377 8.04058 6.18127 8.04058 6.06877L8.04058 2.97502L11.5843 6.29377ZM1.48745 2.7219C1.48745 2.46877 1.68433 2.2719 1.93745 2.2719L6.77495 2.2719L6.77495 6.06877C6.77495 6.8844 7.44995 7.5594 8.2937 7.5594L12.5125 7.5594L12.5125 16.2781C12.5125 16.5313 12.3156 16.7281 12.0625 16.7281L1.93745 16.7281C1.68433 16.7281 1.48745 16.5313 1.48745 16.2781L1.48745 2.7219Z"
      fill="currentcolor"
    />
  </svg>
);
export default FormIcon;
