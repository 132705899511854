export const styles = {
  buttonDesktop: (n) => {
    return {
      // height: "40px",
      // width: n < 10 ? "110px" : n > 99 ? "160px" : "130px",
      backgroundColor: "#fff",
      color: "#000",
      // borderRadius: "30px",
      // border: "1px solid #0008",
      // display: "flex",
    };
  },
  inputDesktop: {
    // width: "100%",
    // maxWidth: "120px",
    // textAlign: "right",
    // fontSize: "1.1rem"
  },
  buttonMobile: {
    position: "absolute",
    top: "55px",
    zIndex: 1,
    marginLeft: "auto",
    marginRight: "auto",
    left: 0,
    right: 0,
    width: "150px",
    opacity: 0.3,
  },
  inputMobile: {
    width: "68px",
    textAlign: "right",
    paddingRight: "5px",
  },
  onFocus: {
    opacity: 1,
  },
};
