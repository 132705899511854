import React, { useEffect, useRef, useState } from "react";
import { serverResponse } from "../../util/fakeServer";
import { connect } from "react-redux";
import {
  setIndexCarousel,
  isFromInput,
  setVerticalPages,
} from "../../redux/carousel/action";
import { getValue, scrollToSlide } from "../../helper/utility";
import { styles } from "./inputPage.style";
import useCurrentWidth from "../../hooks/widthHook";
import { BREAKPOINT } from "../../helper/constant";
import { isSafari } from "react-device-detect";

const InputPage = (props) => {
  const { carouselIndex } = props;
  const width = useCurrentWidth();
  const [currentId, setCurrentId] = useState(["slide_1"]);

  useEffect(() => {
    const options = { root: null, threshold: 0.6 };
    const observer = new IntersectionObserver((entries) => {
      let temp_id = [];
      if (entries) {
        entries
          .filter(
            (el) =>
              el.target.getAttribute("id") &&
              el.target.getAttribute("id").includes("slide_")
          )
          .forEach((el) => {
            if (el.isIntersecting) {
              temp_id.push(el.target.getAttribute("id"));

              setCurrentId(temp_id[0]);
            }
            // console.log(
            //   `I'm ${
            //     el.isIntersecting ? "in view" : "out of view"
            //   }: ${el.target.getAttribute("id")}`,
            //   el.target
            // );
          });
      }
    }, options);

    // Observe all elements with IDs
    const elemsWithIds = document.querySelectorAll("*[id]");
    elemsWithIds.forEach((elem) => observer.observe(elem));
  }, []);

  useEffect(() => {
    // console.log(currentId);
    // props.setIndexCarousel(getValue());
  }, [currentId]);

  const inputPage = useRef();

  function change(event) {
    // alert(event.);
    let target = event.target;
    let input = parseInt(target.value, 10);
    if (event.keyCode === 13 || event.key === "End") {
      props.isFromInput(true);
      props.setIndexCarousel(getValue(input));
      target.value = "";
      inputPage.current.blur();
      if (
        (serverResponse.config.scrollVertical ||
          serverResponse.config.scrollHorizontal) &&
        width < BREAKPOINT.xs
      ) {
        scrollToSlide(
          input,
          props.setVerticalPages,
          props.carouselIndex.verticalPages
        );
      }
    }
  }

  return (
    <div
      style={{
        ...styles.buttonDesktop(serverResponse.leaflet.pages.length),
        ...{ backgroundColor: "#fff !important" },
      }}
      className="carousel-pagination-button"
      color="primary"
    >
      {/* {currentId} */}
      {true ? (
        <span
          style={{
            fontSize: "16px",
            fontWeight: "700",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // width: "90%",
          }}
        >
          <span style={{ fontSize: "16px", fontWeight: "500" }}>pag:</span>
          <input
            className={!isSafari ? "absolute_placeholder" : ""}
            onFocus={(e) => {
              if (
                (!serverResponse.config.scrollVertical ||
                  width >= BREAKPOINT.xs) &&
                inputPage.current !== undefined
              ) {
                inputPage.current.classList.add("onFocus");
              }

              e.target.placeholder = "";
            }}
            onBlur={(e) => (e.target.placeholder = carouselIndex.labelIndex)}
            id="input-page"
            ref={inputPage}
            type="number"
            // pattern="\d*"
            // type="number"
            autoFocus={false}
            onClick={(e) => e.target.select()}
            style={styles.inputDesktop}
            onKeyDown={(e) => {
              change(e);
            }}
            placeholder={
              // serverResponse.config.scrollVertical && width < BREAKPOINT.xs
              //   ? currentId.replace(/slide_/g, "")
              //   :
              carouselIndex.labelIndex
            }
          />
          <span
            style={{ fontSize: "16px", fontWeight: "500", marginRight: "2px" }}
          >
            /
          </span>
          <span style={{ fontSize: "16px", fontWeight: "500" }}>
            {serverResponse.leaflet.pages.length}
          </span>
        </span>
      ) : (
        <p>Vai a</p>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    carouselIndex: state.carousel,
  };
};

export default connect(mapStateToProps, {
  setIndexCarousel,
  isFromInput,
  setVerticalPages,
})(InputPage);
