import * as React from "react";
const SearchFilter = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}

  >
    <path
      d="M8.5125 16.2L6.15 18.5625V3.41249C6.15 2.96249 5.775 2.54999 5.2875 2.54999C4.8 2.54999 4.425 2.92499 4.425 3.41249V18.5625L2.0625 16.2C1.725 15.8625 1.2 15.8625 0.8625 16.2C0.525 16.5375 0.525 17.0625 0.8625 17.4L4.425 20.9625C4.65 21.1875 4.95 21.3 5.2875 21.3C5.5875 21.3 5.8875 21.1875 6.15 20.9625L9.7125 17.4C10.05 17.0625 10.05 16.5375 9.7125 16.2C9.375 15.9 8.85 15.9 8.5125 16.2Z"
      fill="#0D0040"
    />
    <path
      d="M14.8875 19.7625H13.05C12.6 19.7625 12.225 20.1375 12.1875 20.5875C12.1875 21.0375 12.5625 21.45 13.0125 21.45H14.85C15.3 21.45 15.675 21.075 15.7125 20.625C15.7125 20.1375 15.3375 19.7625 14.8875 19.7625Z"
      fill="#0D0040"
    />
    <path
      d="M17.7375 14.025H13.05C12.6 14.025 12.225 14.4 12.1875 14.85C12.1875 15.3 12.5625 15.7125 13.0125 15.7125H17.7C18.15 15.7125 18.525 15.3375 18.5625 14.8875C18.5625 14.4 18.1875 14.025 17.7375 14.025Z"
      fill="#0D0040"
    />
    <path
      d="M19.65 8.28748H13.05C12.6 8.28748 12.1875 8.66248 12.1875 9.11248C12.1875 9.56248 12.5625 9.97498 13.0125 9.97498H19.6125C20.0625 9.97498 20.4375 9.59998 20.475 9.14998C20.475 8.66248 20.1 8.28748 19.65 8.28748Z"
      fill="#0D0040"
    />
    <path
      d="M22.5 2.58749H13.05C12.6 2.58749 12.225 2.96249 12.1875 3.41249C12.1875 3.86249 12.5625 4.27499 13.0125 4.27499H22.4625C22.9125 4.27499 23.2875 3.89999 23.325 3.44999C23.3625 2.96249 22.95 2.58749 22.5 2.58749Z"
      fill="#0D0040"
    />
  </svg>
);

export default SearchFilter;
