export const styles = {
  icon_style: {
    width: "1.6rem",
    heigth: "1.6rem",
    marginLeft: "0.4rem",
    cursor: "pointer",
  },
  style_container_header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    // borderBottom: "1px solid rgb(234 234 234)"
  },
  hr_style: {
    width: "100%",
  },
  socialIcons: {
    display: "flex",
    gap: "16px",
    fontSize: "23px",
    color: "var(--primary-color)",
  },
};
