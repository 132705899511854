import React, { Component } from "react";
import MyGroceryElementInpublish from "../components/myGroceryList/MyGroceryElementInpublish";
import { Scrollbars } from "react-custom-scrollbars";
import axios from "axios";
import "../assets/css/custom.css";
import { idCampaignName, myGroceryListName } from "../helper/constant";
import { serverResponse } from "../util/fakeServer";
import { createRef } from "react";
import { defaultLanguage, getCustomActions } from "../helper/utility";
import { language } from "../languageProvider/language/language";
import ExportButton from "../components/exportButton/ExportButton";
import withParams from "../components/common/WithParams";
import withLocation from "../components/common/WithLocation";
import LeafletButton from "../components/button/LeafletButton";
import InPublishIcona from "../assets/images/icone/InPublish.icona";

class MyGroceryList extends Component {
  constructor(props) {
    super(props);
    this.back = createRef();
  }

  state = {
    list: [],
  };

  componentDidMount() {
    var id = this.props.params.id;
    var api_url = getCustomActions().get_read_api_url(id);

    //axios per il caricamento della lista
    axios({
      method: "POST",
      url: api_url,
    })
      .then((res) => {
        let tmpProducts = getCustomActions().get_product_api_url(res);

        tmpProducts.sort((a, b) => {
          const [aDone, bDone] = [a.done, b.done];
          if (aDone === bDone) {
            const [aID, bID] = [parseInt(a.item_id), parseInt(b.item_id)];
            if (aID === -1) return +1;
            if (bID === -1) return -1;
            return aID - bID;
          }
          if (aDone === true) return +1;
          if (bDone === true) return -1;
          return 1;
        });

        this.setState({
          list: tmpProducts,
        });
        getCustomActions().backToLeaflet(
          this.backToLeaflet,
          this.props.history
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }

  checkProduct = (e) => {
    let tmpProducts = this.state.list;
    tmpProducts[e.target.id].done = e.target.checked;
    tmpProducts.sort((a, b) => {
      const [aDone, bDone] = [a.done, b.done];
      if (aDone === bDone) {
        const [aID, bID] = [parseInt(a.item_id), parseInt(b.item_id)];
        if (aID === -1) return +1;
        if (bID === -1) return -1;
        return aID - bID;
      }
      if (aDone === true) return +1;
      if (bDone === true) return -1;
      return 1;
    });
    this.setState({
      list: tmpProducts,
    });
  };

  sortItems = (tmpProducts, e) => {
    tmpProducts[e.target.id].done = e.target.checked;
    tmpProducts.sort((a, b) => {
      const [aDone, bDone] = [a.done, b.done];
      if (aDone === bDone) {
        const [aID, bID] = [parseInt(a.item_id), parseInt(b.item_id)];
        if (aID === -1) return +1;
        if (bID === -1) return -1;
        return aID - bID;
      }
      if (aDone === true) return +1;
      if (bDone === true) return -1;
      return 1;
    });
    return tmpProducts;
  };

  backToLeaflet = () => {
    localStorage.setItem(
      myGroceryListName + serverResponse.leaflet.code,
      JSON.stringify(this.state.list)
    );
    localStorage.setItem(
      idCampaignName,
      this.state.list ? this.state.list[0].id_campagna : ""
    );
  };

  render() {
    let tot = 0;
    const items = this.state.list;
    items.map((element, key) => {
      tot += parseFloat(element.currentPrice);
      element.index = key;
      element.images = [];
      element.images.push({ image_file: element.image_file });
      return null;
    });
    tot = tot.toFixed(2);

    return (
      <div
        className={`${
          getCustomActions().get_list_container_class
        } container-fluid py-0`}
        style={{ overflow: "hidden", height: "100vh" }}
      >
        {/* Header mobile */}
        <div className="my-grocery-list-header-mobile d-md-none">
          <div className="row py-1 action-row">
            <div className="col-6 d-flex flex-column justify-content-center">
              <LeafletButton
                onClick={this.backToLeaflet}
                text={language[defaultLanguage].volantino}
              />
            </div>
            <div className="col-6 d-flex justify-content-end align-items-center">
              <InPublishIcona />
            </div>
          </div>
          <div className="row py-3 client-logo-row">
            <div className="col-6 m-auto text-center h-100">
              <img
                src={serverResponse.config.logo_full}
                className="img-fluid"
                alt={serverResponse.config.ogDescriptionMeta}
              />
            </div>
          </div>
        </div>

        {/* Header Desktop */}
        <div className="my-grocery-list-header-desktop d-none d-md-inline-block w-100">
          <div className="row h-100" style={{ backgroundColor: "#ffffff" }}>
            <div className="col-12 h-100 text-center py-2">
              <img
                src={serverResponse.config.logo_full}
                alt={serverResponse.config.ogDescriptionMeta}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 py-3 text-center">
              <p className="m-0" style={{ fontWeight: 700, fontSize: "24px" }}>
                {language[defaultLanguage].la_tua_lista_della_spesa}
              </p>
            </div>
          </div>
        </div>

        {/* Tabella */}
        <div className="container">
          <div className="col-12 my-grocery-list-container">
            <div className="container-fluid">
              <div className="row my-grocery-list-header d-none d-md-flex">
                <div className="col-12 py-3">
                  <LeafletButton
                    onClick={this.backToLeaflet}
                    text={language[defaultLanguage].volantino}
                  />
                </div>
              </div>

              {/* Prodotti */}
              <div className="row">
                <div className="custom-srollbars">
                  <div className="col-12">
                    <div className="row">
                      {this.state.list.map((element, i) => {
                        return (
                          <div
                            className="col-12 col-md-6 p-0 p-md-3"
                            key={`${element.id}_${i}`}
                          >
                            <MyGroceryElementInpublish
                              info={element}
                              checkProduct={this.checkProduct}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>

              {/* Footer Tabella */}
              <div className="row py-3">
                <div className="col-12 d-flex justify-content-between justify-content-md-end">
                  <div className="mr-5">
                    <ExportButton
                      idParamUrl={this.props.params.id}
                      type="pdf"
                    />
                  </div>
                  <div className="ml-5">
                    <ExportButton
                      idParamUrl={this.props.params.id}
                      type="csv"
                    />
                  </div>
                </div>
                <div className="col-12 pt-md-3">
                  <p className="my-grocery-list-price-p">
                    <span style={{ fontSize: "1rem" }}>
                      {language[defaultLanguage].totale}:
                    </span>{" "}
                    {tot} &euro;
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-none d-md-inline-block" style={{position: "absolute", right: "2vh"}}>
          <InPublishIcona />
        </div>
      </div>
    );
  }
}

export default withLocation(withParams(MyGroceryList));
